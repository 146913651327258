import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import userRequest from "../utils/requestMethods";
import { PAGING_SIZE_DOC } from "../configs/constants";
import { convertToSentenceCase, getUniqueArray } from "../utils";
import { ReactComponent as NoResultSVG } from "../assets/svgs/no-result.svg";
import { Oval } from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import DocotrItem from "../components/Doctor/DocotrItem";
import DoctorSearch from "../components/Doctor/DoctorSearch";
import SelectDropDown from "../components/Shared/SelectDropDown/SelectDropDown";
import ReactSelectPaginate from "../components/Shared/ReactSelectPaginate/ReactSelectPaginate";
import DoctorService from "../services/doctor.service";

const initialFilter = {
  searchFilter: "",
  city: "Todos",
  country: "Todos",
  specialityName: "Todos",
  expertiesName: "Todos",
  state: "Todos",
  hospital: "Todos",
};

const formatDataForDropDownFilter = (data, label, value, count) => {
  let availableItems = data?.filter((item) => {
    if (item?.[label]) {
      return item;
    }
  });
  let sortList = [];
  sortList = availableItems?.map((item) => {
    return {
      label:
        item?.[label] +
        `${count ? "   (" + manualStringFormatting(item?.[count]) + ")" : ""}`,
      value: item?.[value],
    };
  });
  sortList.unshift({
    label: "Todos",
    value: "",
  });
  return sortList;
};

const manualStringFormatting = (number) => {
  return number ? `${number?.toLocaleString()}` : number;
};

const filterReducer = (state, action) => {
  switch (action.type) {
    case "searchFilter":
    case "city":
    case "specialityName":
    case "expertiesName":
    case "state":
    case "hospital":
    case "country": {
      let newState = { ...state };
      newState[action.payload.key] = action.payload.value;
      return newState;
    }
    case "reset": {
      return initialFilter;
    }
    case "default":
      return state;
  }
};

const DoctorPage = ({ setIsLoginModalOpen }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [appliedFilters, dispatchAppliedFilters] = useReducer(
    filterReducer,
    initialFilter
  );
  const [data, setData] = useState([]);
  const [filterCount, setFilterCount] = useState();
  const pageSize = PAGING_SIZE_DOC;

  const [pageIndex, setPageIndex] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [count, setCount] = useState(0);

  // country filter
  const [countries, setCountries] = useState([]);

  // cities filter
  const [cities, setCities] = useState([]);

  // states filter
  const [states, setStates] = useState([]);

  // hospital filter
  const [hospital, setHospital] = useState([]);

  // params
  const [searchParams, setSearchParams] = useSearchParams();

  const addFilter = (key, value) => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set(key, value);
      return prevSearchParams;
    });
  };

  const applyInitialFilters = () => {
    for (let key in appliedFilters) {
      const val = searchParams.get(key);
      if (val) updateFilter(key, val);
    }
  };

  useEffect(() => {
    applyInitialFilters();
  }, []);

  const prepareSearchPayload = useCallback(
    (pagination = true, summary = false) => {
      let payload = {
        searchFilter: "",
        // sourceName: "doctoralia",
        deleteStatus: false,
      };
      if (pagination) {
        payload = {
          ...payload,
          pageSize,
          pageIndex,
        };
      }
      for (let key in appliedFilters) {
        if (
          appliedFilters[key] &&
          appliedFilters[key] !== "Todos" &&
          key === "country"
        ) {
          payload.countryName = appliedFilters[key];
        } else if (appliedFilters[key] && appliedFilters[key] !== "Todos") {
          payload[key] = appliedFilters[key];
        }
      }
      if (summary) {
        payload.summary = true;
      }
      return payload;
    },
    [pageIndex, pageSize, appliedFilters]
  );

  const fetchSearchResults = useCallback(
    async ({ signal }) => {
      setLoading(true);
      try {
        const payload = prepareSearchPayload(true);
        const response = await userRequest.get("/doctor", {
          signal,
          params: payload,
        });
        if (response.data?.length > pageSize - 1) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        // console.log("response with doctor >>>", response);
        if (payload.pageIndex === 0) {
          setData(response.data);
        } else {
          setData((prevItems) => [...prevItems, ...response.data]);
        }
        setLoading(false);
      } catch (error) {
        if (error.code === "ERR_CANCELED") {
          setLoading(true);
          console.error("Error fetching data:", error);
        } else {
          setLoading(false);
          console.log("error from doc >>>", error);
        }
      }
    },
    [prepareSearchPayload]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchSearchResults({ signal });
    return () => controller.abort();
  }, [pageIndex, pageSize, appliedFilters]);

  const fetchFilterDocCount = useCallback(async () => {
    const payload = prepareSearchPayload(false, true);
    try {
      const response = await userRequest.get("/doctor", {
        params: payload,
      });
      // console.log("response with doctor summary >>>", response);
      setFilterCount(response.data?.count);
    } catch (error) {
      console.log("error from filter summary >>>", error);
    }
  }, [prepareSearchPayload]);

  useEffect(() => {
    fetchFilterDocCount();
  }, [appliedFilters]);

  const fetchDynamicFilters = async (value, country) => {
    try {
      const payload = {
        groupedField: value,
      };
      if (country) {
        payload.country = country;
      }
      const response = await userRequest.get("/doctor/categorised-data", {
        params: payload,
      });
      // Todos
      const sortList = formatDataForDropDownFilter(
        response?.data,
        "_id",
        "_id",
        "count"
      );
      return sortList;
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  };

  useEffect(() => {
    const fetchTotalDocCount = async () => {
      const payload = {
        summary: true,
      };
      try {
        const response = await userRequest.get("/doctor", {
          params: payload,
        });
        console.log("response with summary >>>", response);
        setCount(response.data?.count);
      } catch (error) {
        console.log("error from catch >>>>", error);
      }
    };
    async function fetchCountyData() {
      const response = await fetchDynamicFilters("country");
      if (!countries.length) {
        setCountries(response);
      }
    }
    async function fetchStateData() {
      const response = await fetchDynamicFilters("Estado");
      if (!states.length) {
        setStates(response);
      }
    }
    // async function fetchHospitalData() {
    //   const response = await fetchDynamicFilters("location");
    //   if (!hospital.length) {
    //     setHospital(response);
    //   }
    // }
    fetchTotalDocCount();
    fetchCountyData();
    fetchStateData();
    // fetchHospitalData();
  }, []);
  useEffect(() => {
    async function fetchCityData(country) {
      const response = await fetchDynamicFilters("city", country);
      setCities(response);
    }
    if (appliedFilters?.country !== "Todos") {
      fetchCityData(appliedFilters?.country);
    }
  }, [appliedFilters]);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setPageIndex(pageIndex + 1);
  };

  const searchHandler = () => {
    setData([]);
    setPageIndex(0);
    dispatchAppliedFilters({
      payload: { key: "searchFilter", value: searchValue },
      type: "searchFilter",
    });
  };

  const getPaginatedArr = (data, page) => {
    let availableExperties = data?.filter((item) => {
      if (item) {
        return item;
      }
    });
    // console.log("befor unique  >>>", availableExperties);
    availableExperties = getUniqueArray(availableExperties);
    // console.log("after unique >>>", availableExperties);
    let sortExpertiesList = availableExperties?.map((item) => {
      return {
        label: convertToSentenceCase(item),
        value: item,
      };
    });
    // sortExpertiesList.unshift({
    //   label: "Todos",
    //   value: "",
    // });
    // setExperties(sortExpertiesList);
    return {
      options: sortExpertiesList || [],
      hasMore: data?.length > pageSize - 1 ? true : false,
      additional: {
        page: data?.length ? page + 1 : page + 0,
      },
    };
  };

  async function loadSpecialityOptions(search, loadedOptions, { page }) {
    try {
      const data = await DoctorService.getListGroupedData({
        search: search,
        groupedField: "speciality",
        pageSize: pageSize,
        pageIndex: page,
      });
      return getPaginatedArr(data, page);
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  }
  async function loadExpertiesOptions(search, loadedOptions, { page }) {
    try {
      const data = await DoctorService.getListGroupedData({
        search: search,
        groupedField: "expertoEn",
        pageSize: pageSize,
        pageIndex: page,
      });
      return getPaginatedArr(data, page);
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  }
  async function loadHospitalOptions(search, loadedOptions, { page }) {
    try {
      const data = await DoctorService.getListGroupedData({
        search: search,
        groupedField: "location.name",
        arrField: "location",
        pageSize: pageSize,
        pageIndex: page,
      });
      return getPaginatedArr(data, page);
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  }

  const handleNavigate = (item) => {
    navigate(`/doctores/${item._id}`, {
      state: JSON.stringify(item),
    });
  };

  const updateFilter = (key, value) => {
    setData([]);
    setPageIndex(0);
    dispatchAppliedFilters({ payload: { key, value }, type: key });
    addFilter(key, value);
  };

  const handleReset = async () => {
    setData([]);
    setSearchParams({});
    setSearchValue("");
    setPageIndex(0);
    dispatchAppliedFilters({ type: "reset" });
  };

  return (
    <div>
      {/* <div>Search Bar</div> */}
      <div className="py-8 min-w-[95%] md:max-w-[70%] mx-auto">
        <DoctorSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onChangeValue={searchHandler}
        />
        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-4 my-8 ">
          <div className="xs:pr-4 xs:border-r-2 xs:border-gray-200">
            <p className="font-[600] p-0">Especialidad:</p>
            <ReactSelectPaginate
              value={appliedFilters?.specialityName}
              onChange={(value) => updateFilter("specialityName", value)}
              loadOptions={loadSpecialityOptions}
            />
          </div>
          <div className="xs:pr-4 xs:border-r-2 xs:border-gray-200">
            <p className="font-[600] p-0">Experto en:</p>
            <ReactSelectPaginate
              value={appliedFilters?.expertiesName}
              onChange={(value) => updateFilter("expertiesName", value)}
              loadOptions={loadExpertiesOptions}
            />
          </div>
          <SelectDropDown
            options={countries}
            label="País:"
            value={appliedFilters?.country}
            onChange={(value) => {
              updateFilter("country", value);
              updateFilter("city", "Todos");
              setCities([]);
            }}
          />
          <SelectDropDown
            options={states}
            label="Estado:"
            value={appliedFilters?.state}
            onChange={(value) => updateFilter("state", value)}
          />
          <SelectDropDown
            options={cities}
            label="Ciudad:"
            value={appliedFilters?.city}
            onChange={(value) => updateFilter("city", value)}
          />
          <div className="xs:pr-4 xs:border-r-2 xs:border-gray-200">
            <p className="font-[600] p-0">Hospital:</p>
            <ReactSelectPaginate
              value={appliedFilters?.hospital}
              onChange={(value) => updateFilter("hospital", value)}
              loadOptions={loadHospitalOptions}
            />
          </div>
          <div className="xs:pr-4 flex justifyflex items-center justify-start">
            <button
              className="text-secondary underline pt-5 font-[600]"
              onClick={handleReset}
            >
              Resetear filtros
            </button>
          </div>
        </div>
      </div>
      <div className="mt-1 mb-10 flex flex-col-reverse lg:flex-row justify-between">
        <div
          className="flex-[3] p-6 pt-0 lg:rounded-tl-lg rounded-br-lg lg:rounded-br-none rounded-bl-lg h-[100vh] overflow-y-scroll"
          id="search-container"
        >
          {data.length > 0 ? (
            <div className="my-2">
              <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={hasMore}
                loader={
                  <div className="flex justify-center items-center">
                    <Oval
                      height={50}
                      width={50}
                      color="rgba(51, 124, 171, 1)"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="rgba(51, 124, 171, 1)"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                }
                scrollableTarget="search-container"
              >
                {/* <div className="flex justify-between flex-wrap w-full"> */}
                <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                  {data.map((item) => (
                    <div className="mx-1 bg-white shadow-xl rounded-lg  my-2 max-w-[20rem] max-h-full">
                      <DocotrItem
                        key={item._id}
                        name={item.name}
                        image={item.photo}
                        pharmacyName={item.pharmacyName}
                        price={item.amount || item.price || 0}
                        url={item.url}
                        id={item._id}
                        isFavourite={item?.isFavourite || false}
                        doctor={item}
                        setIsLoginModalOpen={setIsLoginModalOpen}
                      />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          ) : null}
          {/* {data.length === 0 && !loading && (
          <div className="flex justify-center items-center h-[100vh]">
            <p className="text-2xl font-[600]">No se encuentran resultados</p>
          </div>
        )} */}
          {!data?.length && loading && (
            <div className="flex justify-center items-center h-[100vh]">
              <div className="flex justify-center items-center">
                <Oval
                  height={50}
                  width={50}
                  color="rgba(51, 124, 171, 1)"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="rgba(51, 124, 171, 1)"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            </div>
          )}
          {!data?.length && !loading && searchValue ? (
            <div className="flex justify-center items-center h-[60vh]">
              <div>
                <div className="flex justify-center items-center text-2xl">
                  <NoResultSVG width={200} height={200} color="#555555" />
                </div>
                <p className="text-xl font-[600] w-8/12 mx-auto">
                  ¿No encontraste lo que buscabas?{" "}
                  {/* <span
                    className="text-blue-500 cursor-pointer hover:underline"
                    onClick={showContactForm}
                  >
                    Solicite un medicamento aquí
                  </span> */}
                </p>
              </div>
            </div>
          ) : loading ? (
            <div className="flex justify-center items-center h-[20]">
              <div>
                <p className="text-2xl font-[600]">Cargando...</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DoctorPage;
