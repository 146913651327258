import React from "react";

const PillButton = ({ children, onClick }) => {
  return (
    <button
      className="bg-secondary text-white py-2 px-4 rounded-full text-sm transition-all duration-300 ease-in hover:scale-105"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default PillButton;
