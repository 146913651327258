import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import placeHolderImage from "../../../assets/images/blog-page-placeholder.webp";

const Image = ({ image, placeholder, ...rest }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="default-image">
      {loading && (
        <img
          src={placeHolderImage}
          alt={image?.alt}
          className="default-image-loader"
          {...rest}
        />
      )}
      <LazyLoadImage
        alt={image.alt}
        src={image.src}
        placeholderSrc={placeholder || placeHolderImage}
        onLoad={() => {
          setLoading(false);
        }}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          display: loading ? "hidden" : "initial",
          position: loading ? "absolute" : "initial",
          top: 0,
          left: 0,
          zIndex: 0,
        }}
        {...rest}
      />
    </div>
  );
};

export default Image;
