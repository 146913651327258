import React, { createContext, useEffect, useState } from "react";
import { getValueFromLocalStorage } from "../utils/localStorage";

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [selectedLocation, setSelectedLocation] = useState({});
  const getUserLocation = getValueFromLocalStorage("coords");

  useEffect(()=>{
    setSelectedLocation(getUserLocation)
  },[])

  return (
    <LocationContext.Provider value={{ selectedLocation, setSelectedLocation }}>
      {children}
    </LocationContext.Provider>
  );
};
