import React, { useEffect } from "react";
import PrivacyPolicySection from "../components/PrivacyPolicySection/PrivacyPolicySection";
import { Helmet } from "react-helmet";
import { scrollToTop } from "../utils/scrollToTop";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="my-6 px-10 py-12 rounded-2xl relative lg:w-10/12 mx-auto">
      <Helmet>
        <link
          rel="canonical"
          href="https://vooyfarma.com/politica-de-privacidad"
        />
      </Helmet>
      <div className="pb-4">
        <h1 className="text-start text-2xl sm:text-4xl font-[600] text-primary">
          Política de privacidad
        </h1>
      </div>
      <PrivacyPolicySection />
    </div>
  );
};

export default PrivacyPolicyPage;
