import React from "react";
import { CiSearch } from "react-icons/ci";

const DoctorSearch = ({ onChangeValue, searchValue, setSearchValue, placeholder="Buscador de doctores" }) => {
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onChangeValue(searchValue);
    }
  };

  const searchHandler = () => onChangeValue(searchValue);

  return (
    <div className="flex flex-col xs:flex-row xs:items-center xs:justify-between gap-4">
      <div className="flex items-center gap-2 rounded-lg bg-white px-4 py-4 flex-1 ">
        <CiSearch className="text-xl" />
        <input
          type="text"
          placeholder={placeholder}
          className="bg-transparent focus:outline-none w-full"
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <button
        className="bg-secondary text-white px-10 py-4 rounded-lg"
        onClick={searchHandler}
      >
        Buscar
      </button>
    </div>
  );
};

export default DoctorSearch;
