import React, { useState } from "react";
import axios from "axios";
import { AiOutlineClose, AiOutlineLock, AiOutlineMail } from "react-icons/ai";
import Modal from "react-modal";
import TextField from "../Shared/TextField/TextField";
import "react-phone-number-input/style.css";
import Button from "../Shared/Button/Button";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
  //  GoogleOAuthProvider, GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import FacebookLogin from "react-facebook-login";
import { MdOutlinePassword } from "react-icons/md";

import { baseURL } from "../../utils/baseURL";

const CLIENT_ID_FACEBOOK = "632730335658709";

const customStyles = {
  content: {
    backgroundColor: "#fff",
    height: "fit-content",
    margin: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const LoginModal = ({ isOpen, closeLoginModal, onOpenSignupModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputStage, setInputStage] = useState("login");
  const [isLoading, setIsLoading] = useState(false);

  const loginFormSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const email = e.target?.email?.value;
      const password = e.target?.password?.value;
      const resetCode = e.target?.resetCode?.value;
      const confirmPassword = e.target?.confirmPassword?.value;

      if (inputStage === "login") {
        if (!email || !password) {
          setIsLoading(false);
          return toast.warn("Please fill all fields");
        }
        const body = {
          email,
          password,
        };
        const response = await axios.post(`${baseURL}/auth/signin`, body);

        if (response) {
          dispatch(login(response.data));
          // store user in local storage
          localStorage.setItem("user", JSON.stringify(response.data.user));
          // store token in local storage
          localStorage.setItem("token", response.data.token);
          // close login modal
          closeLoginModal();
          setIsLoading(false);
          toast.success("Ingresado exitosamente");
          // redirect to home page
          navigate("/");
        }
      }
      if (inputStage === "forgot_pass") {
        if (!email) {
          setIsLoading(false);
          return toast.warn("Please fill email fields");
        }
        const body = {
          email,
        };

        const response = await axios.post(`${baseURL}/reset-password`, body);
        console.log("response >>>>", response);
        // if (response) {
        if (body.email) {
          setInputStage("reset_pass");
          setIsLoading(false);
          toast.success("Ingresado exitosamente");
        }
      }
      if (inputStage === "reset_pass") {
        if (!email || !resetCode || !password) {
          setIsLoading(false);
          return toast.warn("Please fill all fields");
        }
        const body = {
          email,
          resetCode,
          newPassword: password,
          // confirmPassword
        };
        const response = await axios.post(
          `${baseURL}/confirm-reset-password`,
          body
        );

        if (response) {
          closeLoginModal();
          setInputStage("login");
          setIsLoading(false);
          toast.success("Ingresado exitosamente");
        }
      }
    } catch (error) {
      toast.error(error.response.data.error);
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (authResponse) => {
      // console.log(" authResponse >>>", authResponse);
      if (authResponse?.access_token) {
        const body = {
          socialLogin: "google",
          googleAccessToken: authResponse?.access_token,
          access_token: true,
        };
        // axios
        const serverResponse = await axios
          .post(`${baseURL}/auth/signin`, body)
          .catch((err) => {
            toast.error(err.response.data.error);
            setIsLoading(false);
          });

        // console.log("serverResponse >>>",serverResponse);
        if (serverResponse.status === 200) {
          dispatch(login(serverResponse?.data));
          // store user in local storage
          localStorage.setItem(
            "user",
            JSON.stringify(serverResponse.data.user)
          );
          // store token in local storage
          localStorage.setItem("token", serverResponse.data.token);
          // close login modal
          closeLoginModal();
          setIsLoading(false);
          toast.success("Ingresado exitosamente");
          // redirect to home page
          navigate("/");
        }
      }
    },
    onError: (error) => {
      console.log(" error >>>", error);
      toast.error(
        "Lo sentimos, se produjo un error al intentar iniciar sesión con Google. Por favor, inténtelo de nuevo más tarde"
      );
    },
    // flow: 'auth-code',
  });

  const responseFacebook = async (response) => {
    // console.log("response from facebook oauth", response);
    if (response.status !== "unknown") {
      const body = {
        socialLogin: "facebook",
        facebookAccessToken: response?.accessToken,
      };
      // console.log("body >>>", body);
      const serverResponse = await axios
        .post(`${baseURL}/auth/signin`, body)
        .catch((err) => {
          toast.error(err.serverResponse.data.error);
          setIsLoading(false);
        });
      // console.log("serverResponse >>>",serverResponse);
      if (serverResponse.status === 200) {
        dispatch(login(serverResponse?.data));
        // store user in local storage
        localStorage.setItem(
          "user",
          JSON.stringify(serverResponse?.data?.user)
        );
        // store token in local storage
        localStorage.setItem("token", serverResponse?.data?.token);
        // close login modal
        closeLoginModal();
        setIsLoading(false);
        toast.success("Ingresado exitosamente");
        // redirect to home page
        navigate("/");
      }
    } else {
      toast.error(
        "Lo sentimos, se produjo un error al intentar iniciar sesión con Facebook. Por favor, inténtelo de nuevo más tarde"
      );
    }
  };

  const inputForm = () => {
    switch (inputStage) {
      case "login":
        return (
          <div className="mx-6 mt-3 pt-2 border-t-2 border-t-gray-300">
            <form className="w-full" onSubmit={loginFormSubmitHandler}>
              <TextField
                label="Correo electrónico"
                name="email"
                icon={<AiOutlineMail className="text-xl" />}
                placeholder="Ingresa tu correo electrónico"
              />
              <TextField
                label="Contraseña"
                name="password"
                icon={<AiOutlineLock className="text-xl" />}
                placeholder="Ingresa tu contraseña"
                type="password"
              />
              <div className="text-xs font-medium flex justify-end">
                <button
                  type="button"
                  className="underline hover:text-blue-600 "
                  onClick={() => setInputStage("forgot_pass")}
                >
                  ¿Olvidaste tu contraseña?
                </button>
              </div>
              <div className="flex items-center justify-center my-6">
                <Button disabled={isLoading} type="submit" className="w-full">
                  {/* Ingresar */}
                  {ctaText()}
                </Button>
              </div>
            </form>
            <div className="inline-flex items-center justify-center w-full mb-3">
              <hr className="w-full h-px my-2 bg-gray-400 border-0 " />
              <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2">
                o
              </span>
            </div>
            <div className="flex items-center md:flex-row sm:flex-col max-xs:flex-col  justify-center my-1 gap-2 ">
              {/* <div className="social-btn" style={{width: "210px" ,minWidth: "min-content"}}> */}
              <div className="social-btn" style={{ minWidth: "max-content" }}>
                <span className="w-full">
                  <button
                    type="button"
                    className="social-login-btn-text bg-mainColor flex justify-center items-center rounded-lg cursor-pointer outline-none w-full"
                    onClick={() => handleGoogleLogin()}
                  >
                    <FcGoogle className="mr-4" size={20} />
                    <span className="pl-2 text-sm">Ingresar con Google</span>
                  </button>
                </span>
              </div>
              <div className="my-2">
                {/* <div className="social-btn" style={{width: "210px" ,minWidth: "min-content"}}> */}
                <div className="social-btn" style={{ minWidth: "max-content" }}>
                  <FacebookLogin
                    appId={CLIENT_ID_FACEBOOK}
                    autoLoad={false}
                    size="medium"
                    fields="name,email,picture"
                    scope="public_profile,email,user_friends"
                    callback={responseFacebook}
                    cssClass="social-login-btn-text bg-mainColor flex items-center rounded-lg cursor-pointer outline-none text-sm  w-full"
                    icon={
                      <FaFacebook
                        color="#4267B2"
                        size={20}
                        className="content-start"
                      />
                    }
                    textButton={
                      <span className="text-center w-full pl-1 text-sm">
                        Ingresar con Facebook
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="my-1 text-center flex md:flex-row  sm:flex-col max-xs:flex-col items-center justify-center gap-2 ">
              <p>¿No tienes tu cuenta?</p>
              <p
                className="text-secondary font-semibold cursor-pointer hover:underline w-fit"
                onClick={onOpenSignupModal}
              >
                Regístrate
              </p>
            </div>
          </div>
        );

      case "forgot_pass":
        return (
          <div className="mx-6 mt-3 pt-2 border-t-2 border-t-gray-300">
            <form className="w-full" onSubmit={loginFormSubmitHandler}>
              <TextField
                label="Correo electrónico"
                name="email"
                icon={<AiOutlineMail className="text-xl" />}
                placeholder="Ingresa tu correo electrónico"
              />
              {/* <TextField
                label="Contraseña"
                name="password"
                icon={<AiOutlineLock className="text-xl" />}
                placeholder="Ingresa tu contraseña"
                type="password"
              /> */}
              <div className="flex items-center justify-center my-6">
                <Button disabled={isLoading} type="submit" className="w-full">
                  {ctaText()}
                </Button>
              </div>
            </form>
            <div className="inline-flex items-center justify-center w-full mb-3">
              <hr className="w-full h-px my-2 bg-gray-400 border-0 " />
              <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2">
                o
              </span>
            </div>
            <div className="my-1 text-center flex md:flex-row  sm:flex-col max-xs:flex-col items-center justify-center gap-2 ">
              <p>¿Ya tienes tu cuenta?</p>
              <p
                className="text-secondary font-semibold cursor-pointer hover:underline w-fit"
                onClick={() => setInputStage("login")}
              >
                Ingresar
              </p>
            </div>
          </div>
        );

      case "reset_pass":
        return (
          <div className="mx-6 mt-3 pt-2 border-t-2 border-t-gray-300">
            <form className="w-full" onSubmit={loginFormSubmitHandler}>
              <TextField
                label="Correo electrónico"
                name="email"
                icon={<AiOutlineMail className="text-xl" />}
                placeholder="Ingresa tu correo electrónico"
                disabled={true}
              />
              <TextField
                label="Restablecer Código"
                name="resetCode"
                icon={<MdOutlinePassword className="text-xl" />}
                placeholder="Ingrese el código de reinicio"
              />
              <TextField
                label="Nueva contraseña"
                name="password"
                icon={<AiOutlineLock className="text-xl" />}
                placeholder="Ingresa tu nueva contraseña"
                type="password"
              />
              {/* <TextField
                label="Confirmar Contraseña"
                name="confirmPassword"
                icon={<AiOutlineLock className="text-xl" />}
                placeholder="Vuelve a escribir la contraseña"
                type="password"
              /> */}
              <div className="flex items-center justify-center my-6">
                <Button disabled={isLoading} type="submit" className="w-full">
                  {ctaText()}
                </Button>
              </div>
            </form>
            <div className="inline-flex items-center justify-center w-full mb-3">
              <hr className="w-full h-px my-2 bg-gray-400 border-0 " />
              <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2">
                o
              </span>
            </div>
            <div className="my-1 text-center flex md:flex-row  sm:flex-col max-xs:flex-col items-center justify-center gap-2 ">
              <p>¿Ya tienes tu cuenta?</p>
              <p
                className="text-secondary font-semibold cursor-pointer hover:underline w-fit"
                onClick={() => setInputStage("login")}
              >
                Ingresar
              </p>
            </div>
          </div>
        );
    }
  };

  const header = () => {
    switch (inputStage) {
      case "login":
        return "Ingresar";

      case "forgot_pass":
        return "Has olvidado tu contraseña";

      case "reset_pass":
        return "Restablecer la contraseña";
    }
  };
  const ctaText = () => {
    switch (inputStage) {
      case "login":
        return "Ingresar";

      case "forgot_pass":
        return "Continuar";

      case "reset_pass":
        return "Entregar";
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeLoginModal}
      style={customStyles}
      contentLabel="SignupModal"
    >
      <button className="absolute top-6 right-6" onClick={closeLoginModal}>
        <AiOutlineClose className="text-2xl" />
      </button>
      <>
        <div className="py-3">
          <h1 className="text-2xl font-[600] text-primary text-center">
            {header()}
          </h1>
        </div>
        <>{inputForm()}</>
      </>
    </Modal>
  );
};

export default LoginModal;
