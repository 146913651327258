import React from "react";
// import { Link } from "react-router-dom";
// import { BsArrowRightShort } from "react-icons/bs";
import placeHolderImage from "../../../assets/images/blog-page-placeholder.webp";
import Image from "../Image/index";

const Card = ({ id, img, title, category, details }) => {
  return (
    <div className="box">
      <div className="py-4">
        <div
          // className="relative overflow-hidden bg-cover bg-no-repeat rounded-2xl w-full md:w-12/12 md:h-44"
          className="relative overflow-hidden bg-cover bg-no-repeat rounded-2xl w-full md:w-12/12"
          data-te-ripple-init
          data-te-ripple-color="light"
        >
          <Image
            image={{ src: img, alt: title }}
            // src={img}
            // alt={title}
            className="rounded-2xl"
            placeholder={
              <image src={placeHolderImage} className="rounded-2xl" />
            }
          />

          <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100" />
        </div>
        <div className="px-0 pb-0 pt-2">
          <span className="text-sm leading-[0] text-secondary  font-semibold pb-2">
            {/* Pharmacy Tips */}
            {category || "Pharmacy Tips"}
          </span>
          <h5 className="font-semibold mb-0 text-lg leading-[1.3em]">
            {title}
          </h5>
          <p className="text-gray-500 text-sm py-1">{details}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
