import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { orderStatusObj } from "../../constants";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomTable from "../Shared/CustomTable/CustomTable";
import { _convertToSentenceCase, formatCurrency } from "../../utils";
import { S3_BASE_URL } from "../../utils/baseURL";
import placeHolderImage from "../../assets/images/pills.placeholder.webp";
import { Oval } from "react-loader-spinner";
import { CurrencyContext } from "../../context/CurrencyProvider";
import userRequest from "../../utils/requestMethods";

const getSlug = (location) => {
  try {
    return location?.pathname?.split("/")?.[2]?.split("?")?.[0] || "";
  } catch (err) {
    return "";
  }
};

const columns = (
  <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
    <tr>
      {/* <th scope="col" className="px-6 py-3">
        <div className="flex items-center">Image</div>
      </th> */}
      <th scope="col" className="px-6 py-3">
        <div className="flex items-center">Nombre del medicamento</div>
      </th>
      <th scope="col" className="px-6 py-3">
        <div className="flex items-center">Cantidad</div>
      </th>
      <th scope="col" className="px-6 py-3">
        <div className="flex items-center">Precio</div>
      </th>{" "}
    </tr>
  </thead>
);

const MyOrderDetails = () => {
  const { user } = useSelector((state) => state?.auth);
  const { selectedCurrency } = useContext(CurrencyContext);
  const location = useLocation();
  const navigate = useNavigate();
  let id = location?.pathname?.split("/")?.[2] || "";

  // const order = location.state;

  const orderId = getSlug(location);
  const curruntDate = moment().format("DD-MM-YYYY");
  // const status = orderStatusObj.pending;

  const [order, setOrder] = useState();
  const [statusField, setStatusFiled] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (order?.status === orderStatusObj.pending) {
      setStatusFiled(PendingOrder);
    } else if (order?.status === orderStatusObj.inProgress) {
      setStatusFiled(InProgressOrder);
    } else if (order?.status === orderStatusObj.completed) {
      setStatusFiled(CompletedOrder);
    } else if (order?.status === orderStatusObj.canceled) {
      setStatusFiled(CancelledOrder);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [order]);

  const fetchOrder = async () => {
    setIsLoading(true);
    const payload = {
      orderIdNo: id,
    };
    if (selectedCurrency) {
      payload.currencyFilter = selectedCurrency?.currency || "MXN";
    }
    try {
      const response = await userRequest.get(`/order`, {
        params: payload,
      });
      const { data } = response?.data || {};
      // console.log("data >>>", data);
      setOrder(data?.[0]);
      setIsLoading(false);
    } catch (error) {
      console.log("error from catch block >>>", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchOrder();
    } else {
      navigate("/mis-pedidos");
    }
  }, [selectedCurrency, user]);

  if (isLoading) {
    return (
      <div
        className="flex justify-center items-center h-72 w-full"
        data-aos="fade-up"
      >
        <Oval
          height={50}
          width={50}
          color="rgba(51, 124, 171, 1)"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="rgba(51, 124, 171, 1)"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }

  return (
    <div className="py-14 ">
      <div className="px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto bg-white p-5 rounded-xl">
        <div className="flex justify-start item-start space-y-2 flex-col">
          <h1 className="text-3xl  lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">
            Orden #{orderId}
          </h1>
          <p className="text-base py-1  font-medium leading-6 text-gray-600">
            {/* {moment(order?.createdAt).format("L")} */}
          </p>
        </div>
        <div className="pb-4 mt-5 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
          <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
            <div className="flex justify-center flex-col md:flex-row items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
              <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-100  space-y-6 rounded-xl">
                <h3 className="text-xl  font-semibold leading-5 text-gray-800">
                  Información del pedido
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 ">
                  <div className="flex justify-between w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      Fecha de orden
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">
                      {curruntDate}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      Estado:
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">
                      {statusField}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-100  space-y-6 rounded-xl">
                <h3 className="text-xl  font-semibold leading-5 text-gray-800">
                  Información del cliente
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200">
                  <div className="flex justify-between w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      Nombre:
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">
                      {user?.name}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      Correo electrónico:
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">
                      {user?.email}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      Llamar:
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">
                      {order?.phoneNumber}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      Ciudad:
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">
                      {order?.city}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      País:
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">
                      {order?.country}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      DIRECCIÓN:
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">
                      {order?.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <CustomTable
            columns={columns}
            rows={order?.cart?.map((product) => {
              let image = "";
              let isImage = product?.extraInfo?.image;
              let isImages = product?.extraInfo?.images;
              let isArray = Array.isArray(isImages);

              if (isImage?.length && isImage?.includes("http")) {
                image = isImage;
              } else if (isImage?.length && !isImage?.includes("http")) {
                image = `${S3_BASE_URL}${isImage}`;
              } else if (
                isArray &&
                isImages?.length &&
                isImages?.[0]?.includes("http")
              ) {
                image = isImages?.[0];
              } else if (
                isArray &&
                isImages?.length &&
                !isImages?.[0]?.includes("http")
              ) {
                image = `${S3_BASE_URL}${isImages?.[0]}`;
              } else {
                image = placeHolderImage;
              }

              return (
                <tr key={product?._id}>
                  {/* <td className="px-6 py-2">{product?._id}</td> */}
                  <td className="px-6 py-2">
                    {/* {_convertToSentenceCase(product?.name)} */}
                    <div className="flex items-center">
                      <img
                        src={image}
                        alt={product?.name}
                        className="mr-6 h-12 w-12 rounded object-cover object-center"
                      />
                      <div>
                        <div className="font-medium text-gray-900">
                          {_convertToSentenceCase(product?.name)}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="hidden px-6 py-2 sm:table-cell">
                    {product?.quantity}
                  </td>
                  <td className="hidden px-6 py-2 sm:table-cell">
                    {formatCurrency(product?.extraInfo?.amount)}
                  </td>
                </tr>
              );
            })}
          />
        </div>
      </div>
    </div>
  );
};

const PendingOrder = () => (
  <div className="inline-flex items-center rounded bg-secondary-100 px-2.5 py-0.5 text-xs font-medium text-primary-800  ">
    <svg
      className="mr-1 h-3 w-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M18.5 4h-13m13 16h-13M8 20v-3.333a2 2 0 0 1 .4-1.2L10 12.6a1 1 0 0 0 0-1.2L8.4 8.533a2 2 0 0 1-.4-1.2V4h8v3.333a2 2 0 0 1-.4 1.2L13.957 11.4a1 1 0 0 0 0 1.2l1.643 2.867a2 2 0 0 1 .4 1.2V20H8Z"
      />
    </svg>
    {orderStatusObj.pending}
  </div>
);

const InProgressOrder = () => (
  <div className="inline-flex items-center rounded bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800  ">
    <svg
      className="mr-1 h-3 w-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      />
    </svg>
    {orderStatusObj.inProgress}
  </div>
);

const CompletedOrder = () => (
  <div className="inline-flex items-center rounded bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800  ">
    <svg
      className="mr-1 h-3 w-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 11.917 9.724 16.5 19 7.5"
      />
    </svg>
    {orderStatusObj.completed}
  </div>
);

const CancelledOrder = () => (
  <div className="inline-flex items-center rounded bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800  ">
    <svg
      className="mr-1 h-3 w-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18 17.94 6M18 18 6.06 6"
      />
    </svg>
    {orderStatusObj.canceled}
  </div>
);

export default MyOrderDetails;

{
  /* <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-100  space-y-6">
                <h3 className="text-xl  font-semibold leading-5 text-gray-800">
                  Order Info
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      Subtotal
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">$56.00</p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      Discount{" "}
                      <span className="bg-gray-200 p-1 text-xs font-medium   leading-3 text-gray-800">
                        STUDENT
                      </span>
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">
                      -$28.00 (50%)
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base py-1  leading-4 text-gray-800">
                      Shipping
                    </p>
                    <p className="text-base py-1  leading-4 text-gray-600">$8.00</p>
                  </div>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-base py-1  font-semibold leading-4 text-gray-800">
                    Total
                  </p>
                  <p className="text-base py-1  font-semibold leading-4 text-gray-600">
                    $36.00
                  </p>
                </div>
              </div> */
}

{
  /* <div className="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-100  space-y-6 rounded-xl">
<h3 className="text-xl  font-semibold leading-5 text-gray-800">
  Shipping
</h3>
<div className="flex justify-between items-start w-full">
  <div className="flex justify-center items-center space-x-4">
    <div className="w-8 h-8">
      <img
        className="w-full h-full"
        alt="logo"
        src="https://i.ibb.co/L8KSdNQ/image-3.png"
      />
    </div>
    <div className="flex flex-col justify-start items-center">
      <p className="text-lg leading-6  font-semibold text-gray-800">
        DPD Delivery
        <br />
        <span className="font-normal">
          Delivery with 24 Hours
        </span>
      </p>
    </div>
  </div>
  <p className="text-lg font-semibold leading-6  text-gray-800">
    $8.00
  </p>
</div>
<div className="w-full flex justify-center items-center">
  <button className="hover:bg-black    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base py-1 font-medium leading-4 text-white">
    View Carrier Details
  </button>
</div>
</div> */
}
