import React, { useEffect, useState } from "react";
import { handleSplitTxt, paragraph } from "../../utils";
import { S3_BASE_URL } from "../../utils/baseURL";
import Card from "../Shared/Card/Card";
import userRequest from "../../utils/requestMethods";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const FeaturedBlogsSection = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // console.log("blogs >>>", blogs);
  useEffect(() => {
    const fetchBlogs = async () => {
      const payload = {
        pageSize: 3,
        pageIndex: 0,
        deletedStatus: false,
      };
      try {
        const response = await userRequest.get(`/blog`, {
          params: payload,
        });
        setBlogs(response?.data);
        setIsLoading(false);
      } catch (error) {
        console.log("error from catch block >>>", error);
      }
    };
    fetchBlogs();
  }, []);
  return (
    <div className="py-6">
      <div>
        <h3 className="text-2xl sm:text-4xl font-[600] text-primary">
          Más blogs por descubrir
        </h3>
      </div>
      <div className="grid grid-cols-12 gap-x-8">
        {blogs &&
          blogs?.map((blog) => {
            return (
              <Link
                to={`/blog/${decodeURIComponent(handleSplitTxt(blog?.path))}`}
                state={blog?._id}
                className="col-span-12 md:col-span-6 lg:col-span-4 hover:cursor-pointer"
                key={blog?._id}
                data-aos="fade-up"
              >
                <Card
                  id={blog?._id}
                  img={
                    blog?.image ? `${S3_BASE_URL}${blog?.image}` : blog?.picture
                  }
                  title={blog?.title}
                  category={blog?.category}
                  details={paragraph(blog?.description)}
                />
              </Link>
            );
          })}
      </div>
      {!isLoading && (
        <div className="d-flex justify-center">
          <Link
            to={`/blog`}
            className="flex text-secondary items-center justify-center gap-2 font-[600] transition-all duration-300 ease-in cursor-pointer hover:underline w-fit mx-auto"
          >
            Ver todos <BsArrowRight className="text-2xl font-[600]" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default FeaturedBlogsSection;
