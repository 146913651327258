import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CurrencyProvider } from "./context/CurrencyProvider";
import { LocationProvider } from "./context/LocationProvider";

const CLIENT_ID_GOOGLE =
  "614167185762-fev4trojeo54dh25jgffs8ttib44jhdh.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={CLIENT_ID_GOOGLE}>
    <Provider store={store}>
      <LocationProvider>
        <CurrencyProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CurrencyProvider>
      </LocationProvider>
    </Provider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);
