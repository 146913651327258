import React, { useState } from "react";
import { AiOutlineClose, AiOutlineLock, AiOutlineMail } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import Modal from "react-modal";
import PhoneInput from "react-phone-number-input";
import TextField from "../Shared/TextField/TextField";
import "react-phone-number-input/style.css";
import Button from "../Shared/Button/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "../../utils/baseURL";
import { useDispatch } from "react-redux";
import { login } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

import {
  // GoogleOAuthProvider, GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import { FaFacebook } from "react-icons/fa";
import FacebookLogin from "react-facebook-login";
import { FcGoogle } from "react-icons/fc";

const CLIENT_ID_FACEBOOK = "632730335658709";

const customStyles = {
  content: {
    backgroundColor: "#fff",
    // height: "fit-content",
    height: "100vh",
    margin: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

Modal.setAppElement("#modal");

const SignupModal = ({ isOpen, closeSignupModal, onOpenLoginModal }) => {
  const [value, setValue] = useState();
  const [receiveCommercialCommunications, setReceiveCommercialCommunications] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signupFormSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // get values of text fields
    const name = e.target.name.value;
    const email = e.target.email.value;
    const password = e.target.password.value;

    const body = {
      name,
      email,
      password,
      phoneNumber: value,
      receiveCommercialCommunications: receiveCommercialCommunications,
    };
    // if (!name || !email || !password) {
    if (!name || !email || !password || !value) {
      setIsLoading(false);
      return toast.warn("Por favor llena todos los espacios");
    }

    // if (!receiveCommercialCommunications) {
    //   setIsLoading(false);
    //   // return toast.warn("Please accept the terms and conditions before submitting the form.")
    //   return toast.warn(
    //     "Por favor acepte los términos y condiciones antes de enviar el formulario."
    //   );
    // }

    try {
      const response = await axios.post(`${baseURL}/auth/signup`, body);

      if (response) {
        const loginBody = {
          email,
          password,
        };
        const loginResponse = await axios
          .post(`${baseURL}/auth/signin`, loginBody)
          .catch((err) => {
            toast.error(err.loginResponse.data.error);
            setIsLoading(false);
          });
        if (loginResponse) {
          dispatch(login(loginResponse.data));
          localStorage.setItem("user", JSON.stringify(loginResponse.data.user));
          localStorage.setItem("token", loginResponse.data.token);
          toast.success("Registrado exitosamente");
          closeSignupModal();
          setIsLoading(false);
          // redirect to home page
          navigate("/");
        }
        // setIsLoading(false);
        // toast.success("Registrado exitosamente");
        // closeSignupModal();
        // onOpenLoginModal();
      }
    } catch (error) {
      toast.error(error.response.data?.error || error.response.data?.message);
      setIsLoading(false);
    }
  };

  const responseGoogle = useGoogleLogin({
    onSuccess: async (authResponse) => {
      // console.log(" authResponse >>>", authResponse);
      if (authResponse?.access_token) {
        const body = {
          socialLogin: "google",
          googleAccessToken: authResponse?.access_token,
          access_token: true,
        };
        // axios
        const serverResponse = await axios
          .post(`${baseURL}/auth/signin`, body)
          .catch((err) => {
            toast.error(err.response.data.error);
            setIsLoading(false);
          });

        // console.log("serverResponse >>>",serverResponse);
        if (serverResponse.status === 200) {
          dispatch(login(serverResponse?.data));
          // store user in local storage
          localStorage.setItem(
            "user",
            JSON.stringify(serverResponse.data.user)
          );
          // store token in local storage
          localStorage.setItem("token", serverResponse.data.token);
          // close login modal
          closeSignupModal();
          setIsLoading(false);
          toast.success("Ingresado exitosamente");
          // redirect to home page
          navigate("/");
        }
      }
    },
    onError: (error) => {
      console.log(" error >>>", error);
      toast.error(
        "Lo sentimos, se produjo un error al intentar iniciar sesión con Google. Por favor, inténtelo de nuevo más tarde"
      );
    },
    // flow: 'auth-code',
  });

  const responseFacebook = async (response) => {
    // console.log("response from facebook oauth", response);
    if (response.status !== "unknown") {
      const body = {
        socialLogin: "facebook",
        facebookAccessToken: response?.accessToken,
      };
      // console.log("body >>>", body);
      const serverResponse = await axios
        .post(`${baseURL}/auth/signin`, body)
        .catch((err) => {
          toast.error(err.serverResponse.data.error);
          setIsLoading(false);
        });
      // console.log("serverResponse >>>",serverResponse);
      if (serverResponse.status === 200) {
        dispatch(login(serverResponse?.data));
        // store user in local storage
        localStorage.setItem(
          "user",
          JSON.stringify(serverResponse?.data?.user)
        );
        // store token in local storage
        localStorage.setItem("token", serverResponse?.data?.token);
        // close login modal
        closeSignupModal();
        setIsLoading(false);
        toast.success("Ingresado exitosamente");
        // redirect to home page
        navigate("/");
      }
    } else {
      toast.error(
        "Lo sentimos, se produjo un error al intentar iniciar sesión con Facebook. Por favor, inténtelo de nuevo más tarde"
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeSignupModal}
      style={customStyles}
      contentLabel="SignupModal"
    >
      <button className="absolute top-6 right-6" onClick={closeSignupModal}>
        <AiOutlineClose className="text-2xl" />
      </button>
      <div className="py-2">
        <h1 className="text-2xl font-[600] text-primary text-center">
          Registro
        </h1>
      </div>
      <div className="mx-6 mt-2 py-2 border-t-2 border-t-gray-300">
        <form className="w-full" onSubmit={signupFormSubmitHandler}>
          <TextField
            label="Nombre"
            name="name"
            type="name"
            icon={<BsPerson className="text-xl" />}
            placeholder="Ingresa tu nombre"
          />
          <div className="my-3">
            <label htmlFor="phoneNumber" className="font-[600]">
              Teléfono
            </label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="MX"
              placeholder="Ingresa tu número de teléfono"
              className="bg-lightSecondary py-3 px-3 rounded-md mt-2 w-full"
              value={value}
              onChange={setValue}
            />
          </div>
          <TextField
            label="Correo electrónico"
            name="email"
            type="email"
            icon={<AiOutlineMail className="text-xl" />}
            placeholder="Ingresa tu correo electrónico"
          />
          <TextField
            label="Contraseña"
            name="password"
            icon={<AiOutlineLock className="text-xl" />}
            placeholder="Ingresa tu contraseña"
            type="password"
          />
          <div className="flex items-center gap-2">
            <input
              checked={receiveCommercialCommunications}
              id="checked-checkbox"
              type="checkbox"
              defaultValue
              onChange={(e) =>
                setReceiveCommercialCommunications(e.target.checked)
              }
              className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="checked-checkbox" className="text-gray-900 text-sm">
              Quiero recibir comunicaciones comerciales y novedades de Vooyfarma
              según mi perfil
            </label>
          </div>
          <p className="text-sm pt-4">
            {/* “Al hacer clic en «Registrarse», aceptas las Condiciones de uso, la Política de privacidad y la Política de cookies de Vooyfarma.” */}
            Al hacer clic en «Registrarse», aceptas las 
            <a
              href="https://vooyfarma.com/terminos-y-condiciones"
              target="_blank"
              rel="noopener noreferrer"
              className="underline hover:text-blue-600 "
            >
              Condiciones de uso
            </a>
            , la 
            <a
              href="https://vooyfarma.com/politica-de-privacidad"
              target="_blank"
              rel="noopener noreferrer"
              className="underline hover:text-blue-600 "
            >
              Política de privacidad
            </a>
             y la{" "}
            <a
              href="https://vooyfarma.com/politica-de-cookies"
              target="_blank"
              rel="noopener noreferrer"
              className="underline hover:text-blue-600 "
            >
              Política de cookies
            </a>{" "}
            de Vooyfarma.
          </p>
          <div className="flex items-center justify-center mt-4 mb-3">
            <Button type="submit" className="w-full" disabled={isLoading}>
              Registrarse
            </Button>
          </div>
        </form>
        <div className="inline-flex items-center justify-center w-full mb-3">
          <hr className="w-full h-px my-2 bg-gray-400 border-0 " />
          <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2">
            o
          </span>
        </div>
        {/* <div className="flex items-center flex-col my-1"> */}
        <div className="flex items-center md:flex-row sm:flex-col max-xs:flex-col  justify-center my-1 gap-2 ">
          <div className="social-btn" style={{ minWidth: "max-content" }}>
            <span className="w-full">
              <button
                type="button"
                className="social-login-btn-text bg-mainColor flex justify-center items-center rounded-lg cursor-pointer outline-none w-full"
                onClick={() => responseGoogle()}
              >
                <FcGoogle className="mr-4" size={20} />
                <span className="pl-2 text-sm">Registrarse con Google</span>
              </button>
            </span>
          </div>
          <div className="my-2">
            <div className="social-btn" style={{ minWidth: "max-content" }}>
              <FacebookLogin
                appId={CLIENT_ID_FACEBOOK}
                autoLoad={false}
                size="medium"
                fields="name,email,picture"
                scope="public_profile,email,user_friends"
                callback={responseFacebook}
                cssClass="social-login-btn-text bg-mainColor flex items-center rounded-lg cursor-pointer outline-none text-sm  w-full"
                icon={
                  <FaFacebook
                    color="#4267B2"
                    size={20}
                    className="content-start"
                  />
                }
                textButton={
                  <span className="text-center w-full pl-1 text-sm">
                    Registrarse con Facebook
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="my-1 text-cente flex md:flex-row  sm:flex-col max-xs:flex-col items-center justify-center gap-2">
          <p className="py-1">¿Ya tienes tu cuenta?</p>
          <p
            className="text-secondary py-1 font-semibold cursor-pointer hover:underline w-fit"
            onClick={onOpenLoginModal}
          >
            Ingresar
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default SignupModal;
