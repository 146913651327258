import React, { useState, useReducer, useEffect } from "react";
import SelectDropDownWithouLabel from "../Shared/SelectDropDownWithouLabel/SelectDropDownWithouLabel";
import { reactSelectStyleWithoutZIndex } from "../../utils/style";
import "./ProductComparisonSection.css";
import { CiSearch } from "react-icons/ci";
import userRequest from "../../utils/requestMethods";
import { PAGING_SIZE } from "../../constants";
import { TiDeleteOutline } from "react-icons/ti";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import SelectDropDown from "../Shared/SelectDropDown/SelectDropDown";
import useAddToCart from "../../hooks/useAddToCart";
import CartHelper from "../../helpers/CartHelper";
const dropDownOptions = [
  {
    label: "Keytruda",
    value: "Keytruda",
  },
  {
    label: "Ibuprofeno",
    value: "Ibuprofeno",
  },
  {
    label: "Simvastatina",
    value: "Simvastatina",
  },
  {
    label: "Omeprazol",
    value: "Omeprazol",
  },
];

const cleanPrice = (str) => {
  if (typeof str === "string") {
    str = str?.replace(/\$/g, "").replace(/#N\/A/g, "");
    return str;
  } else if (typeof str === "number") {
    return str?.toString();
  } else {
    return 0.0;
  }
};

const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE";
const SEARCH_STARTED = "SEARCH_STARTED";
const SEARCH_SUCCESS = "SEARCH_SUCCESS";
const SEARCH_FAILURE = "SEARCH_FAILURE";
const UPDATE_SEARCH_HISTORY = "UPDATE_SEARCH_HISTORY";
const UPDATE_SEARCH_HISTORY_WITH_RESULTS = "UPDATE_SEARCH_HISTORY_WITH_RESULTS";
const UPDATE_SEARCH_HISTORY_AND_RESULTS = "UPDATE_SEARCH_HISTORY_AND_RESULTS";

const initialState = {
  searchValue: "",
  country: "",
  // country: {},
  searchResults: [],
  searchHistory: [],
  searchHistoryWithResult: [],
  pharmacies: [],
  isLoading: false,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_VALUE:
      return { ...state, searchValue: action.payload };
    case "updateValue": {
      let newState = { ...state };
      newState[action.payload.key] = action.payload.value;
      return newState;
    }
    case SEARCH_STARTED:
      return { ...state, isLoading: true, error: null };
    case SEARCH_SUCCESS:
      return { ...state, isLoading: false, searchResults: action.payload };
    case SEARCH_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case UPDATE_SEARCH_HISTORY:
      // Handle updating search history
      const newSearchHistory = [...state.searchHistory];
      if (!newSearchHistory.includes(action.payload)) {
        newSearchHistory.push(action.payload);
      }
      return { ...state, searchHistory: newSearchHistory };
    case UPDATE_SEARCH_HISTORY_WITH_RESULTS:
      // Handle updating search history
      const newSearchHistoryWithResult = [...state.searchHistoryWithResult];
      if (!newSearchHistoryWithResult.includes(action.payload)) {
        newSearchHistoryWithResult.push(action.payload);
      }
      let pharmacyNames = new Set();

      newSearchHistoryWithResult.forEach((entry) => {
        entry.result.forEach((pharmacy) => {
          pharmacyNames.add({
            name: pharmacy.pharmacyName,
            previewUrl:
              pharmacy?.medicines?.[0]?.pharmacyStore?.[0]?.previewUrl,
          });
        });
      });

      let uniquePharmacyNames = Array.from(pharmacyNames);

      return {
        ...state,
        searchHistoryWithResult: newSearchHistoryWithResult,
        pharmacies: uniquePharmacyNames,
      };
    case UPDATE_SEARCH_HISTORY_AND_RESULTS: {
      const newSearchHistory = [...state.searchHistory].filter(
        (history) => history !== action.payload.keyword
      );
      const newSearchResultsUpdate = [...state.searchHistoryWithResult].filter(
        (history) => history.keyword !== action.payload.keyword
      );

      return {
        ...state,
        searchHistory: newSearchHistory,
        searchHistoryWithResult: newSearchResultsUpdate,
      };
    }
    case "setState": {
      return action.payload;
    }
    default:
      return state;
  }
};

const ProductComparisonSection = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    searchValue,
    searchResults,
    searchHistory,
    searchHistoryWithResult,
    pharmacies,
    isLoading,
    error,
  } = state;
  const [columnTotals, setColumnTotals] = useState(pharmacies.map(() => 0));
  const [selectedMeds, setSelectedMeds] = useState({});
  const [missingProducts, setMissingProducts] = useState({});
  const [pharmacyShopsWithCountry, setPharmacyShopsWithCountry] = useState([]);
  const { cart, addProductToCart, removeProductFromCart, onPressCartIcon } =
    useAddToCart();

  const handleChange = (event) => {
    dispatch({ type: UPDATE_SEARCH_VALUE, payload: event.target.value });
  };

  useEffect(() => {
    if (state?.searchResults?.length)
      sessionStorage.setItem("filterState", JSON.stringify(state));
  }, [state]);
  const parseJson = (json) => {
    try {
      return JSON.parse(json);
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    let savedState = parseJson(sessionStorage.getItem("filterState"));
    if (savedState) {
      savedState.isLoading = false;
      savedState.error = null;
      dispatch({ payload: savedState, type: "setState" });
    }
  }, []);

  const handleFilter = (value) => {
    dispatch({ payload: { key: "country", value }, type: "updateValue" });
    dispatch({ type: UPDATE_SEARCH_HISTORY, payload: searchValue });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event === "click") {
      dispatch({ type: SEARCH_STARTED });
      searchChangeHandler(searchValue);
    }
  };

  const fetchPharmacyStoreWithCountry = async () => {
    try {
      const response = await userRequest.get("/country-store");
      let formatDate = response?.data?.filter((item) => {
        if (item?.value) {
          return item;
        }
      });
      setPharmacyShopsWithCountry([
        {
          value: "",
          label: "Todos",
        },
        ...formatDate,
      ]);
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  };

  useEffect(() => {
    fetchPharmacyStoreWithCountry();
  }, []);

  const searchChangeHandler = async (value) => {
    if (searchValue && !searchHistory.includes(searchValue)) {
      dispatch({ type: UPDATE_SEARCH_HISTORY, payload: { searchValue } });
    }
    try {
      const payload = {
        // pageSize: PAGING_SIZE,
        pageSize: 10,
        pageIndex: 0,
        searchFilter: value,
        // country: state?.country?.value,
        country: state?.country,
      };
      const response = await userRequest.get(`/cart-comparison`, {
        params: payload,
      });

      if (response.data?.perPharmacyTotal) {
        const result = response.data?.perPharmacyTotal;
        dispatch({ type: SEARCH_SUCCESS, payload: result });
        if (result) {
          dispatch({
            type: UPDATE_SEARCH_HISTORY_WITH_RESULTS,
            payload: {
              keyword: searchValue,
              result: result,
            },
          });
        }
      }
    } catch (error) {
      dispatch({ type: SEARCH_FAILURE, payload: error });
    }
  };

  const handleRemoveSearchKeyword = (keyword) => {
    dispatch({
      type: UPDATE_SEARCH_HISTORY_AND_RESULTS, // Customize action type based on your reducer
      payload: {
        keyword: keyword,
      },
    });
  };

  const handleMedicineChange = (pharmacy, keyword, selectedOption) => {
    setSelectedMeds((prevSelectedMeds) => ({
      ...prevSelectedMeds,
      [keyword]: {
        ...prevSelectedMeds[keyword],
        [pharmacy]: selectedOption,
      },
    }));
  };

  const recalculateTotals = () => {
    const columnTotals = pharmacies.map((pharmacy) => {
      pharmacy = pharmacy?.name;
      return searchHistoryWithResult.reduce((acc, history) => {
        const selectedOption = selectedMeds[history.keyword]?.[pharmacy];
        return acc + (+selectedOption?.amount?.replaceAll(/,/g, "") || 0);
      }, 0);
    });
    setColumnTotals(columnTotals);
  };

  const calculateMissingProducts = () => {
    const allPharmacies = new Set();
    for (const med in selectedMeds) {
      for (const pharmacy in selectedMeds[med]) {
        allPharmacies.add(pharmacy);
      }
    }
    const pharmacyList = Array.from(allPharmacies);

    const missingMeds = {};

    pharmacyList.forEach((pharmacy) => {
      missingMeds[pharmacy] = false;
      for (const med in selectedMeds) {
        if (!selectedMeds[med].hasOwnProperty(pharmacy)) {
          missingMeds[pharmacy] = true;
          break;
        }
      }
    });
    setMissingProducts(missingMeds);
  };

  useEffect(() => {
    recalculateTotals();
    calculateMissingProducts();
  }, [selectedMeds]);

  const handleAddToCart = (pharmacy) => {
    const selectedMedicinesIds = [];
    for (const key in selectedMeds) {
      if (selectedMeds[key][pharmacy]?.id)
        selectedMedicinesIds.push(selectedMeds[key][pharmacy]);
    }
    selectedMedicinesIds.forEach((med) => addToCart(med));
  };

  const addToCart = (product) => {
    const isAlreadyAddedProductIndex = CartHelper.getAlreadyProductAddedIndex(
      cart,
      product
    );
    // console.log("product >>", product);
    let existingMedicine = cart?.[isAlreadyAddedProductIndex];
    if (existingMedicine) {
      existingMedicine = {
        ...existingMedicine,
        quantity: existingMedicine.quantity + 1,
      };
      addProductToCart({
        ...product,
        quantity: existingMedicine.quantity,
        // listSlug: slug,
        id: product?.id,
      });
      // toast.success(
      //   `${product?.name} cantidad actualizada a ${existingMedicine.quantity}`
      // );
    } else {
      addProductToCart({
        ...product,
        quantity: 1,
        // listSlug: slug,
        id: product?.id,
      });
      // toast.success(`${product?.name} añadido al carrito`);
    }
  };

  useEffect(() => {
    // Initialize selectedMeds with default values
    const initialSelectedMeds = {};
    searchHistoryWithResult.forEach((history) => {
      initialSelectedMeds[history.keyword] = {};
      pharmacies.forEach((pharmacy) => {
        pharmacy = pharmacy?.name;
        const item = history?.result?.find((r) => r.pharmacyName === pharmacy);
        if (item?.medicines?.length) {
          if (
            selectedMeds[history.keyword] &&
            selectedMeds[history.keyword][pharmacy]
          ) {
            initialSelectedMeds[history.keyword][pharmacy] = {
              id: selectedMeds[history.keyword][pharmacy]?.id,
              value: selectedMeds[history.keyword][pharmacy]?.value,
              amount: cleanPrice(
                selectedMeds[history.keyword][pharmacy]?.amount
              ),
            };
          } else {
            initialSelectedMeds[history.keyword][pharmacy] = {
              id: item.medicines[0]?.id,
              value: item.medicines[0]?.name,
              amount: cleanPrice(item.medicines[0]?.amount),
            };
          }
        }
      });
    });
    setSelectedMeds(initialSelectedMeds);
  }, [searchHistoryWithResult, pharmacies]);

  // Display loading indicator while searching
  if (isLoading) {
    return <p>Searching...</p>;
  }

  // Display error message if search fails
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  console.log(searchHistoryWithResult);

  return (
    <div className="py-2">
      <div className="py-5">
        <div className="flex flex-col xs:flex-row xs:items-center xs:justify-between gap-4 ">
          <div className="flex items-center gap-2 rounded-lg bg-white px-4 py-4 flex-1 ">
            <CiSearch className="text-xl" />
            <input
              type="text"
              placeholder="Buscador de medicamentos"
              className="bg-transparent focus:outline-none w-full"
              value={searchValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <button
            className="bg-secondary text-white px-10 py-4 rounded-lg"
            onClick={() => handleKeyDown("click")} // Trigger search on button click
          >
            Buscar
          </button>
        </div>
        <div className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 gap-4 mt-2">
          <SelectDropDown
            options={pharmacyShopsWithCountry}
            label="País:"
            value={state?.country}
            onChange={(e) => handleFilter(e)}
          />
        </div>
      </div>

      <div className="relative overflow-x-auto">
        {searchHistoryWithResult?.length > 0 && (
          <table className="border-collapse border-gray-400 w-full">
            <thead>
              <tr>
                <td className="border-r-2 border-gray-300 capitalize text-gray-900 px-1 font-semibold">
                  -
                </td>
                {pharmacies?.map((pharmacy) => (
                  <th th className="border-r-4  border-gray-300 px-4  py-5">
                    <div className="flex flex-row items-center justify-center">
                      {pharmacy?.previewUrl ? (
                        <img
                          className="w-10 h-10 mb-3 rounded-full shadow-lg"
                          src={pharmacy?.previewUrl}
                          alt="avatar"
                        />
                      ) : null}
                      <p className="ml-3 mb-2">{pharmacy?.name}</p>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {searchHistoryWithResult?.map((history) => (
                <tr className="mt-px border-t border-gray-300 border-b  overflow-hidden bg-gray-100">
                  <td
                    className="border-r-2 border-gray-300 border-b capitalize text-gray-900 h-12 px-1 flex items-center -mt-px font-semibold"
                    width={150}
                  >
                    {history?.keyword}
                    <button
                      type="button"
                      className="ml-2 hover:text-red-500 focus:outline-none"
                      onClick={() => handleRemoveSearchKeyword(history.keyword)} // Pass keyword to handleRemoveSearch function
                    >
                      <TiDeleteOutline size={25} />
                    </button>
                  </td>
                  {pharmacies?.map((pharmacy) => {
                    pharmacy = pharmacy?.name;
                    const item = history?.result?.find(
                      (r) => r.pharmacyName === pharmacy
                    );
                    const defaultMedicine = item?.medicines?.[0] || {};
                    const selectedOption =
                      selectedMeds[history.keyword]?.[pharmacy] ||
                      defaultMedicine;

                    return (
                      <td className="border-gray-300 px-1 py-1  text-gray-600  font-semibold datawidth1 border-r-4   border-gray-300">
                        {item?.medicines?.length ? (
                          <CartComparisonSlice
                            title={item.pharmacyName}
                            showMissingProd={!item.medicines.length}
                            value={history.keyword}
                            options={item.medicines}
                            totalPrice={
                              item.total ? item.total.toFixed(2) : "0"
                            }
                            selectedMed={
                              selectedOption.value || item.medicines?.[0]?.name
                            }
                            selectedMedPrice={
                              selectedOption.amount ||
                              cleanPrice(item.medicines?.[0]?.amount)
                            }
                            handleChange={(selectedOption) =>
                              handleMedicineChange(
                                pharmacy,
                                history.keyword,
                                selectedOption
                              )
                            }
                          />
                        ) : (
                          <div className="text-center">--</div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
              <tr className="mt-px border-t border-gray-300 overflow-hidden bg-gray-100">
                <td className="bg-gray-100 border-r-2 border-gray-300 text-gray-900 h-12 font-bold px-1 justify-start -mt-px ">
                  Total Price
                </td>
                {console.log(columnTotals)}
                {columnTotals.map((total, index) => (
                  <td
                    key={index}
                    className="p-6 border-r-4  border-gray-300 text-gray-500 text-center"
                  >
                    ${+total?.toFixed(2)}
                  </td>
                ))}
              </tr>
              <tr className="border-gray-300 overflow-hidden bg-gray-100">
                <td scopeclassName="bg-gray-100 border-r-2 border-gray-300 text-gray-900 h-12 font-bold px-1 justify-start -mt-px " />
                {pharmacies.map((pharmacy, index) => (
                  <td
                    key={index}
                    className="px-6  border-l-2 border-r-4 border-gray-300 font-light text-red-500 whitespace-nowrap  min-w-[300px] text-center"
                  >
                    <button
                      className="flex items-center mb-4 text-white bg-black border-0 py-2 px-4 mx-auto focus:outline-none hover:bg-black rounded"
                      onClick={() => handleAddToCart(pharmacy?.name)}
                    >
                      Add To Cart
                    </button>
                  </td>
                ))}
              </tr>
              {searchHistoryWithResult?.length > 1 ? (
                <tr className="border-gray-300 overflow-hidden bg-gray-100">
                  <td scopeclassName="bg-gray-100 border-r-2 border-gray-300 text-gray-900 h-12 font-bold px-1 justify-start -mt-px " />
                  {pharmacies.map((pharmacy, index) => (
                    <td
                      key={index}
                      className="px-6 pb-6  border-l-2 border-r-4 border-gray-300 font-light text-red-500 whitespace-nowrap  min-w-[300px] text-center"
                    >
                      <span>
                        {missingProducts[pharmacy?.name]
                          ? "Missing Products"
                          : ""}
                      </span>
                    </td>
                  ))}
                </tr>
              ) : (
                <tr className="border-gray-300 overflow-hidden bg-gray-100">
                  <td scopeclassName="bg-gray-100 border-r-2 border-gray-300 text-gray-900 h-12 font-bold px-1 justify-start -mt-px " />
                  {pharmacies.map((pharmacy, index) => (
                    <td
                      key={index}
                      className="px-6 py-4 border-l-2 border-r-4 border-gray-300 font-light  whitespace-nowrap  min-w-[300px] text-center"
                    />
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const CartComparisonSlice = ({
  options,
  selectedMed,
  selectedMedPrice,
  handleChange,
}) => {
  options = options?.map((item) => ({
    label: item?.name,
    value: item?.name,
    amount: item?.amount,
    id: item?.id,
  }));
  const [selectedOption, setSelectedOption] = useState({
    value: selectedMed,
    amount: selectedMedPrice,
  });

  const handleSelectChange = (option) => {
    console.log(option);
    setSelectedOption(option);
    handleChange(option);
  };

  return (
    <div className="text-gray-600  flex items-center justify-between ">
      <span className="med-name text-xs w-full pr-2">
        <SelectDropDownWithouLabel
          options={options || dropDownOptions}
          value={selectedOption.value}
          onChange={handleSelectChange}
          style={reactSelectStyleWithoutZIndex}
        />{" "}
      </span>
      <span className="med-price font-semibold text-sm">
        ${cleanPrice(selectedOption?.amount || 0)}
      </span>
    </div>
  );
};

export default ProductComparisonSection;
