import React from "react";
import ProductComparisonSection from "../components/ProductComparisonSection/ProductComparisonSection";
import { Helmet } from "react-helmet";

const ProductComparison = () => {
  return (
    <div className="my-6 px-10 py-12 rounded-2xl relative lg:w-10/12 mx-auto">
      <Helmet>
        <link rel="canonical" href="https://vooyfarma.com/comparador" />
      </Helmet>
      <div className="pb-4">
        <h1 className="text-start text-2xl sm:text-4xl font-[600] text-primary">
          Product Comparison
        </h1>
      </div>
      <ProductComparisonSection />
    </div>
  );
};

export default ProductComparison;
