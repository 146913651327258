import React, { useContext } from "react";
import placeHolderImage from "../../assets/images/pills.placeholder.webp";
import { Helmet } from "react-helmet";

import {
  _convertAndCheckArrayOfStringIntoSentaenceCase,
  _convertToSentenceCase,
  clearLogs,
  convertArrayOfStringIntoSentaenceCase,
} from "../../utils";
import { Currencies, whatsAppNum } from "../../constants";
import { CurrencyContext } from "../../context/CurrencyProvider";
import useAddToCart from "../../hooks/useAddToCart";
import CartHelper from "../../helpers/CartHelper";
import { toast } from "react-toastify";
import _ from "lodash";
const globalSingapureLogo =
  "https://vooy-farma.s3.amazonaws.com/images/Logo+Global+Singapure.png";
  const GLOBAL_SINGAPURE_NAME = "Global Singapore";
const SharedMedicineItemV1 = ({
  name,
  pharmacyName,
  price,
  image,
  medicine,
  deliveryTime,
  originCountry,
  isNavigateToWeb,
  isNavigateToWhatsapp,
  url,
  urlFda,
  urlEma,
  inventory,
  brand,
  slug,
  isDisplayExtraInfo,
  extraInfo,
  previewUrl,
}) => {
  console.log("medicine >>>", medicine);
  const { selectedCurrency } = useContext(CurrencyContext);
  const { PRODUCT, ...otherInfo } = extraInfo || {};
  const isIngredinets =
    medicine?.ingredients?.length || medicine?.ExtraInfo?.Ingredientes?.length;

  const text = `Hola, quiero cotizar el Medicamento: ${_convertToSentenceCase(
    name
  )}${
    isIngredinets
      ? `, Sustancia Activa: ${
          medicine?.ingredients?.length
            ? convertArrayOfStringIntoSentaenceCase(
                medicine?.ingredients
              )?.join(", ")
            : convertArrayOfStringIntoSentaenceCase(
                medicine?.ExtraInfo?.Ingredientes
              )?.join(", ")
        }`
      : ""
  }`;

  const whatsappURL = `https://wa.me/${whatsAppNum}?text=${text}`;

  // add to cart
  const { cart, addProductToCart, removeProductFromCart, onPressCartIcon } =
    useAddToCart();
  // console.log("cart >>>", cart);
  // console.log("slug >>>", slug);

  const isAlreadyAddedProductIndex = CartHelper.getAlreadyProductAddedIndex(
    cart,
    medicine
  );
  const addToCart = (product) => {
    // console.log("product >>", product);
    let existingMedicine = cart?.[isAlreadyAddedProductIndex];
    if (existingMedicine) {
      existingMedicine = {
        ...existingMedicine,
        quantity: existingMedicine.quantity + 1,
      };
      addProductToCart({
        ...product,
        quantity: existingMedicine.quantity,
        listSlug: slug,
        id: product?._id,
      });
      toast.success(
        `${product?.name} cantidad actualizada a ${existingMedicine.quantity}`
      );
    } else {
      addProductToCart({
        ...product,
        quantity: 1,
        listSlug: slug,
        id: product?._id,
      });
      toast.success(`${product?.name} añadido al carrito`);
    }
  };

  const handleNavigateToWhatsapp = () => {
    window.open(whatsappURL, "_blank");
  };

  const handleNavigate = () => {
    if (isNavigateToWhatsapp) {
      handleNavigateToWhatsapp();
    } else if (url) {
      window.open(url, "_blank");
    } else {
    }
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: name || "",
    image: image || "",
    description: name || "",
    brand: {
      "@type": "Brand",
      name: pharmacyName || "",
    },
    offers: {
      "@type": "Offer",
      url: "https://vooyfarma.com/buscar",
      priceCurrency: "MXN",
      price: parseFloat(price) || "0.00",
      availability: "https://schema.org/InStock",
      shippingDetails: {
        "@type": "ShippingDeliveryTime",
        transitTime: "2-3 Business Days",
      },
      hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        refundType: "Full Refund",
        returnPolicyCategory: "https://schema.org/FullRefund",
        returnPolicyCountry: "MX",
        returnPolicyEffectiveDate: "2023-07-13",
      },
    },
  };
  clearLogs();
  return (
    <React.Fragment key={medicine?.id || medicine?._id}>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <tr
        className="bg-white border-b overflow-auto hover:cursor-pointer"
        onClick={() => handleNavigate()}
      >
        <th scope="row" className="px-6 py-4">
          <img
            src={image}
            alt={name}
            style={{ width: "140px", height: "100px", objectFit: "contain" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = placeHolderImage;
            }}
          />
          {/* <img className="rounded-full w-96 h-96" src="/docs/images/examples/image-4@2x.jpg" alt="image description" /> */}
        </th>
        <th scope="row" className="px-6 py-4 font-medium ">
          {_convertToSentenceCase(name)}
        </th>
        <td className="px-6 py-4">
          {console.log("medicine",medicine)}
          {medicine?.previewUrl || pharmacyName === GLOBAL_SINGAPURE_NAME ? (
            <div>
              <img
                src={medicine?.previewUrl || globalSingapureLogo}
                alt={pharmacyName}
                style={{
                  width: "60px",
                  objectFit: "contain",
                }}
              />
            </div>
          ) : (
            _convertToSentenceCase(pharmacyName)
          )}
        </td>
        <td className="px-6 py-4">
          {isIngredinets ? (
            <div className="flex items-start gap-1">
              <p className="text-xss text-left pt-1 pb-0">
                {medicine?.ingredients?.length
                  ? _convertAndCheckArrayOfStringIntoSentaenceCase(
                      medicine?.ingredients
                    )?.join(", ")
                  : medicine?.ExtraInfo?.Ingredientes?.length
                  ? _convertAndCheckArrayOfStringIntoSentaenceCase(
                      medicine?.ExtraInfo?.Ingredientes
                    )?.join(", ")
                  : _convertAndCheckArrayOfStringIntoSentaenceCase(
                      medicine?.ExtraInfo?.Ingredients
                    )?.join(", ")}
              </p>
            </div>
          ) : null}
        </td>
        <td className="px-6 py-4">{deliveryTime}</td>
        <td className="px-6 py-4">{originCountry} </td>
        <td className="px-6 py-4">{brand || medicine?.laboratorio || ""} </td>
        {slug !== "ulsatech" && (
          <td className="px-6 py-4">
            {inventory?.Existencia
              ? inventory?.Existencia
              : inventory != 0
              ? inventory
              : "Sobre pedido" || "Sobre pedido"}{" "}
          </td>
        )}
        <td
          className="px-6 py-4"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {urlFda ? (
            <a
              style={{ color: "#1677ff" }}
              href={urlFda?.includes("http") ? urlFda : `https://${urlFda}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"FDA"}
            </a>
          ) : (
            ""
          )}
        </td>
        <td
          className="px-6 py-4"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {urlEma ? (
            <a
              style={{ color: "#1677ff" }}
              href={urlEma?.includes("http") ? urlEma : `https://${urlEma}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"EMA"}
            </a>
          ) : (
            ""
          )}{" "}
        </td>
        <td className="px-6 py-4">
          {price || inventory?.Existencia || inventory != 0 ? (
            <>
              <div className="h-10" />
              <div className="">
                <p className="text-center text-secondary font-[700] pb-1">
                  {price &&
                    `${
                      Currencies[selectedCurrency?.currency]?.sign
                    }${price}`}{" "}
                </p>
                <div className="text-center">
                  <button
                    className="bg-secondary text-white px-2 py-1 rounded-md text-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      onPressCartIcon(medicine);
                      addToCart(medicine);
                    }}
                  >
                    Comprar
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="py-2" />
              <div className="">
                <p className="text-center">
                  <button
                    className="bg-secondary text-white px-2 py-1 rounded-md text-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      onPressCartIcon(medicine);
                      addToCart(medicine);
                      // handleNavigateToWhatsapp()
                    }}
                  >
                    Cotizar
                  </button>
                </p>
              </div>
            </>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default SharedMedicineItemV1;
