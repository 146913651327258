import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <section>
      <div className="container flex justify-center items-center h-[50vh] px-6 py-6 mx-auto">
        <div>
          <h3 className="font-medium text-blue-500 text-xl">404</h3>
          <h1 className="mt-3 text-2xl font-semibold text-gray-800  md:text-3xl">
            Lo sentimos, la página no ha sido encontrada
          </h1>
          <p className="mt-4 text-gray-500 ">
            Te sugerimos regresar a nuestra página de inicio
          </p>
          <div className="flex items-center mt-6 gap-x-3">
            {/* <button className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto  hover:bg-gray-100 ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                <span>Go back</span>
              </button> */}
            <Link
              to={"/"}
              className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-secondary rounded-lg shrink-0 sm:w-auto "
            >
              Página de inicio
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Page404;
