import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getObjectId } from "../../utils";
import CartHelper from "../../helpers/CartHelper";

const initialState = {
  data: [],
};

const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const cartData = _.cloneDeep(state?.data || []);
      if (!cartData?.length) {
        cartData.push(action.payload.item);
      } else {
        const isAlreadyAddedProductIndex =
          CartHelper.getAlreadyProductAddedIndex(
            cartData,
            action.payload?.item
          );

        if (isAlreadyAddedProductIndex < 0) {
          cartData.push(action.payload.item);
        } else {
          cartData[isAlreadyAddedProductIndex] = action.payload.item;
        }
      }
      state.data = cartData;
    },
    updateCart: (state, action) => {
      state.data = action.payload?.item;
    },
    updateCartFromCartRelatedProducts: (state, action) => {
      if (action?.payload?.length) {
        state.data = action.payload;
      } else {
        state.data = [];
      }
    },
    removeFromCart: (state, action) => {
      if (state?.data?.length) {
        state.data = state?.data?.filter(
          (product) => getObjectId(product) !== action?.payload?.productId
        );
      }
      //   state.countryCode = action.payload.countryCode;
    },
    setInitialState: (state, action)=> action.payload,
    clearCart: () => initialState,
  },
});

export const {
  addToCart,
  removeFromCart,
  clearCart,
  updateCartFromCartRelatedProducts,
  updateCart,
  setInitialState,
} = cart.actions;

export default cart.reducer;
