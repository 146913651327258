import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  clearCart,
  removeFromCart,
  updateCart,
} from "../features/cart/cart";
import { useEffect, useRef, useState, useContext, useMemo } from "react";

import CartHelper from "../helpers/CartHelper";
import { request as getCartRelatedProducts } from "../features/cart/cartRelatedProducts";
import { getObjectId } from "../utils";
// import useGeoLocation from "./useGeoLocation";
import ShopsHelper from "../helpers/ShopsHelper";
import { LocationContext } from "../context/LocationProvider";

const useAddToCart = ({ isFetchRelatedProduct = false } = {}) => {
  const cart = useSelector((state) => state.cart);
  const cartRelatedProducts = useSelector((state) => state.cartRelatedProducts);
  const productModalRef = useRef(null);
  const [selectedProduct, setSelectedProduct] = useState();
  const cartProductsLength = useMemo(() => cart?.data?.length, [cart?.data]);
  // const location = useGeoLocation();
  const dispatch = useDispatch();
  const { selectedLocation } = useContext(LocationContext);

  const addProductToCart = (product) => {
    const cartData = [...cart?.data];
    const isAlreadyAddedProductIndex = CartHelper.getAlreadyProductAddedIndex(
      cart?.data,
      product
    );

    if (isAlreadyAddedProductIndex < 0) {
      cartData.push(product);
    } else {
      cartData[isAlreadyAddedProductIndex] = product;
    }
    dispatch(
      addToCart({
        item: product,
      })
    );
    productModalRef?.current?.close();
    fetchRelatedProducts(cartData);
  };

  const updateProductsToCart = (products) => {
    dispatch(
      updateCart({
        item: products,
      })
    );
  };

  const fetchRelatedProducts = (cartData) => {
    if (!cart?.data?.length) return;
    const payload = {
      medicineIds: cartData?.map((product) => ({
        id: getObjectId(product),
        quantity: product?.quantity,
      })),
    };
    if (selectedLocation?.latitude) {
      payload.location = { ...selectedLocation };
    }
    // if (location?.location?.latitude) {
    //   payload.location = { ...location.location };
    // }
    // const shopsWithLocation = ShopsHelper.getShopsWithLocation();
    // if (shopsWithLocation?.length) {
    //   payload.shopsWithLocation = shopsWithLocation;
    // }

    cart?.data?.length &&
      dispatch(
        getCartRelatedProducts({
          payload,
        })
      );
  };

  useEffect(() => {
    if (isFetchRelatedProduct) {
      fetchRelatedProducts(cart?.data);
    }
  }, [isFetchRelatedProduct]);

  const removeProductFromCart = (productId) => {
    dispatch(removeFromCart({ productId }));
    fetchRelatedProducts(cart?.data?.filter((med) => med?.id !== productId));
  };

  const onPressCartIcon = (product) => {
    const addedProduct = CartHelper.getAddedProduct(cart?.data, product);
    productModalRef?.current?.open(addedProduct?.quantity || 1);

    setSelectedProduct(product);
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };
  return {
    addProductToCart,
    onPressCartIcon,
    setSelectedProduct,
    removeProductFromCart,
    productModalRef,
    cart: cart?.data,
    selectedProduct,
    cartRelatedProducts,
    updateProductsToCart,
    handleClearCart,
  };
};

export default useAddToCart;
