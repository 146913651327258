import React from "react";

const EmailMasker = ({ email }) => {
  // Masking the first part of the email
  const maskedEmail = email.replace(
    /^(.)(.*)(.@.*)$/,
    function (_, firstChar, middlePart, lastPart) {
      const maskedMiddlePart = middlePart.replace(/./g, "*");
      return firstChar + maskedMiddlePart + lastPart;
    }
  );

  return <span>{maskedEmail}</span>;
};

export default EmailMasker;
