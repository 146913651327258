import React from "react";
// import { MdOutlinePrivacyTip } from "react-icons/md";
// import AccordianItem from "../Shared/AccordianItem/AccordianItem";

const ACCORDIAN_DATA = [
  {
    id: 10,
    title: "Definiciones",
    content: (
      <div className="px-4">
        Para los fines de esta Política de Privacidad:
        <ul className="list-disc">
          <li className="py-1">
            <b>Cuenta</b> significa una cuenta única creada para que tú accedas
            a nuestro Servicio o partes de nuestro Servicio.
          </li>
          <li className="py-1">
            <b>Aplicación</b> se refiere a VooyFarma, el programa de software
            proporcionado por la Empresa, accesible desde.
            <div>
              Play Store:{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.vooyfarma&pli=1"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 dark:text-blue-500 hover:underline"
              >
                https://play.google.com/store/apps/details?id=com.vooyfarma&pli=1
              </a>
            </div>
            <div>
              Apple Store:{" "}
              <a
                href="https://apps.apple.com/us/app/vooy-farma/id6446176829"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 dark:text-blue-500 hover:underline"
              >
                https://apps.apple.com/us/app/vooy-farma/id6446176829
              </a>
            </div>
            <div>
              Sitio Web:{" "}
              <a
                href="https://VooyFarma.com/"
                rel="noopener noreferrer"
                className="text-blue-600 dark:text-blue-500 hover:underline"
              >
                https://VooyFarma.com/
              </a>
            </div>
          </li>
          <li className="py-1">
            <b>Empresa</b> Empresa (referida como “VooyFarma“, “VOOY Ride the
            City”, ”la Empresa", "Nosotros", "Nuestro" o "Nuestra" en este
            Acuerdo) se refiere a VOOY PHARMA SA DE CV.
          </li>
          <li className="py-1">
            <b>Las Cookies</b> son pequeños archivos que son colocados en tu
            computadora, dispositivo móvil o cualquier otro dispositivo por un
            sitio web, que contienen los detalles de tu historial de navegación
            en ese sitio web, entre sus muchos usos.
          </li>
          <li className="py-1">
            <b>País</b> se refiere a: México.
          </li>
          <li className="py-1">
            <b>Dispositivo</b> significa cualquier dispositivo que pueda acceder
            al Servicio, como una computadora, un celular o una tableta digital.
          </li>
          <li className="py-1">
            <b>Datos Personales</b> son cualquier información que se refiere a
            una persona identificada o identificable.
          </li>
          <li className="py-1">
            <b>Servicio</b> se refiere a la Aplicación o al Sitio Web, o a
            ambos.
          </li>
          <li className="py-1">
            <b>Proveedor de Servicios</b> significa cualquier persona física o
            jurídica que procesa los datos en nombre de la Compañía. Se refiere
            a empresas o individuos externos contratados por la Compañía para
            facilitar el Servicio, para proporcionar el Servicio en nombre de la
            Compañía, para realizar servicios relacionados con el Servicio o
            para ayudar a la Compañía a analizar cómo se utiliza el Servicio.
          </li>
          <li className="py-1">
            <b>Datos de Uso</b> se refiere a los datos recopilados
            automáticamente, generados ya sea por el uso del Servicio o a partir
            de la infraestructura del Servicio misma (por ejemplo, la duración
            de una visita a una página).
          </li>
          <li className="py-1">
            <b>Sitio Web</b> se refiere a Vooyfarma, accesible desde{" "}
            <a
              href="https://vooyfarma.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-500 hover:underline"
            >
              https://vooyfarma.com/
            </a>
          </li>
          <li className="py-1">
            <b>Tú</b> significa la persona que accede o utiliza el Servicio, o
            la empresa u otra entidad legal en nombre de la cual dicha persona
            accede o utiliza el Servicio, según corresponda.
          </li>
        </ul>
        <p>Sobre los que recaban sus datos personales.</p>
      </div>
    ),
  },
  {
    id: 17,
    title: "Sobre los que recaban sus datos personales",
    content: (
      <div>
        <p>
          <b>VOOY PHARMA S.A. DE C.V.</b> (en lo sucesivo denominada{" "}
          <b> VOOYFARMA</b>) es una sociedad debidamente constituida conforme a
          las leyes de la República Mexicana con domicilio en el inmueble
          marcado con el número 1102 Avenida Benito Juárez, E1 O1 OF 13,
          Constitución Pabellón M, Centro Mty. Ote. Sur. Monterrey Nuevo León.
          C.P. 64000, toda vez que es una compañía intermediaria entre
          consumidor y establecimientos/farmacias que proporcionan el servicio
          de buscar y comparar precios de medicamentos ofrecidos por los
          establecimientos/farmacias al consumidor que brinda información sobre
          el procesamiento de datos personales para redirigir a sitios web o
          aplicaciones de terceros.
        </p>
        <p>
          VOOYFARMA reconoce la importancia que tiene el tratamiento legítimo,
          controlado e informado de los datos personales de sus clientes y
          usuarios de nuestro sitio web, y pone a sus disposición el presente
          Aviso de Privacidad, a fin de que conozca sus prácticas al obtener,
          usar, almacenar y, en general, tratar sus datos personales, de
          conformidad con la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares y demás normatividad aplicable (en
          adelante “la Ley” o la “LFPDPPP”).
        </p>
        <p>
          El Titular manifiesta que el presente Aviso le ha sido dado a conocer
          por el Responsable, haber leído, entendido y acordado los términos
          expuestos en este Aviso, por lo que otorga su consentimiento respecto
          del tratamiento de sus datos personales.
        </p>
        <p>
          Señalado lo anterior, el usuario en cualquier momento podrá revocar el
          consentimiento que previamente se otorgó para retención y
          procesamiento de los datos personales, por lo cual, una vez revocada
          dicha autorización, <b> VOOYFARMA </b> se abstendrá de procesar los
          datos personales del usuario en cuestión. La revocación del
          consentimiento no afecta la legalidad de ningún procesamiento que se
          haya realizado en función del consentimiento vigente hasta el momento
          de su revocación.
        </p>
        <p>
          La información personal proporcionada por el usuario será procesada
          con fines de mercadotecnia, sin embargo, el usuario en cualquier
          momento podrá oponerse al tratamiento de sus datos personales con la
          finalidad de que <b> VOOYFARMA </b> realice marketing directo.
        </p>
      </div>
    ),
  },
  {
    id: 1,
    title: "Información que recopilamos",
    content: (
      <div className="px-4">
        VooyFarma tratará los datos personales del Titular con la finalidad de
        dar cumplimiento a las obligaciones originadas y derivadas de cualquier
        relación jurídica y comercial que establezca con el Titular.
        <ol className="list-decimal">
          <li>
            Datos de usuario:
            <ul className="ml-5 list-disc">
              <li className="py-1">
                Datos personales recopilados para la creación de una cuenta de
                usuario, incluyendo de manera enunciativa mas no limitativa,
                nombre, número de teléfono, dirección de correo electrónico y
                contraseña del usuario.
              </li>
              <li className="py-1">
                Datos de contacto e información adicional que se pueda compartir
                con <b> VOOYFARMA </b>, tal como edad, genero, lugar de
                residencia, perfil de redes sociales.
              </li>
            </ul>
          </li>
          <li>
            Datos de uso:
            <ul className="ml-5 list-disc">
              <li className="py-1">
                Información sobre el uso de nuestros servicios, incluidos los
                detalles que el usuario ingresará al realizar una búsqueda
                incluyendo producto buscado, padecimiento, médico que prescribe,
                periodo de tratamiento, así como receta médica en caso de que el
                producto buscado lo requiera.
              </li>
              <li className="py-1">
                Interacción con terceros con fines de mercadotecnia, para
                ofertar los productos buscados.
              </li>
              <li className="py-1">
                Datos de ubicación, se podrá procesar la información de
                ubicación de acuerdo a la dirección IP del usuario. Asimismo,
                con consentimiento previo, se utilizará datos de GPS para
                ofrecer resultados de búsqueda personalizada en el dispositivo
                móvil.
              </li>
              <li className="py-1">
                Datos técnicos, para el correcto funcionamiento, se incluirá
                direcciones IP, cookies, datos de identificación, sistema
                operativo, y otros parámetros de configuración.
              </li>
            </ul>
          </li>
        </ol>
        <p>
          Cuando usted navega en nuestro sitio web se recaba cierta información
          no personal por medio de “cookies” y de los “registros del servidor” o
          “web beacons”. La obtención de esta información se da por el simple
          uso e interacción con nuestro sitio de Internet y la información que
          se recaba puede consistir en direcciones de Protocolo de Internet, su
          sistema operativo, tipo de navegador, información sobre su ubicación
          (proporcionada por dispositivos móviles) y la ruta que Usted sigue
          durante el tiempo que pasa en nuestro sitio.
        </p>
        <p>
          Estos datos se recaban únicamente con los siguientes propósitos:
          generar estadísticas relativas a las visitas a nuestro sitio web, al
          tiempo que pasa en el mismo, y los sitios de referencia que puedan
          haberle traído al nuestro.
        </p>
        <p>
          Es posible deshabilitar estas tecnologías de seguimiento en todo tipo
          de buscadores. Al deshabilitar o bloquear estos mecanismos se puede
          ocasionar que nuestro sitio no funcione correctamente. Para saber más
          sobre el tema, consulte{" "}
          <a
            href="http://www.allaboutcookies.org/es/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-500 hover:underline"
          >
            {" "}
            http://www.allaboutcookies.org/es/
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: "Uso de la información",
    content:
      "La información que recopilamos se utiliza para mejorar la plataforma y brindar un mejor servicio al usuario. También podemos utilizar la información para enviar correos electrónicos de marketing y publicidad. En ningún caso compartiremos información personal con terceros.",
  },
  {
    id: 3,
    title: "Seguridad de la información",
    content:
      "Vooyfarma toma muy en serio la seguridad de la información de sus usuarios y toma medidas para protegerla de accesos no autorizados, modificaciones, divulgaciones o destrucciones no autorizadas. Utilizamos tecnologías de seguridad para proteger la información y todos los datos se manejan de manera segura.",
  },
  {
    id: 4,
    title: "Política de Cookies",
    content:
      "Vooyfarma utiliza cookies para mejorar la experiencia del usuario y proporcionar contenido personalizado. Las cookies son pequeños archivos que se almacenan en el dispositivo del usuario y se utilizan para reconocer al usuario y recordar sus preferencias.",
  },
  {
    id: 5,
    title: "Derechos de privacidad",
    content: (
      <div>
        Los usuarios tienen el derecho de acceder, modificar y eliminar su
        información personal en cualquier momento. También pueden darse de baja
        de la lista de correo electrónico en cualquier momento usando estos
        medios
        <div>
          Correo electrónico{" "}
          <a
            href="mailto:contacto@vooyfarma.com"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-500 hover:underline"
          >
            contacto@vooyfarma.com
          </a>
        </div>
        <div>
          Pagina de contacto:{" "}
          <a
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-500 hover:underline"
            href="https://vooyfarma.com/contacto"
          >
            https://vooyfarma.com/contacto
          </a>
        </div>
        <div>O desde la app Ajustes {"->"} Eliminar mi cuenta</div>
      </div>
    ),
  },
  {
    id: 6,
    title: "Cambios en la política de privacidad",
    content:
      "Vooyfarma se reserva el derecho de cambiar esta política de privacidad en cualquier momento sin previo aviso. Si utilizas la plataforma después de que se hayan realizado los cambios, se considerará que aceptas la nueva política de privacidad.",
  },
  {
    id: 7,
    title: "Leyes y regulaciones",
    content: (
      <>
        Vooyfarma cumple con todas las leyes y regulaciones aplicables a la
        privacidad de datos y la protección de la propiedad intelectual.
        <br /> Si tienes alguna pregunta o comentario sobre esta política de
        privacidad, por favor contáctanos a través de nuestro chatbot en línea o
        correo electrónico.
        <div>
          <a
            href="mailto:contacto@vooyfarma.com"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-500 hover:underline"
          >
            contacto@vooyfarma.com
          </a>
        </div>
        <div>
          <a
            href="https://vooyfarma.com/contacto"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-500 hover:underline"
          >
            https://vooyfarma.com/contacto
          </a>
        </div>
      </>
    ),
  },
  {
    id: 11,
    title: "Aviso de Privacidad Vooyfarma",
    content: (
      <div>
        {/* <p className="pt-0">Fecha de última modificación:</p> */}
        <p>
          <b>VOOY PHARMA S.A. DE C.V.</b> (en lo sucesivo denominada{" "}
          <b> VOOYFARMA </b>) es una sociedad debidamente constituida conforme a
          las leyes de la República Mexicana con domicilio en el inmueble
          marcado con el número 1102 Avenida Benito Juárez, E1 O1 OF 13,
          Constitución Pabellón M, Centro Mty. Ote. Sur. Monterrey Nuevo León.
          C.P. 64000, toda vez que es una compañía intermediaria entre
          consumidor y establecimientos/farmacias que proporcionan el servicio
          de buscar y comparar precios de medicamentos ofrecidos por los
          establecimientos/farmacias al consumidor que brinda información sobre
          el procesamiento de datos personales para redirigir a sitios web o
          aplicaciones de terceros.
        </p>
        <p>
          De acuerdo al Reglamento General de Protección de Datos, los usuarios
          de <b> VOOYFARMA </b>, tienen el derecho a la protección de sus datos
          personales, derecho a la portabilidad de dichos datos; así como el
          derecho a la rectificación, a la limitación del tratamiento de sus
          datos personales y derecho a la supresión de su información personal.
        </p>
        <p>
          Señalado lo anterior, el usuario en cualquier momento podrá revocar el
          consentimiento que previamente se otorgó para retención y
          procesamiento de los datos personales, por lo cual, una vez revocada
          dicha autorización, <b> VOOYFARMA </b> se abstendrá de procesar los
          datos personales del usuario en cuestión. La revocación del
          consentimiento no afecta la legalidad de ningún procesamiento que se
          haya realizado en función del consentimiento vigente hasta el momento
          de su revocación.
        </p>
        <p>
          {" "}
          La información personal proporcionada por el usuario será procesada
          con fines de mercadotecnia, sin embargo, el usuario en cualquier
          momento podrá oponerse al tratamiento de sus datos personales con la
          finalidad de que <b> VOOYFARMA </b> realice marketing directo.
        </p>
      </div>
    ),
  },
  {
    id: 12,
    title: "Datos que se Recopilarán al Utilizar Vooyfarma",
    content: (
      <div className="px-4">
        <ul className="list-decimal">
          <li className="py-1">
            <p className="pt-0">Datos de usuario:</p>
            <ul className="ml-4 list-disc">
              <li className="py-1">
                Datos personales recopilados para la creación de una cuenta de
                usuario, incluyendo de manera enunciativa mas no limitativa,
                nombre, dirección de correo electrónico y contraseña del
                usuario.
              </li>
              <li className="py-1">
                Datos de contacto e información adicional que se pueda compartir
                con <b> VOOYFARMA </b>, tal como edad, genero, lugar de
                residencia, perfil de redes sociales.
              </li>
            </ul>
          </li>
          <li className="py-1">
            <p>Datos de uso:</p>
            <ul className="ml-4 list-disc">
              <li className="py-1">
                Información sobre el uso de nuestros servicios, incluidos los
                detalles que el usuario ingresará al realizar una búsqueda
                incluyendo producto buscado, padecimiento, médico que prescribe,
                periodo de tratamiento, así como receta médica en caso de que el
                producto buscado lo requiera.
              </li>
              <li className="py-1">
                Interacción con terceros con fines de mercadotecnia, para
                ofertar los productos buscados.
              </li>
              <li className="py-1">
                Datos de ubicación, se podrá procesar la información de
                ubicación de acuerdo a la dirección IP del usuario. Asimismo,
                con consentimiento previo, se utilizará datos de GPS para
                ofrecer resultados de búsqueda personalizada en el dispositivo
                móvil.
              </li>
              <li className="py-1">
                Datos bancarios, aún y cuando <b> VOOYFARMA </b> solo publicita
                costos de los productos buscados por el usuario, podrá pedir al
                usuario que comparta datos bancarios para realizar la compra de
                los productos seleccionados por el propio usuario.
              </li>
              <li className="py-1">
                Datos técnicos, para el correcto funcionamiento, se incluirá
                direcciones IP, cookies, datos de identificación, sistema
                operativo, y otros parámetros de configuración.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 13,
    title: "Justificación de la recopilación de datos personales",
    content: (
      <div>
        <p className="pt-0">
          En seguida se describirán los motivos por los cuales se recaba
          información y finalidad de la misma, así como el fundamento jurídico.
        </p>
        <ul className="list-[square] ml-4">
          <li>
            Brindar los servicios que requiera el usuario, al proporcionarle la
            información de los productos médicos buscados, incluyendo comparador
            de precios y ofertas con el fin de que el usuario obtenga el mayor
            beneficio económico.
          </li>
          <li>
            Se realizará un acto jurídico de compraventa, en caso de que el
            usuario desee adquirir un producto, en el cual el usuario
            manifestará su consentimiento expreso al realizar dicha compra.
          </li>
          <li>
            Para lo anterior se utilizarán datos personales como datos de
            usuario, datos de uso, datos de paciente, datos de padecimiento,
            datos de ubicación, datos de médico, así como datos técnicos.
          </li>
          <li>
            Enviar comunicaciones de mercadotecnia, sobre los productos buscados
            y algunos similares, mostrar anuncios publicitarios sobre servicios
            propios y de terceros.
          </li>
          <li>
            Tener una comunicación directa con el usuario en caso de que surjan
            dudas
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 14,
    title: "Transmisión de datos",
    content: (
      <div>
        <p className="pt-0">
          <b> VOOYFARMA </b> compartirá los datos personales de los usuarios con
          los siguientes destinatarios:
        </p>
        <ul className="list-[square] ml-4">
          <li>Empresas Subsidiarias, filiales y/o socios comerciales.</li>
          <li>Socios de autenticación</li>
          <li>
            Proveedores de servicios externos, correspondientes a quienes
            oferten los productos buscados por el usuario, tales como farmacias,
            distribuidoras, entre otras.
          </li>
          <li>
            Socios comerciales a través de los cuales se ofertarán los productos
            buscados.
          </li>
        </ul>
        <p>
          La transferencia de datos a proveedores de servicios, es única y
          exclusivamente con la finalidad de ofrecer los productos buscados, así
          como brindar más opciones de los mismos o productos adicionales
          relacionados con la búsqueda principal, por lo cual <b> VOOYFARMA </b>
          selecciona y designa cuidadosamente a dichos proveedores de servicios,
          a fin de salvaguardar dicha información compartida.
        </p>
      </div>
    ),
  },
  {
    id: 15,
    title: "Cuenta de Usuario Vooyfarma",
    content: (
      <div>
        <p className="pt-0">
          Es totalmente opcional para el usuario la creación de una cuenta, sin
          embargo, es necesario para hacer uso y disfrutar de los beneficios que
          <b> VOOYFARMA </b> ofrece.
        </p>
        <p>
          El usuario en todo momento podrá administrar, modificar y eliminar
          toda la información de su cuenta de usuario.
        </p>
      </div>
    ),
  },
  {
    id: 16,
    title: "Protección de Datos",
    content: (
      <div>
        <b> VOOYFARMA </b> se compromete a salvaguardar y hacer buen uso de los
        datos personales proporcionados por el usuario, por lo cual existen
        medidas técnicas y adecuadas para la protección de los mismos, a fin de
        que terceros no autorizados no puedan tener acceso, ni manipular dicha
        información, de manera accidental o intencional, se cuentan con
        firewalls y controles de acceso para dicha protección, medidas que están
        en constante revisión y mejoras.
      </div>
    ),
  },
];

const PrivacyPolicySection = () => {
  return (
    <div className="py-3 flex flex-col gap-3">
      {/* <div className="bg-white flex items-center gap-3 py-4 px-4 rounded-lg"> */}
      <div className="pt-4 px-4 rounded-lg">
        <div className="flex items-center justify-between cursor-pointer">
          <h2 className="text-xl font-[600]">
            Última actualización: 29 de diciembre de 2023
          </h2>
        </div>
        <div className="mt-2 border-t border-gray-200 pt-4">
          <p className="">
            Esta Política de Privacidad describe cómo VooyFarma trata datos
            personales en relación con el uso que usted hace de la Plataforma
            VooyFarma. Por favor, siga los enlaces y revise la información
            complementaria que describe cómo procesamos la información personal
            para esas regiones y servicios.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {ACCORDIAN_DATA.map((item) => (
          // <div className="bg-white py-4 px-4 rounded-lg" key={item?.id}>
          <div className="pt-4 px-4 rounded-lg" key={item?.id}>
            <div className="flex items-center justify-between cursor-pointer">
              <h2 className="text-xl font-[600]">{item?.title}</h2>
            </div>
            <div className="mt-2 border-t border-gray-200 pt-4">
              <p>{item?.content}</p>
            </div>
          </div>
        ))}
        {/* {ACCORDIAN_DATA.map((item) => (
          <AccordianItem
            key={item.id}
            title={item.title}
            content={item.content}
          />
        ))} */}
      </div>
    </div>
  );
};

export default PrivacyPolicySection;
