import { useEffect, useRef } from "react";
import "./GoogleTranslate.css";

const GoogleTranslate = () => {
  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    // debugger
    window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                // pageLanguage: 'es',
                includedLanguages: "en,es,",
                autoDisplay: true,
            },
            'google_translate_element'
        );
    };
}, []);

  
  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
