import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { updateMetaTags } from "../features/meta/metaSlice";

import HeroSection from "../components/HeroSection/HeroSection";
import InfoSection from "../components/InfoSection/InfoSection";
import ListedPharmacies from "../components/ListedPharmacies/ListedPharmacies";
import MedicineSection from "../components/MedicineSection/MedicineSection";
import StepsSection from "../components/StepsSection/StepsSection";
import IGFeedSection from "../components/IGFeedSection/IGFeedSection";
import FeaturedBlogsSection from "../components/FeaturedBlogsSection/FeaturedBlogsSection";
import BannerSection from "../components/BannerSection/BannerSection";
import { clearLogs } from "../utils";

const HomePage = () => {
  const metaTagsData = useSelector((state) => state.metaTags.metaTags);
  const dispatch = useDispatch();

  useEffect(() => {
    let payload = {
      title: "Vooy Farma | Buscador de medicamentos en México",
      description:
        "En Vooy Farma hacemos la búsqueda de medicamentos en varios sitios de farmacias de México por ti",
    };
    dispatch(updateMetaTags(payload));
  }, [dispatch]);
  clearLogs();

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://vooyfarma.com/" />
        {/* <title>Vooy Farma | Buscador de medicamentos en México</title> */}
        <title>{metaTagsData.title}</title>
        <meta
          name="description"
          // content="En Vooy Farma hacemos la búsqueda de medicamentos en varios sitios de farmacias de México por ti"
          content={metaTagsData.description}
        />
      </Helmet>
      <HeroSection />
      <ListedPharmacies />
      <MedicineSection />
      <InfoSection />
      <StepsSection />
      <BannerSection />
      <FeaturedBlogsSection />
      <IGFeedSection />
    </div>
  );
};

export default HomePage;
