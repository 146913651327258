import React, { useState } from "react";
import { BsList, BsPerson } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import LogoImage from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { ReactComponent as Archive } from "../../assets/svgs/archive-gray.svg";
import CurrencyDropDown from "../CurruncyDropDown/CurruncyDropDown";
import GoogleTranslate from "../GoogleTranslate/GoogleTranslate";
import { MdLanguage } from "react-icons/md";
import { IoCartOutline } from "react-icons/io5";

const Navbar = ({ onLoginModal, onSignupModal }) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  // cart count
  const cart = useSelector((state) => state.cart);

  const navigate = useNavigate();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const handleMobileNav = () => {
    setIsMobileNavOpen((prevState) => !prevState);
  };
  const handleMobileNavPopupClose = (e, route) => {
    if (route === "cart" && !isLoggedIn) {
      e?.preventDefault();
      onLoginModal();
    }
    setIsMobileNavOpen(false);
  };

  const loginHandler = () => {
    setIsMobileNavOpen(false);
    onLoginModal();
  };

  // const signupHandler = () => {
  //   setIsMobileNavOpen(false);
  //   onSignupModal();
  // };

  return (
    <div className="flex justify-between items-center w-full">
      <div
        className="w-24 cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={LogoImage} alt="Vooy Farma Logo" loading="lazy" />
      </div>
      <nav
        className={`flex justify-center items-center gap-6 flex-col sm:flex-row fixed top-0 left-0 w-full h-screen bg-tertiary text-light text-center sm:text-left sm:bg-transparent z-[1000] sm:h-auto sm:w-auto sm:relative sm:gap-6 sm:justify-between sm:items-center ${
          isMobileNavOpen ? "block sm:flex" : "hidden sm:flex"
        }`}
        // style={{alignItems: "baseline",}}
      >
        <button className="sm:hidden">
          <AiOutlineClose
            className="absolute top-10 right-10 text-2xl text-light transition-all duration-150 ease-in hover:text-gray-500"
            onClick={handleMobileNav}
          />
        </button>
        <ul className="flex items-center gap-6 flex-col sm:flex-row">
          <li className="cursor-pointer transition-all duration-150 ease-in hover:text-gray-500">
            <Link onClick={handleMobileNavPopupClose} to={"/"}>
              Inicio
            </Link>
          </li>
          <li className="cursor-pointer transition-all duration-150 ease-in hover:text-gray-500">
            <Link onClick={handleMobileNavPopupClose} to={"/buscar"}>
              Búsqueda avanzada
            </Link>
          </li>
          {/* <li className="cursor-pointer transition-all duration-150 ease-in hover:text-gray-500">
            <Link onClick={handleMobileNavPopupClose} to={"/doctores"}>
              Doctores
            </Link>
          </li> */}
          <li className="cursor-pointer transition-all duration-150 ease-in hover:text-gray-500">
            <Link onClick={handleMobileNavPopupClose} to={"/blog"}>
              {" "}
              Blog{" "}
            </Link>
          </li>
          <li className="cursor-pointer transition-all duration-150 ease-in hover:text-gray-500">
            <Link onClick={handleMobileNavPopupClose} to={"/sobre-vooy-farma"}>
              {" "}
              Sobre Vooyfarma{" "}
            </Link>
          </li>
          <li className="cursor-pointer transition-all duration-150 ease-in hover:text-gray-500">
            <Link onClick={handleMobileNavPopupClose} to={"/contacto"}>
              Contacto
            </Link>
          </li>
          {/* <li className="cursor-pointer transition-all duration-150 ease-in hover:text-gray-500">
            <div className="flex justify-center items-center">
              <MdLanguage />
              <div className="google-translate">
                <GoogleTranslate />
              </div>
            </div>
          </li> */}
          <li className="cursor-pointer transition-all duration-150 ease-in hover:text-gray-500">
            <CurrencyDropDown origin={false} />
          </li>
          {!isLoggedIn && (
            <li
              className="cursor-pointer transition-all duration-150 ease-in hover:text-gray-500"
              onClick={loginHandler}
            >
              Ingresar
            </li>
          )}
        </ul>
        {/* <CurruncyDropDown /> */}
        {isLoggedIn && (
          <Link
            onClick={handleMobileNavPopupClose}
            to={"/mis-favoritos"}
            className="mx-1 text-xl transition-all duration-150 ease-in hover:text-gray-500"
          >
            <Archive color={"#8E939E"} width={20} height={20} />
          </Link>
        )}
        {/* {user?.role === "admin" || user?.role === "sadmin" ? ( */}
        <Link
          onClick={(e) => handleMobileNavPopupClose(e, "cart")}
          to={"/cart"}
          className="mx-1 text-xl transition-all duration-150 ease-in hover:text-gray-500 flex justify-center items-center"
        >
          <div class="relative">
            <div class="t-0 absolute left-3">
              <p class="flex h-2 w-2 items-center justify-center rounded-full bg-secondary p-3 text-xs text-white">
                {cart?.data?.length || 0}
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="file: mt-4 h-6 w-6 mb-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </div>
        </Link>
        {/* ) : null} */}
        {isLoggedIn && (
          <button
            onClick={() => {
              navigate("/perfil");
              setIsMobileNavOpen(false);
            }}
          >
            <BsPerson className="text-xl transition-all duration-150 ease-in hover:text-gray-500" />
          </button>
        )}
      </nav>
      <button
        className="sm:hidden"
        onClick={handleMobileNav}
        id="al"
        aria-label="Mobile Menu"
      >
        <BsList className="text-2xl text-primary transition-all duration-150 ease-in hover:text-gray-500" />
      </button>
    </div>
  );
};

export default Navbar;
