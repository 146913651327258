import React from "react";

function ConditionalLinkOrDiv({ url, children }) {
  return (
    <>
      {url ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default ConditionalLinkOrDiv;
