import {
  COL_FLAG,
  CRC_FLAG,
  EUR_FLAG,
  GTQ_FLAG,
  INR_FLAG,
  MXN_FLAG,
  NETH_FLAG,
  PANAMA_FLAG,
  USD_FLAG,
  VEN_FLAG,
} from "../utils/countriesSvg";

export const PAGING_SIZE = 20;

export const KM_RANGE = {
  min: 0,
  max: 30,
};
export const PRICE_RANGE = {
  min: 1,
  max: 1000,
};

export const Currencies = Object.freeze({
  USD: {
    sign: "$",
    value: "USD",
    flag: USD_FLAG,
  },
  MXN: {
    sign: "$",
    value: "MXN",
    flag: MXN_FLAG,

  },
  CRC: {
    sign: "₡",
    value: "CRC",
    flag: CRC_FLAG,

  },
  GTQ: {
    sign: "GTQ",
    value: "GTQ",
    flag: GTQ_FLAG,

  },
  INR: {
    sign: "₹",
    value: "INR",
    flag: INR_FLAG,

  },
  EUR: {
    sign: "€",
    value: "EUR",
    flag: EUR_FLAG,
  },
  COL: {
    sign: "$",
    value: "COL",
    flag: COL_FLAG,
  },
});


export const CountryWithFlag = Object.freeze({
  "USA": {
    sign: "$",
    value: "USD",
    flag: USD_FLAG,
  },
  "Mexico": {
    sign: "$",
    value: "MXN",
    flag: MXN_FLAG,

  },
  "Costa Rica": {
    sign: "₡",
    value: "CRC",
    flag: CRC_FLAG,

  },
  "Guatemala": {
    sign: "GTQ",
    value: "GTQ",
    flag: GTQ_FLAG,

  },
  "India": {
    sign: "₹",
    value: "INR",
    flag: INR_FLAG,

  },
  "Netherlands": {
    sign: "€",
    value: "EUR",
    flag: NETH_FLAG,
  },
  "Panamá": {
    sign: "$",
    value: "USD",
    flag: PANAMA_FLAG,
  },
  "Colombia": {
    sign: "$",
    value: "USD",
    flag: COL_FLAG,
  },
  "Venezuela": {
    sign: "$",
    value: "USD",
    flag: VEN_FLAG,
  },
});

export const currenciesWithFlags = [
  {
    id: 1,
    currency: "MXN",
    flag: MXN_FLAG,
    sign: "$",
  },
  {
    id: 5,
    currency: "USD",
    flag: USD_FLAG,
    sign: "$",
  },
  {
    id: 6,
    currency: "CRC",
    flag: CRC_FLAG,
    sign: "₡",
  },
  {
    id: 7,
    currency: "GTQ",
    flag: GTQ_FLAG,
    sign: "GTQ",
  },
  {
    id: 8,
    currency: "INR",
    flag: INR_FLAG,
    sign: "₹",
  },
  {
    id: 9,
    currency: "EUR",
    flag: EUR_FLAG,
    sign: "€",
  },
  {
    id: 10,
    currency: "COL",
    flag: COL_FLAG,
    sign: "$",
  },
  //   {
  //     id: 2,
  //     currency: "EUR",
  //     flag: EUR_FLAG,
  //   },
  //   {
  //     id: 3,
  //     currency: "CAD",
  //     flag: CAD_FLAG,
  //   },
  //   {
  //     id: 4,
  //     currency: "GBP",
  //     flag: GBP_FLAG,
  //   },
];

export const whatsAppNum = "37281119358";

export const orderStatusObj = {
  pending: "Pendiente",
  inProgress: "En Proceso",
  completed: "Terminado",
  canceled: "Cancelado",
};
