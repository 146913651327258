import React from "react";

const IndivStep = ({ icon, title, description, id, duration }) => {
  return (
    <div
      className="flex-1 relative bg-white p-8 rounded-lg cursor-pointer transition-all ease-in duration-300 hover:shadow-md"
      data-aos="fade-up"
      data-aos-delay={duration}
    >
      <div className="flex justify-center items-center">
        <span className="bg-secondary p-3 rounded-full">{icon}</span>
      </div>
      <div className="mt-4 text-center">
        <div className="font-[600] text-xl">{title}</div>
        <p className="my-2 text-light ">{description}</p>
      </div>
      <div className="absolute top-[-14px] left-4 text-3xl font-[700] text-primary">
        {id}
      </div>
    </div>
  );
};

export default IndivStep;
