import React from "react";

const Button = ({
  children,
  type = "button",
  onClick,
  className,
  disabled = false,
}) => {
  return (
    <button
      className={`py-3 px-4 rounded-lg bg-secondary text-white transition-all duration-300 ease-in hover:opacity-90 disabled:opacity-25 disabled:cursor-not-allowed  ${className}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
