import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineLock } from "react-icons/ai";
import TextField from "../components/Shared/TextField/TextField";
import Button from "../components/Shared/Button/Button";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

import { baseURL } from "../utils/baseURL";
import { Oval } from "react-loader-spinner";

const ResetPasswordPage = ({ setIsResetPass }) => {
  const navigate = useNavigate();

  const [resetPasswordUser, setResetPasswordUser] = useState({});
  const [isUserAuthentic, setIsUserAuthentic] = useState(false);
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const newPassword = e.target.newPassword.value;
      const confirmPassword = e.target.confirmPassword.value;
      if (!newPassword && !confirmPassword) {
        setIsLoading(false);
        return toast.warn("Por favor complete ambos campos");
      }
      if (newPassword !== confirmPassword) {
        setIsLoading(false);
        return toast.warn("Las contraseñas no coinciden");
      }
      const body = {
        token: resetPasswordUser?.token,
        userId: resetPasswordUser?.userId,
        password: confirmPassword,
      };
      const response = await axios.post(`${baseURL}/user/reset-password`, body);

      if (response) {
        setIsLoading(false);
        toast.success("Restablecimiento de contraseña exitoso");
        setTimeout(() => {
          handleBackToHome();
        }, 2000);
      }
    } catch (error) {
      console.log("error >>", error);
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const isAuthenticUser = async ({ body }) => {
    try {
      const response = await axios.get(
        `${baseURL}/user/reset-password/authenticate`,
        {
          params: body,
        }
      );
      if (response) {
        setIsUserAuthentic(true);
      }
    } catch (error) {
      handleBackToHome();
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const token = searchParams?.get("token");
    const userId = searchParams?.get("userId");
    if (token && userId) {
      const body = {
        token: token,
        userId: userId,
      };
      isAuthenticUser({ body });
      setResetPasswordUser(body);
    } else {
      handleBackToHome();
    }
  }, []);

  const handleBackToHome = () => {
    navigate("/");
    // setIsResetPass(false);
    // window.location.reload();
  };

  return (
    <>
      {isUserAuthentic ? (
        <div className="my-6 px-10 py-12 rounded-2xl relative lg:w-6/12 mx-auto">
          <div className="py-3">
            <h1 className="text-2xl font-[600] text-primary text-center">
              Restablecer la contraseña
            </h1>
          </div>
          <div className="mx-6 mt-3 pt-2 border-t-2 border-t-gray-300">
            <form className="w-full" onSubmit={handleSubmit}>
              <TextField
                label="Nueva contraseña"
                name="newPassword"
                icon={<AiOutlineLock className="text-xl" />}
                placeholder="Ingresa tu nueva contraseña"
                type="password"
              />
              <TextField
                label="Confirmar Contraseña"
                name="confirmPassword"
                icon={<AiOutlineLock className="text-xl" />}
                placeholder="Vuelve a escribir la contraseña"
                type="password"
              />
              <div className="flex items-center justify-center my-6">
                <Button disabled={isLoading} type="submit" className="w-full">
                  Enviar
                </Button>
              </div>
            </form>
            <div className="my-1 text-center">
              <p
                className="text-secondary font-semibold cursor-pointer hover:underline w-fit mx-auto"
                onClick={() => handleBackToHome()}
              >
                Volver al inicio
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex justify-center items-center h-72 w-full"
          data-aos="fade-up"
        >
          <Oval
            height={50}
            width={50}
            color="rgba(51, 124, 171, 1)"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="rgba(51, 124, 171, 1)"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </>
  );
};

export default ResetPasswordPage;
