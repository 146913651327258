import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shopLocation: {},
  shopLocationForMedicineDetail: {},
  shopLocationWithShopNames: {},
};

const shopLocationSlice = createSlice({
  name: "shopLocation",
  initialState,
  reducers: {
    updateShopLocation: (state, action) => {
      state.shopLocation = action.payload;
    },
    updateShopLocationForMedicineDetail: (state, action) => {
      state.shopLocationForMedicineDetail = action.payload;
    },
    updateShopLocationWithShopNames: (state, action) => {
      state.shopLocationWithShopNames = action.payload;
    },

  },
});

export const { updateShopLocation,updateShopLocationForMedicineDetail,updateShopLocationWithShopNames } = shopLocationSlice.actions;

export default shopLocationSlice.reducer;
