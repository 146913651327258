import Select from "react-select";
import { convertToSentenceCase } from "../../../utils";

const SelectDropDownWithouLabel = ({
  options,
  value,
  onChange,
  style = { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
}) => {
  options = options?.map((item) => ({
    value: item?.value,
    label: convertToSentenceCase(item?.label),
    ...item,
  }));
  return (
    <Select
      menuPortalTarget={document.body}
      styles={style}
      options={options}
      placeholder="Seleccionar"
      value={
        value
          ? {
              value: value,
              label: value,
            }
          : null
      }
      // onChange={(e) => onChange(e.value)}
      onChange={(e) => onChange(e)}
      // className="p-0"
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );
};

export default SelectDropDownWithouLabel;
