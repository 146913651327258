import axios from "axios";
import { baseURL } from "./baseURL";
import {
  getUserFromLocalStorage,
  removeUserAndTokenFromLocalStorage,
} from "./localStorage";

const userRequest = axios.create({
  baseURL: baseURL,
});

userRequest.interceptors.request.use((config) => {
  const user = getUserFromLocalStorage();
  if (user.token) {
    const token = user.token;
    // config.headers["Authorization"] = `Bearer ${user.token}`;
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

// Add a response interceptor
userRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      (error.response.data.errorCode === "USER_NOT_FOUND" ||
        error.response.data.errorCode === "UNAUTHORIZED_REQUEST")
    ) {
      removeUserAndTokenFromLocalStorage();
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default userRequest;
