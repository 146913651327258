import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../../utils/baseURL";
import TextField from "../Shared/TextField/TextField";
import Button from "../Shared/Button/Button";
import { useSelector } from "react-redux";

const Contact = ({ isSearchpage = false, closeContactForm }) => {
  const { user, token } = useSelector((state) => state.auth);

  const [name, setName] = useState(user?.name || "");
  const [phoneNumber, setphoneNumber] = useState(user?.phoneNumber || "");
  const [email, setEmail] = useState(user?.email || "");
  const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleContactForm = async () => {
    setIsLoading(true);
    try {
      const payload = {
        name,
        email,
        phoneNumber,
        message,
      };
      // console.log("payload >>>", payload);
      if (!name || !email || !phoneNumber || !message) {
        setIsLoading(false);
        return toast.warn("Please fill all fields!");
      }
      const response = await axios
        .post(`${baseURL}/contact-us`, payload)
        .catch((err) => {
          toast.error(err.response.data.error);
          setIsLoading(false);
        });
      // console.log("response: ", response);
      if (response) {
        toast.success("éxito");
        setIsLoading(false);
        if (isSearchpage) {
          closeContactForm();
        }
      }
    } catch (error) {
      toast.error(error.response.data.error);
      setIsLoading(false);
    }
  };

  return (
    <form className="py-3 flex flex-col gap-2">
      <TextField
        label="Nombre"
        name="name"
        placeholder="Ingresa tu nombre"
        defaultValue={name}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="my-2">
        <label htmlFor="phoneNumber" className="font-[600]">
          Teléfono
        </label>
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          defaultCountry="MX"
          placeholder="Ingresa tu número de teléfono"
          className="bg-lightSecondary py-3 px-3 rounded-md mt-2 w-full"
          value={phoneNumber}
          onChange={setphoneNumber}
        />
      </div>
      <TextField
        label="Correo electrónico"
        name="email"
        type="email"
        placeholder="Ingresa tu Correo electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div className="my-2">
        <label htmlFor="message" className="font-[600]">
          Mensaje
        </label>
        <div className=" gap-2 bg-lightSecondary py-3 px-3 rounded-md mt-2">
          <textarea
            className="bg-transparent w-full focus:outline-none custom-text-field"
            placeholder="Ingresa tu Mensaje"
            rows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div>
      <div className="flex justify-end py-2">
        <Button
          disabled={isLoading}
          className="px-14"
          onClick={handleContactForm}
        >
          Enviar
        </Button>
      </div>
    </form>
  );
};

export default Contact;
