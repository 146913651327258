import React from 'react';

const PhoneNumberMasker = ({ phoneNumber }) => {
  // Masking the first three digits and the last three digits of the phone number
  const maskedPhoneNumber = phoneNumber.replace(/^(.{3})(.*)(.{3})$/, function(_, firstThreeDigits, middleDigits, lastThreeDigits) {
    const maskedMiddleDigits = middleDigits.replace(/./g, '*');
    return firstThreeDigits + maskedMiddleDigits + lastThreeDigits;
  });

  return <span>{maskedPhoneNumber}</span>;
};

export default PhoneNumberMasker;