import React, { useEffect } from "react";
import CookiePolicySection from "../components/CookiePolicySection/CookiePolicySection";
import { Helmet } from "react-helmet";
import { scrollToTop } from "../utils/scrollToTop";

const CookiePolicyPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="my-6 px-10 py-12 rounded-2xl relative lg:w-10/12 mx-auto">
      <Helmet>
        <link
          rel="canonical"
          href="https://vooyfarma.com/politica-de-cookies"
        />
      </Helmet>
      <div className="pb-4">
        <h1 className="text-start text-2xl sm:text-4xl font-[600] text-primary">
          Política de Cookies
        </h1>
      </div>
      <CookiePolicySection />
    </div>
  );
};

export default CookiePolicyPage;
