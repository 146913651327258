import React from "react";

import InfoImg from "../../assets/images/info-img.webp";

const InfoSection = () => {
  return (
    <div
      className="my-6 px-4 py-10 lg:px-20 lg:py-12 rounded-2xl"
      id="info-section"
    >
      <div className="flex items-center px-4 lg:px-10 flex-col gap-6 sm:gap-0 sm:flex-row">
        <div className="flex-1">
          <img src={InfoImg} width="100%" alt="Information" className="lg:w-4/5" />
        </div>
        <div className="flex-1 lg:flex-[2]">
          <h2 className="text-2xl md:text-4xl font-[600] text-white">
            ¡Encuentra tu medicamento ideal al mejor precio!
          </h2>
          <p className="mt-6 md:text-xl text-white">
            Busca compara y encuentra tu medicamento ideal al mejor precio, más cerca de ti.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
