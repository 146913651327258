import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import SharedMedicineItem from "../components/SharedMedicineItem/SharedMedicineItem";
import { Oval } from "react-loader-spinner";
import { Helmet } from "react-helmet";

import Button from "../components/Shared/Button/Button";
import userRequest from "../utils/requestMethods";
import { useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../utils/scrollToTop";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { updateMetaTags } from "../features/meta/metaSlice";
import { CurrencyContext } from "../context/CurrencyProvider";
import placeHolderImage from "../assets/images/pills.placeholder.webp";
import DoctorSearch from "../components/Doctor/DoctorSearch";
import SelectDropDown from "../components/Shared/SelectDropDown/SelectDropDown";
import ReactSelectComp from "../components/Shared/ReactSelectComp/ReactSelectComp";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  _convertAndCheckArrayOfStringIntoSentaenceCase,
  _convertToSentenceCase,
  clearLogs,
  formatDataForDropDownFilter,
  formatDataWithoutCountForDropDownFilter,
} from "../utils";
import ContactForm from "../components/ContactForm";
import CurrencyDropDown from "../components/CurruncyDropDown/CurruncyDropDown";
import TextField from "../components/Shared/TextField/TextField";
import { AiOutlineLock } from "react-icons/ai";
import MedicineService from "../services/medicine.service";
import ReactSelectPaginate from "../components/Shared/ReactSelectPaginate/ReactSelectPaginate";
import { PAGING_SIZE } from "../constants";
import { S3_BASE_URL } from "../utils/baseURL";
import InfiniteScroll from "react-infinite-scroll-component";
import SharedMedicineService from "../services/sharedMedicine.service";
import CustomTable from "../components/Shared/CustomTable/CustomTable";
import { CiBoxList } from "react-icons/ci";
import { BiSolidGrid } from "react-icons/bi";
import SharedMedicineItemV1 from "../components/SharedMedicineItem/SharedMedicineItemV1";
import SharedMedicineBanner from "../components/SharedMedicineBanner/SharedMedicineBanner";

const initialSortFilterValues = [
  {
    label: "Populares",
    // value: "nameAsc",
    field: "Rank",
    value: JSON.stringify({
      field: "medicine.Rank",
      value: false,
    }),
  },
  {
    label: "Nombre A-Z",
    // value: "nameAsc",
    field: "ProductName",
    value: JSON.stringify({
      field: "medicine.name",
      value: false,
    }),
  },
  {
    label: "Nombre Z-A",
    // value: "nameDesc",
    value: JSON.stringify({
      field: "medicine.name",
      value: true,
    }),
  },
  {
    label: "Precio (Menor a Mayor)",
    field: "price",
    value: JSON.stringify({
      field: "medicine.price",
      value: false,
    }),
  },
  {
    label: "Precio (Mayor a Menor)",
    field: "price",
    value: JSON.stringify({
      field: "medicine.price",
      value: true,
    }),
  },
];

const inventoryDropDown = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Sobre Pedido",
    value: "Sobre Pedido",
  },
  {
    label: "En existincia",
    value: "En existincia",
  },
];

const initialFilter = {
  searchFilter: "",
  country: "All",
  pharmacy: "All",
  sortBy: initialSortFilterValues[0],
  originCountry: "All",
  deliveryTime: "All",
  // disease: "All",
  speciality: "All",
  inventory: "All",
};

const filterReducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "searchFilter":
    case "sortBy":
    case "pharmacy":
    case "originCountry":
    case "deliveryTime":
    // case "disease":
    case "speciality":
    case "inventory":
    case "country": {
      let newState = { ...state };
      newState[action.payload.key] = action.payload.value;
      return newState;
    }
    case "filterByDate": {
      let newState = { ...state };
      newState[action.payload.key] = action.payload.value;
      return newState;
    }
    case "sortBy": {
      let newState = { ...state };
    }
    // eslint-disable-next-line no-fallthrough
    case "reset": {
      return initialFilter;
    }
    case "default":
      return state;
  }
};

const SharedMedicinePage = ({ setIsLoginModalOpen }) => {
  const { token, user } = useSelector((state) => state?.auth);
  const metaTagsData = useSelector((state) => state.metaTags.metaTags);
  const { selectedCurrency } = useContext(CurrencyContext);
  const [isOpenContactForm, setIsOpenContactForm] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // console.log("searchResults >>>", searchResults);
  // console.log("data >>>", data);
  const slug = location.pathname?.split("/")?.at(-1);

  const selectedCurrencyRef = useRef(selectedCurrency?.currency);

  const [searchValue, setSearchValue] = useState("");
  const pageSize = PAGING_SIZE;
  const [pageIndex, setPageIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [originCountries, setOriginCountries] = useState([]);
  const [deliveryTime, setDeliveryTime] = useState([]);

  // toggle button state
  const [isListToggle, setIsListToggle] = useState(false);

  // // country
  const [countries, setCountries] = useState([]);

  // //PharmacyShops
  const [pharmacyShops, setPharmacyShops] = useState([]);

  const [appliedFilters, dispatchAppliedFilters] = useReducer(
    filterReducer,
    initialFilter
  );

  // params
  const [searchParams, setSearchParams] = useSearchParams();

  const addFilter = (key, value, stringify = false) => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set(key, stringify ? JSON.stringify(value) : value);
      return prevSearchParams;
    });
  };
  const isJsonParsable = (val) => {
    try {
      return JSON.parse(val);
    } catch (err) {
      return false;
    }
  };
  const jsonParse = (value) => {
    try {
      return JSON.parse(value);
    } catch (err) {
      return value;
    }
  };
  const applyInitialFilters = () => {
    for (let key in appliedFilters) {
      const val = searchParams.get(key);
      if (val) updateFilter(key, jsonParse(val), isJsonParsable(val));
    }
  };

  useEffect(() => {
    applyInitialFilters();
  }, []);

  const prepareSearchPayload = useCallback(
    (pagination = true, summary = false) => {
      let payload = {
        searchFilter: "",
      };
      if (pagination) {
        payload = {
          ...payload,
          _pageSize: pageSize,
          _pageIndex: pageIndex,
        };
      }
      if (selectedCurrency) {
        payload.currencyFilter = selectedCurrency?.currency || "MXN";
      }
      for (let key in appliedFilters) {
        if (appliedFilters[key] && appliedFilters[key] !== "All") {
          if (key === "sortBy") {
            payload[key] = jsonParse(jsonParse(appliedFilters[key])?.value);
          } else {
            payload[key] = appliedFilters[key];
          }
        }
      }
      // if (summary) {
      //   payload.summary = true;
      // }
      return payload;
    },
    [appliedFilters, selectedCurrency, pageIndex, pageSize]
  );

  const fetchSearchResults = useCallback(async () => {
    setIsLoading(true);
    try {
      const payload = prepareSearchPayload(true);
      const response = await userRequest.get(`/shared-medicine/${slug}`, {
        params: payload,
      });
      setIsLoading(false);
      if (response.data) setSearchResults(response.data);
      if (response.data?.medicine?.length > pageSize - 1) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      if (payload._pageIndex === 0) {
        setData(response.data?.medicine);
      } else if (response.data && response.data?.medicine?.length) {
        setData((prevItems) => [...prevItems, ...response.data?.medicine]);
      } else {
        console.log("no data");
      }
    } catch (error) {
      console.log("error from pharmacies >>>", error);
    } finally {
      setIsLoading(false);
    }
  }, [prepareSearchPayload]);

  useEffect(() => {
    fetchSearchResults();
    clearLogs();
  }, [selectedCurrency, appliedFilters, pageIndex, pageSize]);

  useEffect(() => {
    if (selectedCurrencyRef.current !== selectedCurrency?.currency) {
      // console.log('Previous value:', selectedCurrencyRef.current);
      // console.log('Updated value:', selectedCurrency);
      setPageIndex(0);
      setIsLoading(true);
      setData([]);
    }
    selectedCurrencyRef.current = selectedCurrency;
  }, [selectedCurrency]);

  const fetchPharmacyStores = async () => {
    try {
      const payload = {
        groupedField: "medicine.pharmacyName",
        slug: slug,
      };
      if (appliedFilters?.country !== "All") {
        payload.country = appliedFilters?.country;
      }
      const response = await userRequest.get(
        `/shared-medicine/list-categorised-data`,
        {
          params: payload,
        }
      );

      if (Array.isArray(response.data) && response.data?.length) {
        let pharmacies = response.data?.map((shop) => ({
          // label: shop?._id.toUpperCase(),
          label: shop?._id,
          value: shop?._id,
          isActive: true,
        }));
        pharmacies.unshift({
          label: "Todas",
          value: "",
          isActive: true,
        });
        setPharmacyShops(pharmacies);
      }
    } catch (error) {
      console.log("error from catch PHARMACY_SHOPS >>>>", error);
    }
  };
  const fetchOriginCountry = async () => {
    try {
      const payload = {
        groupedField: "medicine.originCountry",
        slug: slug,
      };
      if (appliedFilters?.country !== "All") {
        payload.country = appliedFilters?.country;
      }
      const response = await userRequest.get(
        `/shared-medicine/list-categorised-data`,
        {
          params: payload,
        }
      );

      if (Array.isArray(response.data) && response.data?.length) {
        let pharmacies = response.data?.filter((shop) => {
          if (shop?._id) return shop;
        });
        pharmacies = pharmacies?.map((shop) => ({
          // label: shop?._id.toUpperCase(),
          label: shop?._id,
          value: shop?._id,
          isActive: true,
        }));
        pharmacies.unshift({
          label: "Todos",
          value: "",
          isActive: true,
        });
        setOriginCountries(pharmacies);
      }
    } catch (error) {
      console.log("error from catch PHARMACY_SHOPS >>>>", error);
    }
  };
  const fetchDeliveryTime = async () => {
    try {
      const payload = {
        groupedField: "medicine.deliveryTime",
        slug: slug,
      };
      if (appliedFilters?.country !== "All") {
        payload.country = appliedFilters?.country;
      }
      const response = await userRequest.get(
        `/shared-medicine/list-categorised-data`,
        {
          params: payload,
        }
      );

      if (Array.isArray(response.data) && response.data?.length) {
        let pharmacies = response.data?.map((shop) => ({
          // label: shop?._id.toUpperCase(),
          label: shop?._id,
          value: shop?._id,
          isActive: true,
        }));
        pharmacies.unshift({
          label: "Todas",
          value: "",
          isActive: true,
        });
        setDeliveryTime(pharmacies);
      }
    } catch (error) {
      console.log("error from catch PHARMACY_SHOPS >>>>", error);
    }
  };
  useEffect(() => {
    scrollToTop();
    fetchOriginCountry();
    fetchDeliveryTime();
  }, []);

  useEffect(() => {
    fetchPharmacyStores();
  }, [appliedFilters?.country]);

  const handleLoginModal = () => {
    setIsLoginModalOpen(true);
  };
  const handleNavigate = () => {
    navigate(`/buscar`);
  };

  useEffect(() => {
    let payload = {
      title: "Vooy Farma | Buscador de medicamentos en México",
      description:
        "En Vooy Farma hacemos la búsqueda de medicamentos en varios sitios de farmacias de México por ti",
    };
    dispatch(updateMetaTags(payload));
  }, [dispatch]);

  const loadMoreData = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setPageIndex(pageIndex + 1);
  };

  const searchHandler = () => {
    dispatchAppliedFilters({
      payload: { key: "searchFilter", value: searchValue },
      type: "searchFilter",
    });
    setPageIndex(0);
    setSearchResults({});
    if (searchValue) setData([]);
  };

  const updateFilter = (key, value, stringify = false) => {
    setSearchResults({});
    setData([]);
    dispatchAppliedFilters({ payload: { key, value }, type: key });
    addFilter(key, value, stringify);
    setPageIndex(0);
  };

  const handleReset = async () => {
    setSearchParams({});
    setSearchValue("");
    dispatchAppliedFilters({ type: "reset" });
    setPageIndex(0);
  };

  const showContactForm = () => {
    setIsOpenContactForm(true);
  };
  const closeContactForm = () => {
    setIsOpenContactForm(false);
  };

  const handleReqeustAccess = async () => {
    if (!token) return handleLoginModal();
    setIsLoading(true);
    try {
      const payload = {
        slug: slug,
        email: user?.email,
      };
      const response = await userRequest.post(
        `/shared-medicine/request-access`,
        payload
      );
      toast.success(
        "Su solicitud ha sido enviada al administrador para su aprobación."
      );
      // console.log("response >>", response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
      console.log("error from access >>>", error);
    }
  };
  const handleGetProtectedList = async (e) => {
    e.preventDefault();
    const listPassword = e.target?.password?.value;
    if (!token) return handleLoginModal();
    if (!listPassword) return toast.error("Please enter list password");

    setIsLoading(true);
    try {
      const payload = {
        slug: slug,
        listPassword: listPassword,
      };
      const response = await userRequest.post(
        `/shared-medicine/get-protected-list`,
        payload
      );
      // toast.success(
      //   "Su solicitud ha sido enviada al administrador para su aprobación."
      // );
      console.log("response >>", response);
      fetchSearchResults();

      // setSearchResults(response.data)
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
      console.log("error from access >>>", error);
    }
  };

  // load options for async paginate
  const getPaginatedArr = ({
    data,
    page,
    summary,
    isExistAllLabel = false,
  }) => {
    let sortExpertiesList = summary
      ? formatDataForDropDownFilter(
          data,
          "_id",
          "_id",
          "count",
          isExistAllLabel
        )
      : formatDataWithoutCountForDropDownFilter(
          data,
          "_id",
          "_id",
          isExistAllLabel
        );
    // setExperties(sortExpertiesList);
    return {
      options: sortExpertiesList || [],
      hasMore: data?.length ? true : false,
      additional: {
        page: data?.length ? page + 1 : page + 0,
      },
    };
  };

  async function loadSpecialityOptions(search, loadedOptions, { page }) {
    const isExistAllLabel = loadedOptions?.some(
      (item) => item?.label === "All"
    );
    const summary = false;
    const payload = {
      search: search,
      groupedField: "medicine.speciality",
      arrField: "medicine.speciality",
      pageSize: pageSize,
      pageIndex: page,
      slug: slug,
      // summary: true
    };
    try {
      // const data = await MedicineService.getListGroupedDataV2(payload);
      const data = await SharedMedicineService.getListGroupedData(payload);
      return getPaginatedArr({ data, page, summary, isExistAllLabel });
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  }

  async function loadDiseaseOptions(search, loadedOptions, { page }) {
    const isExistAllLabel = loadedOptions?.some(
      (item) => item?.label === "All"
    );

    try {
      const data = await MedicineService.getListGroupedData({
        search: search,
        groupedField: "disease",
        pageSize: pageSize,
        pageIndex: page,
      });
      return getPaginatedArr({ data, page, isExistAllLabel });
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  }

  async function loadDeliveryTimeOptions(search, loadedOptions, { page }) {
    const isExistAllLabel = loadedOptions?.some(
      (item) => item?.label === "All"
    );
    const summary = false;
    const payload = {
      // searchFilter: search,
      search: search,
      pageSize: pageSize,
      pageIndex: page,
      groupedField: "deliveryTime",
    };
    try {
      const data = await MedicineService.getListGroupedDataV2(payload);
      return getPaginatedArr({ data, page, summary, isExistAllLabel });
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  }

  async function loadOriginCountryOptions(search, loadedOptions, { page }) {
    const isExistAllLabel = loadedOptions?.some(
      (item) => item?.label === "All"
    );
    const summary = false;
    const payload = {
      // searchFilter: search,
      search: search,
      pageSize: pageSize,
      pageIndex: page,
      groupedField: "originCountry",
    };
    try {
      const data = await MedicineService.getListGroupedDataV2(payload);
      return getPaginatedArr({ data, page, summary, isExistAllLabel });
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  }

  const columns = (
    <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
      <tr>
        <th scope="col" className="px-6 py-3">
          Imagen
        </th>
        <th scope="col" className="px-6 py-3">
          Nombre
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">Farmacia</div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">Sustancia Activa</div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">Entega</div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">Continente de origen</div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">Laboratorio</div>
        </th>
        {slug !== "ulsatech" && (
          <th scope="col" className="px-6 py-3">
            <div className="flex items-center">Inventario</div>
          </th>
        )}
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">FDA</div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">EMA</div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">Action</div>
        </th>
      </tr>
    </thead>
  );

  if (
    !isLoading &&
    searchResults?.isPasswordRequired &&
    searchResults?.hasOwnProperty("isPasswordRequired")
  ) {
    return (
      <div className="flex justify-center items-center h-[50vh]">
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col items-center justify-center my-3 w-full">
            <div>
              <p className="my-3">
                Password Required: To view this list, please enter password
              </p>
            </div>
            <form className="w-full" onSubmit={handleGetProtectedList}>
              <TextField
                label="Contraseña"
                name="password"
                icon={<AiOutlineLock className="text-xl" />}
                placeholder="Enter List Password"
                type="password"
              />
              <div className="flex items-center justify-center my-6">
                <Button className="w-full" type="submit" disabled={isLoading}>
                  Enter Password
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (
    !isLoading &&
    ((!searchResults?.isPublic && searchResults?.hasOwnProperty("isPublic")) ||
      searchResults?.isPendingRequest)
  ) {
    return (
      <div className="flex justify-center items-center h-[50vh]">
        <div className="flex flex-col justify-center items-center">
          <div className="flex items-center justify-center my-3 w-full">
            {searchResults?.isPendingRequest ? (
              <p>Solicitud pendiente</p>
            ) : (
              <Button className="w-full" onClick={handleReqeustAccess}>
                Solicitar acceso
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  const activeClassForToggle = "text-blue-600 bg-gray-100 rounded-t-lg active";
  const disabledClassForToggle =
    "rounded-t-lg hover:text-gray-600 hover:bg-gray-50";

  return (
    <div className="sm:container px-3 py-6">
      <Helmet>
        <link rel="canonical" href={`https://vooyfarma.com/lista/${slug}`} />

        <title>{metaTagsData.title}</title>
        <meta name="description" content={metaTagsData.description} />
      </Helmet>
      <div className="flex flex-col lg:flex-col">
        {true ? (
          <>
            <div className="py-2 w-full">
              {!isLoading && searchResults?.medicine?.length > 0 ? (
                <div className="font-[600] pb-4">
                  <h2 className="font-[600] text-xl">
                    {_convertToSentenceCase(searchResults?.name)}
                  </h2>
                </div>
              ) : null}

              <DoctorSearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                onChangeValue={searchHandler}
                placeholder="Buscador de medicamentos"
              />
              {!isLoading && searchResults?.medicine?.length > 0 && (
                <div className="py-2 flex justify-between">
                  <div className="xs:pr-4">
                    <p className="p-0 font-[300]" onClick={handleReset}>
                      {"Si necesitan medicamentos difíciles de encontrar, "}
                      <button
                        className="text-secondary underline py-2 font-[600]"
                        onClick={showContactForm}
                      >
                        contáctenos
                      </button>
                    </p>
                  </div>
                  {/* <div>
                    <ToggleButton
                      isChecked={isListToggle}
                      setIsChecked={setIsListToggle}
                      label={`Toggle view by ${isListToggle ? "List" : "Grid"}`}
                    />
                  </div> */}
                </div>
              )}
              <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 py-2">
                <div className=" xs:pr-4 xs:border-r-2 xs:border-gray-200">
                  <div className="font-[600]">Ordernar por:</div>
                  <ReactSelectComp
                    // style={reactSelectStyleDefault}
                    placeholder="Seleccionar"
                    options={initialSortFilterValues}
                    value={appliedFilters?.sortBy ? appliedFilters?.sortBy : ""}
                    onChange={(e) => updateFilter("sortBy", e, true)}
                  />
                </div>
                <SelectDropDown
                  options={pharmacyShops}
                  label="Farmacias:"
                  value={appliedFilters?.pharmacy}
                  onChange={(value) => updateFilter("pharmacy", value)}
                />
                {/** 
                 <SelectDropDown
                  options={countries}
                  label="País:"
                  value={appliedFilters?.country}
                  onChange={(value) => updateFilter("country", value)}
                /> 
                 
                 */}
                <SelectDropDown
                  options={originCountries}
                  label="Continente de origen:"
                  value={appliedFilters?.originCountry}
                  onChange={(value) => updateFilter("originCountry", value)}
                />
                <SelectDropDown
                  options={deliveryTime}
                  label="Entrega:"
                  value={appliedFilters?.deliveryTime}
                  onChange={(value) => updateFilter("deliveryTime", value)}
                />
                <div className="xs:pr-4 xs:border-r-2 xs:border-gray-200">
                  <p className="font-[600] p-0">Especialidad:</p>
                  <ReactSelectPaginate
                    value={appliedFilters?.speciality}
                    onChange={(value) => updateFilter("speciality", value)}
                    loadOptions={loadSpecialityOptions}
                  />
                </div>
                {slug !== "ulsatech" && (
                  <SelectDropDown
                    options={inventoryDropDown}
                    label="Inventario:"
                    value={appliedFilters?.inventory}
                    onChange={(value) => updateFilter("inventory", value)}
                  />
                )}
                <div className="xs:pr-4 flex justifyflex items-center justify-start">
                  <button
                    className="text-secondary underline font-[600]"
                    onClick={handleReset}
                  >
                    Resetear filtros
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="py-3"> */}
            <div className="pb-3">
              {isLoading && !searchResults?.name && (
                <div
                  className="flex justify-center items-center h-72"
                  data-aos="fade-up"
                >
                  <div className="flex justify-center items-center">
                    <Oval
                      height={50}
                      width={50}
                      color="rgba(51, 124, 171, 1)"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="rgba(51, 124, 171, 1)"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                </div>
              )}
              {data?.length > 0 ? (
                // <div className="my-4">
                <div className="mb-4">
                  <SharedMedicineBanner />
                  {searchResults?.medicineSize > 0 && (
                    <div className="flex justify-between items-center">
                      <div>
                        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500">
                          <li className="hidden sm:flex sm:justify-center sm:items-center sm:mr-2 xl:mr-2">
                            Estilo de lista:
                          </li>
                          <li className="xl:mr-1 flex justify-center items-center">
                            <span
                              aria-current="page"
                              className={`inline-block px-1 py-1 hover:cursor-pointer ${
                                !isListToggle
                                  ? activeClassForToggle
                                  : disabledClassForToggle
                              }`}
                              onClick={() => setIsListToggle(!isListToggle)}
                            >
                              <BiSolidGrid size={25} />
                            </span>
                          </li>
                          <li className="xl:mr-1 flex justify-center items-center">
                            <span
                              className={`inline-block px-1 py-1 hover:cursor-pointer ${
                                isListToggle
                                  ? activeClassForToggle
                                  : disabledClassForToggle
                              }`}
                              onClick={() => setIsListToggle(!isListToggle)}
                            >
                              <CiBoxList size={25} />
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-end items-center">
                        <div>
                          <CurrencyDropDown origin={false} />
                        </div>
                        <div>
                          Total:
                          <span className="font-bold text-md">{` ${searchResults?.medicineSize}`}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className="h-[100vh] overflow-y-scroll"
                    id="search-container"
                  >
                    <InfiniteScroll
                      dataLength={data?.length}
                      next={loadMoreData}
                      hasMore={hasMore}
                      loader={
                        <div className="flex justify-center items-center">
                          <Oval
                            height={50}
                            width={50}
                            color="rgba(51, 124, 171, 1)"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="rgba(51, 124, 171, 1)"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                          />
                        </div>
                      }
                      scrollableTarget="search-container"
                    >
                      {isListToggle ? (
                        <CustomTable
                          columns={columns}
                          rows={data?.map((medicine, index) => (
                            <SharedMedicineItemV1
                              key={medicine.id}
                              name={medicine.name}
                              categoryName={medicine.category}
                              amount={medicine?.amount}
                              id={medicine.id}
                              medicineSlug={medicine?.medicine?.medicineSlug}
                              categorySlug={medicine?.medicine?.categorySlug}
                              image={
                                medicine?.images?.length
                                  ? medicine?.images?.[0]?.includes("http")
                                    ? medicine?.images?.[0]
                                    : `${S3_BASE_URL}${medicine?.images?.[0]}`
                                  : medicine?.Image?.[0]
                                  ? medicine?.Image?.[0]
                                  : placeHolderImage
                              }
                              pharmacyName={
                                medicine?.Pharmacyname ||
                                medicine?.pharmacyName ||
                                "-"
                              }
                              price={
                                medicine?.discountedPrice
                                  ? medicine?.discountedPrice
                                  : medicine?.price
                              }
                              // medicine={searchResults?.medicine?.[index]}
                              medicine={medicine}
                              deliveryTime={medicine?.deliveryTime}
                              originCountry={medicine?.originCountry}
                              isNavigateToWeb={searchResults?.isNavigateToWeb}
                              isNavigateToWhatsapp={
                                searchResults?.isNavigateToWhatsapp
                              }
                              url={medicine?.url}
                              urlFda={medicine?.urlFda}
                              urlEma={medicine?.urlEma}
                              inventory={medicine?.inventory}
                              brand={medicine?.brand}
                              slug={slug}
                              isDisplayExtraInfo={
                                searchResults?.isDisplayExtraInfo || false
                              }
                              extraInfo={medicine?.extraInfo}
                              previewUrl={medicine?.previewUrl}
                            />
                          ))}
                        />
                      ) : (
                        <div className="mt-2 mb-6 grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                          {data?.map((medicine, index) => (
                            <SharedMedicineItem
                              key={medicine.id}
                              name={medicine.name}
                              categoryName={medicine.category}
                              amount={medicine?.amount}
                              id={medicine.id}
                              medicineSlug={medicine?.medicine?.medicineSlug}
                              categorySlug={medicine?.medicine?.categorySlug}
                              image={
                                medicine?.images?.length
                                  ? medicine?.images?.[0]?.includes("http")
                                    ? medicine?.images?.[0]
                                    : `${S3_BASE_URL}${medicine?.images?.[0]}`
                                  : medicine?.Image?.[0]
                                  ? medicine?.Image?.[0]
                                  : placeHolderImage
                              }
                              pharmacyName={
                                medicine?.Pharmacyname ||
                                medicine?.pharmacyName ||
                                "-"
                              }
                              price={
                                medicine?.discountedPrice
                                  ? medicine?.discountedPrice
                                  : medicine?.price
                              }
                              // medicine={searchResults?.medicine?.[index]}
                              medicine={medicine}
                              deliveryTime={medicine?.deliveryTime}
                              originCountry={medicine?.originCountry}
                              isNavigateToWeb={searchResults?.isNavigateToWeb}
                              isNavigateToWhatsapp={
                                searchResults?.isNavigateToWhatsapp
                              }
                              url={medicine?.url}
                              urlFda={medicine?.urlFda}
                              urlEma={medicine?.urlEma}
                              inventory={medicine?.inventory}
                              brand={medicine?.brand}
                              slug={slug}
                              isDisplayExtraInfo={
                                searchResults?.isDisplayExtraInfo || false
                              }
                              extraInfo={medicine?.extraInfo}
                              previewUrl={medicine?.previewUrl}
                            />
                          ))}
                        </div>
                      )}
                    </InfiniteScroll>
                  </div>
                </div>
              ) : null}
              {!isLoading && !data?.length && (
                <div
                  className="flex justify-center items-center h-72"
                  data-aos="fade-up"
                >
                  Medicina no encontrada
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-[50vh]">
            <div className="flex flex-col justify-center items-center">
              <p className="text-primary font-[600] text-sm">
                Inicie sesión para ver la lista de medicamentos
              </p>
              <div className="flex items-center justify-center my-3 w-full">
                <Button className="w-full" onClick={handleLoginModal}>
                  Iniciar sesión o registrarse
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ContactForm
        isOpenContactForm={isOpenContactForm}
        closeContactForm={closeContactForm}
      />
    </div>
  );
};

export default SharedMedicinePage;
