import React, { useEffect, useState } from "react";
import MedicineItem from "../MedicineSection/MedicineItem";
import userRequest from "../../utils/requestMethods";
import { S3_BASE_URL } from "../../utils/baseURL";

const BannerSection = () => {
  const [bannerData, setBannerData] = useState({});

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await userRequest.get(`/banner`);
        setBannerData(response.data);
      } catch (error) {
        console.log("error from catch block >>>", error);
      }
    };
    fetchBanner();
  }, []);

  return (
    <div className="pb-6">
      {bannerData && bannerData?.banner && (
        <div className="my-6 mb-8 rounded-2xl relative h-max">
          <div className="absolute bottom-[-80px] right-0 left-0 xs:block hidden">
            <div className="my-8 grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mx-4">
              {bannerData &&
                bannerData?.medicine?.map((medicine) => (
                  <MedicineItem
                    key={medicine.id}
                    name={medicine.name}
                    pharmacyName={medicine.pharmacyName}
                    categoryName={medicine.category}
                    price={medicine.price}
                    amount={medicine?.amount}
                    image={medicine.image}
                    id={medicine.id}
                    isFavourite={medicine?.isFavourite || false}
                  />
                ))}
            </div>
          </div>
          <div
            className="flex items-center justify-center h-[400px] w-full px-10 py-12 my-6 rounded-2xl"
            style={{
              background: `url("${
                S3_BASE_URL + bannerData.banner?.image
              }") no-repeat center center/cover`,
            }}
          >
            <div className="flex items-center justify-start w-9/12">
              <h1 className="text-2xl sm:text-4xl font-[600] text-primary text-white w-8/12">
                {bannerData.banner?.title}
              </h1>
            </div>
          </div>
          <div className="flex items-center h-[350px] w-full px-10 py-12 rounded-2xl my-6" />
        </div>
      )}
      {bannerData && bannerData?.banner && <div className="mb-12 pb-6" />}
    </div>
  );
};

export default BannerSection;
