import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const TextField = ({
  label,
  name,
  icon,
  placeholder,
  onChange,
  type = "text",
  defaultValue,
  value,
  disabled = false,
  isRequired = "",
  parentClassName = "my-3",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={parentClassName}>
      <label htmlFor={name} className="font-[600]">
        {label}{isRequired}
      </label>
      <div className="flex items-center gap-2 bg-lightSecondary py-3 px-3 rounded-md mt-2">
        {icon}
        <input
          type={showPassword ? "text" : type}
          name={name}
          id={name}
          placeholder={placeholder}
          className="bg-transparent w-full focus:outline-none custom-text-field"
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        {type === "password" && (
          <button
            type="button"
            className="focus:outline-none"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <AiOutlineEyeInvisible className="text-xl" />
            ) : (
              <AiOutlineEye className="text-xl" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default TextField;
