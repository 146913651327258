import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as ArchiveFill } from "../../assets/svgs/archive-fill.svg";
import { ReactComponent as Archive } from "../../assets/svgs/archive.svg";
import { baseURL } from "../../utils/baseURL";
import { Helmet } from "react-helmet";
import placeHolderImage from "../../assets/images/pills.placeholder.webp";
import { _convertToSentenceCase, clearLogs, getCleanedUrl } from "../../utils";
import { CurrencyContext } from "../../context/CurrencyProvider";
import { Currencies } from "../../constants";
import { FAVOURITE_TEXT, UN_FAVOURITE_TEXT } from "../../constants/text";

const MedicineItem = ({
  name,
  pharmacyName,
  categoryName,
  price,
  amount,
  image,
  id,
  isFavourite,
  medicineSlug,
  categorySlug,
}) => {
  const { user, token } = useSelector((state) => state?.auth);
  const { selectedCurrency } = useContext(CurrencyContext);

  // const navigate = useNavigate();
  const [isArchived, setIsArchived] = useState(isFavourite);
  const [activeImage, setActiveImage] = useState(image);

  const toggleArchiveHandler = async () => {
    setIsArchived((prevState) => !prevState);

    const payload = {
      pharmacyId: id,
      isFavourite: !isArchived,
    };
    if (user) {
      // console.log("payload >>>", payload);
      const response = await axios
        .put(`${baseURL}/favourite`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
          setIsArchived((prevState) => !prevState);
        });
      if (response) {
        toast.success(!isArchived ? FAVOURITE_TEXT : UN_FAVOURITE_TEXT);

      }
    } else {
      toast.warn("Por favor ingresa primero");
      setIsArchived((prevState) => !prevState);
    }
  };

  useEffect(() => {
    setIsArchived(isFavourite);
  }, [isFavourite]);
  clearLogs();

  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: name,
    image: image,
    description: name,
    brand: {
      "@type": "Brand",
      name: pharmacyName,
    },
    offers: {
      "@type": "Offer",
      url: "url",
      priceCurrency: "MXN",
      price: parseFloat(amount) || "0.00",
      availability: "https://schema.org/InStock",
      shippingDetails: {
        "@type": "ShippingDeliveryTime",
        transitTime: "2-3 Business Days",
      },
      hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        refundType: "Full Refund",
        returnPolicyCategory: "https://schema.org/FullRefund",
        returnPolicyCountry: "MX",
        returnPolicyEffectiveDate: "2023-07-13",
      },
    },
  };

  return (
    <div
      className="relative bg-white rounded-lg px-6 py-4 w-full cursor-pointer transition-all duration-300 ease-in hover:shadow-md"
      data-aos="fade-up"
      key={name}
    >
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Link
        to={`/${getCleanedUrl(pharmacyName)}/${categorySlug}/${medicineSlug}`}
        state={id}
      >
        <div className="flex justify-center items-center">
          <img
            src={activeImage}
            // src={}
            alt={name}
            style={{ width: "140px", height: "120px", objectFit: "contain" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = placeHolderImage;
            }}
          />
        </div>
        <div className="mt-2">
          <div className="flex items-center gap-1">
            <HiLocationMarker className="text-lg sm:text-xl text-red-600" />
            <p className="text-sm">{_convertToSentenceCase(pharmacyName)}</p>
          </div>
          <h3 className="font-[600] text-lg sm:text-xl my-2">{_convertToSentenceCase(name)}</h3>
          <p className="text-center text-secondary font-[700]">
            {/* {amount?.includes("$") ? amount : `$${amount}`}{" "} */}
            {`${Currencies[selectedCurrency?.currency]?.sign}${amount}`}{" "}
          </p>
        </div>
      </Link>

      {!isArchived && (
        <button
          className="absolute top-6 right-6"
          onClick={toggleArchiveHandler}
          aria-label="Add to Favourite"
        >
          <Archive width={30} height={30} />
        </button>
      )}
      {isArchived && (
        <button
          className="absolute top-6 right-6"
          onClick={toggleArchiveHandler}
          aria-label="Remove from Favourite"
        >
          <ArchiveFill width={30} height={30} />
        </button>
      )}
    </div>
  );
};

export default MedicineItem;
