import React, { useContext } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

import placeHolderImage from "../../assets/images/pills.placeholder.webp";
import { Helmet } from "react-helmet";

import {
  _convertAndCheckArrayOfStringIntoSentaenceCase,
  _convertToSentenceCase,
  clearLogs,
  convertArrayOfStringIntoSentaenceCase,
  getCleanedUrl,
  getObjectId,
  handleCapitalizedString,
} from "../../utils";
import { Currencies, whatsAppNum } from "../../constants";
import { CurrencyContext } from "../../context/CurrencyProvider";
import { FaLink } from "react-icons/fa6";
import useAddToCart from "../../hooks/useAddToCart";
import CartHelper from "../../helpers/CartHelper";
import { toast } from "react-toastify";
import _ from "lodash";
import ConditionalLinkOrDiv from "../Shared/ConditionalLinkOrDiv/ConditionalLinkOrDiv";

const SharedMedicineItem = ({
  name,
  pharmacyName,
  categoryName,
  price,
  amount,
  image,
  id,
  medicineSlug,
  categorySlug,
  medicine,
  deliveryTime,
  originCountry,
  isNavigateToWeb,
  isNavigateToWhatsapp,
  url,
  urlFda,
  urlEma,
  inventory,
  brand,
  slug,
  isDisplayExtraInfo,
  extraInfo,
  previewUrl,
}) => {
  // console.log("medicine >>>", medicine);
  const { selectedCurrency } = useContext(CurrencyContext);
  const { PRODUCT, ...otherInfo } = extraInfo || {};
  const isIngredinets =
    medicine?.ingredients?.length || medicine?.ExtraInfo?.Ingredientes?.length;

  const text = `Hola, quiero cotizar el Medicamento: ${_convertToSentenceCase(
    name
  )}${
    isIngredinets
      ? `, Sustancia Activa: ${
          medicine?.ingredients?.length
            ? convertArrayOfStringIntoSentaenceCase(
                medicine?.ingredients
              )?.join(", ")
            : convertArrayOfStringIntoSentaenceCase(
                medicine?.ExtraInfo?.Ingredientes
              )?.join(", ")
        }`
      : ""
  }`;

  const whatsappURL = `https://wa.me/${whatsAppNum}?text=${text}`;
  const globalSingapureLogo =
    "https://vooy-farma.s3.amazonaws.com/images/Logo+Global+Singapure.png";
  const GLOBAL_SINGAPURE_NAME = "Global Singapore";

  // add to cart
  const { cart, addProductToCart, removeProductFromCart, onPressCartIcon } =
    useAddToCart();
  // console.log("cart >>>", cart);
  // console.log("slug >>>", slug);

  const isAlreadyAddedProductIndex = CartHelper.getAlreadyProductAddedIndex(
    cart,
    medicine
  );
  const addToCart = (product) => {
    // console.log("product >>", product);
    let existingMedicine = cart?.[isAlreadyAddedProductIndex];
    if (existingMedicine) {
      existingMedicine = {
        ...existingMedicine,
        quantity: existingMedicine.quantity + 1,
      };
      addProductToCart({
        ...product,
        quantity: existingMedicine.quantity,
        listSlug: slug,
        id: product?._id,
      });
      toast.success(
        `${product?.name} cantidad actualizada a ${existingMedicine.quantity}`
      );
    } else {
      addProductToCart({
        ...product,
        quantity: 1,
        listSlug: slug,
        id: product?._id,
      });
      toast.success(`${product?.name} añadido al carrito`);
    }
  };

  const removeItem = (product) => {
    removeProductFromCart(getObjectId(product));
    toast.info(`${product?.name} eliminado del carrito`);
  };

  const handleNavigateToWhatsapp = () => {
    window.open(whatsappURL, "_blank");
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: name || "",
    image: image || "",
    description: name || "",
    brand: {
      "@type": "Brand",
      name: pharmacyName || "",
    },
    offers: {
      "@type": "Offer",
      url: "https://vooyfarma.com/buscar",
      priceCurrency: "MXN",
      price: parseFloat(price) || "0.00",
      availability: "https://schema.org/InStock",
      shippingDetails: {
        "@type": "ShippingDeliveryTime",
        transitTime: "2-3 Business Days",
      },
      hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        refundType: "Full Refund",
        returnPolicyCategory: "https://schema.org/FullRefund",
        returnPolicyCountry: "MX",
        returnPolicyEffectiveDate: "2023-07-13",
      },
    },
  };
  clearLogs();
  return (
    <div
      className="relative bg-white rounded-lg px-6 py-4 w-full cursor-pointer transition-all duration-300 ease-in hover:shadow-md"
      // data-aos="fade-up"
      key={name}
    >
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <ConditionalLinkOrDiv
        url={isNavigateToWhatsapp ? whatsappURL : url || ""}
        children={
          <>
            <div className="flex justify-center items-center">
              <img
                src={image}
                alt={name}
                style={{
                  width: "140px",
                  height: "100px",
                  objectFit: "contain",
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = placeHolderImage;
                }}
              />
            </div>
            <div className="mt-2 pb-6">
              <h3 className="font-[600] text-sm sm:text-sm my-1 pb-2">
                {_convertToSentenceCase(name)}
              </h3>
              <div className="flex items-center justify-between gap-1">
                {pharmacyName !== GLOBAL_SINGAPURE_NAME && !medicine?.previewUrl ? (
                  <div className="flex gap-1">
                    <HiLocationMarker className="text-sm text-red-600" />
                    <p className="text-xss m-0 p-0">
                      {_convertToSentenceCase(pharmacyName)}
                    </p>
                  </div>
                ) : null}
                {pharmacyName === GLOBAL_SINGAPURE_NAME  || medicine?.previewUrl && (
                  <div>
                    <img
                      src={medicine?.previewUrl || globalSingapureLogo}
                      alt="Global Singapure"
                      style={{
                        width: "60px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </div>
              {isIngredinets ? (
                <div className="flex items-start gap-1">
                  <p className="text-xss text-left pt-1 pb-0">
                    {/* {"Sustancia Activa:"}{" "} */}
                    {medicine?.ingredients?.length
                      ? _convertAndCheckArrayOfStringIntoSentaenceCase(
                          medicine?.ingredients
                        )?.join(", ")
                      : medicine?.ExtraInfo?.Ingredientes?.length
                      ? _convertAndCheckArrayOfStringIntoSentaenceCase(
                          medicine?.ExtraInfo?.Ingredientes
                        )?.join(", ")
                      : _convertAndCheckArrayOfStringIntoSentaenceCase(
                          medicine?.ExtraInfo?.Ingredients
                        )?.join(", ")}
                  </p>
                </div>
              ) : null}
              {deliveryTime ? (
                <div className="flex items-start gap-1">
                  <p className="text-xss text-left pt-1 pb-0">
                    <span className="font-bold">Entega:</span> {deliveryTime}
                  </p>
                </div>
              ) : null}
              {originCountry ? (
                <div className="flex items-start gap-1">
                  <p className="text-xss text-left pt-1 pb-0">
                    <span className="font-bold">Continente de origen:</span>{" "}
                    {originCountry}
                  </p>
                </div>
              ) : null}
              {brand ? (
                <div className="flex items-start gap-1">
                  <p className="text-xss text-left pt-1 pb-0">
                    <span className="font-bold">Laboratorio:</span> {brand}
                  </p>
                </div>
              ) : null}
              {slug !== "ulsatech" && (
                <div className="flex items-start gap-1">
                  <p className="text-xss text-left pt-1 pb-0">
                    <span className="font-bold">Inventario:</span>{" "}
                    {inventory?.Existencia
                      ? inventory?.Existencia
                      : inventory != 0
                      ? inventory
                      : "Sobre pedido" || "Sobre pedido"}
                  </p>
                </div>
              )}
              {urlFda ? (
                <div className="flex items-start gap-1">
                  {/* <p className="text-left pt-1 pb-0 flex justify-between items-center"> */}
                  <a
                    className="text-left pt-1 pb-0 flex justify-between items-center hover:underline"
                    href={urlFda}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="text-xss font-bold">FDA:</span>{" "}
                    <span className="px-2">
                      <FaLink size={15} color="#4889B3" />
                    </span>
                  </a>
                  {/* </p> */}
                </div>
              ) : null}
              {urlEma ? (
                <div className="flex items-start gap-1">
                  <a
                    className="text-left pt-1 pb-0 flex justify-between items-center hover:underline"
                    href={urlEma}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="text-xss font-bold">EMA:</span>{" "}
                    <span className="px-2">
                      <FaLink size={15} color="#4889B3" />
                    </span>
                  </a>
                </div>
              ) : null}
              {isDisplayExtraInfo ? (
                <>
                  {!_.isEmpty(otherInfo) &&
                    Object.keys(otherInfo)?.map((field) => {
                      return (
                        <div
                          className="flex items-start gap-1"
                          key={otherInfo?.[field]}
                        >
                          <p className="text-xss text-left pt-1 pb-0">
                            <span className="font-bold">
                              {field === "DOSAGE / STREGTH"
                                ? "Dosis"
                                : handleCapitalizedString(
                                    field?.toLocaleLowerCase()
                                  )}
                              :
                            </span>{" "}
                            {otherInfo?.[field]}
                          </p>
                        </div>
                      );
                    })}
                </>
              ) : null}
            </div>
          </>
        }
      />
      {/* {inventory?.Existencia || inventory != 0 ? ( */}
      {price && (inventory?.Existencia || inventory != 0) ? (
        <>
          <div className="h-10" />
          <div className="absolute bottom-2 m-auto left-0 right-0">
            <p className="text-center text-secondary font-[700] pb-1">
              {price &&
                `${Currencies[selectedCurrency?.currency]?.sign}${price}`}{" "}
            </p>
            <div className="text-center">
              <button
                className="bg-secondary text-white px-2 py-1 rounded-md text-sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onPressCartIcon(medicine);
                  addToCart(medicine);
                }}
              >
                Comprar
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* <div className="py-2" /> */}
          <div className="h-10" />
          <div className="absolute bottom-2 m-auto left-0 right-0">
            <p className="text-center text-secondary font-[700] pb-1">
              {price &&
                `${Currencies[selectedCurrency?.currency]?.sign}${price}`}{" "}
            </p>
            <div className="text-center">
              <button
                className="bg-secondary text-white px-2 py-1 rounded-md text-sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onPressCartIcon(medicine);
                  addToCart(medicine);
                  // handleNavigateToWhatsapp()
                }}
              >
                Cotizar
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SharedMedicineItem;
