import Select from "react-select";
import { convertToSentenceCase } from "../../../utils";

const SelectDropDown = ({ label, options, value, onChange }) => {
  options = options?.map((item) => ({
    value: item?.value,
    label: convertToSentenceCase(item?.label),
  }));
  return (
    <div className="xs:pr-4 xs:border-r-2 xs:border-gray-200">
      <p className="font-[600] p-0">{label}</p>

      <Select
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        options={options}
        placeholder="Seleccionar"
        value={
          value
            ? {
                value: value,
                label: value,
              }
            : null
        }
        onChange={(e) => onChange(e.value)}
      />
    </div>
  );
};

export default SelectDropDown;
