import { getObjectId } from "../utils";

class CartHelper {
  getAlreadyProductAddedIndex = (cart, product) => {
    return cart?.findIndex(
      (medicine) => getObjectId(medicine) === getObjectId(product)
    );
  };
  getAddedProduct = (cart, product) => {
    return cart?.find(
      (medicine) => getObjectId(medicine) === getObjectId(product)
    );
  };
  isProductAddedToCart = (cart, product) => {
    const index = this.getAlreadyProductAddedIndex(cart, product);
    return index >= 0;
  };
}

export default new CartHelper();
