import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import HeroSectionImage from "../../assets/images/hero-section-img.webp";
import PillImage from "../../assets/images/pill-img.webp";
import PillButton from "../Shared/PillButton/PillButton";

const HeroSection = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      navigate(`/buscar?searchFilter=${searchValue}`);
    }
  };

  const navigateToSearchTerm = (searchTerm) => {
    navigate(`/buscar?searchFilter=${searchTerm}`);
  };

  useEffect(() => {
    const pillImg = new Image();
    pillImg.src = PillImage;
    const herosectionImg = new Image();
    herosectionImg.src = HeroSectionImage;
  }, []);

  const famousMedicines = [
    "Paracetamol",
    "Ibuprofeno",
    "Amoxicilina",
    "Omeprazol",
    "Metformina",
    "Loratadina",
    "Simvastatina",
    "Dipirona",
    "Salbutamol",
    "Clorfeniramina",
  ];

  return (
    <div className="my-6 px-10 py-12 rounded-2xl relative" id="hero-section">
      <div className="absolute bottom-[-190px] right-0 xs:block hidden">
        <img src={PillImage} alt="pill" className="w-4/5" loading="lazy" />
      </div>
      <div className="flex items-center flex-col lg:flex-row">
        <div className="flex-[2]">
          <h1 className="text-2xl sm:text-4xl font-[600] text-primary">
            Vooyfarma, la manera más fácil de encontrar tu medicamento ideal al
            mejor precio en México
          </h1>
          <p className="text-light my-4 sm:max-w-[90%] sm:text-xl">
            Encuentra todo lo que compras en una farmacia. Desde algo para el
            dolor de cabeza hasta medicamentos especializados de cáncer,
            neurología, hepatitis, y más.
          </p>
          <div className="flex items-center gap-2 rounded-lg bg-white px-4 py-4 sm:max-w-[70%]">
            <CiSearch className="text-xl" />
            <input
              type="text"
              placeholder="Buscador de medicamentos"
              className="bg-transparent focus:outline-none w-full"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="flex my-6 items-center gap-4 flex-wrap">
            <span className="font-bold">Popular: </span>
            <div className="flex items-center gap-2 flex-wrap">
              {famousMedicines.map((value) => (
                <PillButton onClick={() => navigateToSearchTerm(value)}>
                  {/* Aspirinina */}
                  {value}
                </PillButton>
              ))}


            </div>
          </div>
        </div>
        <div className="flex-1">
          <img
            src={HeroSectionImage}
            width="100%"
            alt="Aspirina omeprazol metformina"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
