import React from "react";
// import placeHolderLogo from "../../assets/images/logo.webp";
// import moment from "moment";
// import { paragraph } from "../../utils";

const IGCard = ({
  username,
  avatar,
  userId,
  media_type,
  media_url,
  permalink,
  timestamp,
  caption,
  duration,
}) => {
  //   console.log("media_url >>", media_url);
  const renderMediaFromUrl = () => {
    if (media_type === "IMAGE") {
      return (
        <img
          //   className="w-full bg-cover"
          className="object-cover aspect-square filter hover:filter-none rounded-sm transition duration-200"
          src={media_url}
          alt="Post"
          //   width="350"
          //   height="410"
        />
      );
    } else if (media_type === "VIDEO") {
      return (
        <video
          // width="350" height="410"
          controls
          //   className="w-full bg-cover"
          className="object-cover aspect-square filter hover:filter-none rounded-sm transition duration-200"
        >
          <source src={media_url} />
          Your browser does not support the video tag.
        </video>
      );
    }
  };

  return (
    <div
      className="flex-1 relative bg-white rounded-lg cursor-pointer transition-all ease-in duration-300 hover:shadow-md"
      data-aos="fade-up"
      data-aos-delay={duration}
    >
      <a href={permalink} target="_blank" rel="noreferrer noopener">
        {renderMediaFromUrl()}
      </a>
    </div>
  );
};

export default IGCard;

