import React from "react";
import lisfarmaLogo from "../../assets/images/lisfarma-images/lisfarma-logo.png";
import googlePlayStoreIcon from "../../assets/images/lisfarma-images/google-play-store.png";
import applyStoreIcon from "../../assets/images/lisfarma-images/apple-store.png";
import lisfarmaQrCode from "../../assets/images/lisfarma-images/lisfarma-qr-code.png";

const lisfarmaConsultationURL = "https://formulario.lisfarmaapp.com/";

const SharedMedicineBanner = () => {
  return (
    <div className="bg-white flex justify-center items-center mt-3 mb-5">
      <div className="bg-white w-[992px]">
        <div className="w-full flex flex-wrap py-2">
          <div className="w-full md:w-9/12 xl:w-9/12 2xl:w-9/12 flex items-center flex-wrap">
            <div className="w-full sm:w-2/12 flex justify-center items-center">
              <img
                src={lisfarmaQrCode}
                alt="lisfarma qr code"
                style={{ height: "100px" }}
              />
            </div>
            <div className="w-full sm:w-10/12  flex justify-center">
              <div className="w-auto items-start justify-center">
                <div className="lisfarma-text hidden sm:block">
                  <p className="p-0 m-0 text-[#0A1B3C] md:text-sm lg:text-xl xl:text-xl font-extrabold">
                    Accede a tu tratamiento en cualquier país del mundo,
                  </p>
                  <p className="p-0 m-0 text-[#0A1B3C] md:text-md lg:text-xl xl:text-2xl font-extrabold">
                    Lisfarma gestiona tu permiso de importación.
                  </p>
                </div>
                <div className="block sm:hidden">
                  <p className="p-0 m-0 text-[#0A1B3C] md:text-sm lg:text-xl xl:text-xl font-extrabold text-center">
                    Accede a tu tratamiento en cualquier país del mundo,
                    Lisfarma gestiona tu permiso de importación.
                  </p>
                </div>
                <div className="lisfarma-soruce flex justify-center sm:justify-start gap-2">
                  <div>
                    <a
                      href="https://apps.apple.com/mx/app/lisfarma/id6448763767"
                      target={"_blank"}
                    >
                      <img
                        src={applyStoreIcon}
                        alt="Apply Store Icon"
                        style={{ height: "40px" }}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.lisfarma"
                      target={"_blank"}
                    >
                      <img
                        src={googlePlayStoreIcon}
                        alt="Google Play Store Icon"
                        style={{ height: "40px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/12 xl:w-3/12 2xl:w-3/12 flex flex-col justify-center">
            <img
              src={lisfarmaLogo}
              alt="lisfarma logo"
              className="object-contain"
              width={200}
            />
            <a
              href={lisfarmaConsultationURL}
              target="_blank"
              className="underline text-center"
            >
              Solicitud de permisos
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedMedicineBanner;
