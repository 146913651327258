import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Link, 
  // useNavigate
 } from "react-router-dom";
import { baseURL } from "../../utils/baseURL";
import MedicineItem from "./MedicineItem";
import { clearLogs } from "../../utils";

const MedicineSection = () => {
  const { token } = useSelector((state) => state?.auth);

  // const navigate = useNavigate();
  const [medicines, setMedicines] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMedicines = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/pharmacy?isFeatured=true&pageSize=30&pageIndex=0`,
          // `${baseURL}/pharmacy?pageSize=10&pageIndex=0&isFeatured=true`,
          // `${baseURL}/pharmacy?pageSize=10&pageIndex=0`,
          token && {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // const slicedResponse = [
        //   ...response.data.pharmacies.slice(0, 7),
        //   response.data.pharmacies[8],
        // ];
        // setMedicines(slicedResponse);
        setMedicines(response.data.pharmacies);
        setLoading(false);
      } catch (error) {
        console.log("error from catch block >>>", error);
      }
    };
    fetchMedicines();
  }, [token]);

  clearLogs();

  return (
    <div className="py-6">
      <h2 className="text-2xl sm:text-4xl font-[600] text-primary">
        Más medicamentos por descubrir
      </h2>
      {!loading && (
        <div>
          <div className="my-6 grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
            {medicines.map((medicine) => (
              <MedicineItem
                key={medicine.id}
                name={medicine.name}
                pharmacyName={medicine.pharmacyName}
                categoryName={medicine.category}
                price={medicine.price}
                amount={medicine?.amount}
                image={medicine.image}
                id={medicine.id}
                isFavourite={medicine?.isFavourite || false}
                medicineSlug={medicine?.medicineSlug}
                categorySlug={medicine?.categorySlug}
              />
            ))}
          </div>
          <Link
            to={`/buscar?searchFilter=`}
            className="flex text-secondary items-center justify-center gap-2 font-[600] transition-all duration-300 ease-in cursor-pointer hover:underline mt-8 w-fit mx-auto"
            // onClick={() => navigate(`/buscar?searchFilter=`)}
          >
            Ver todos <BsArrowRight className="text-2xl font-[600]" />
          </Link>
        </div>
      )}
      {loading && (
        <div
          className="flex justify-center items-center h-72"
          data-aos="zoom-in"
        >
          <Oval
            height={50}
            width={50}
            color="rgba(51, 124, 171, 1)"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="rgba(51, 124, 171, 1)"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </div>
  );
};

export default MedicineSection;
