import React from "react";
import IndivStep from "./IndivStep";

import { ReactComponent as DescriptionIcon } from "../../assets/svgs/description.svg";
import { ReactComponent as SensorIcon } from "../../assets/svgs/sensor.svg";
import { ReactComponent as AgentIcon } from "../../assets/svgs/support-agent.svg";
import { ReactComponent as TvIcon } from "../../assets/svgs/tv.svg";

const STEPS_DATA = [
  {
    id: 1,
    title: "Haz la búsqueda",
    description: "Ingresa el medicamento que necesitas en la barra de búsqueda",
    icon: <DescriptionIcon width={40} height={40} />,
    duration: 500,
  },
  {
    id: 2,
    title: "Al mejor precio",
    description:
      "Observa y filtra las opciones de los resultados. Selecciona el que más te convenga",
    icon: <SensorIcon width={40} height={40} />,
    duration: 1000,
  },
  {
    id: 3,
    title: "Encuentra tu medicamento ideal",
    description:
      "Sistema con capacidad de búsqueda y actualización continua para encontrar lo que quieres",
    icon: <AgentIcon width={40} height={40} />,
    duration: 1500,
  },
  {
    id: 4,
    title: "Cómodo y seguro",
    description:
      "Encontramos tu medicamento ideal con los requerimientos solicitados, selecciona la farmacia en nuestra plataforma y te guiamos hasta la farmacia de tu elección.",
    icon: <TvIcon width={40} height={40} />,
    duration: 2000,
  },
];

const StepsSection = () => {
  return (
    <div className="py-6">
      <div data-aos="fade-up">
        <h3 className="text-2xl sm:text-4xl font-[600] text-primary text-center">
          ¿Cómo encontrar tu medicamento ideal al mejor precio y más cerca de ti?
        </h3>
        <p className="mt-6 text-lg text-light text-center sm:max-w-[50%] mx-auto">
          Para buscar un medicamento en Vooy Farma, tan solo realiza la
          búsqueda, encuentra el medicamento que necesitas y realiza tu compra.
        </p>
      </div>
      <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-6 my-10">
        {STEPS_DATA.map((step) => (
          <IndivStep key={step.id} {...step} />
        ))}
      </div>
    </div>
  );
};

export default StepsSection;
