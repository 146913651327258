export const customStyles = {
  content: {
    backgroundColor: "#fff",
    height: "fit-content",
    margin: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

export const reactSelectStyleWithoutZIndex = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "1px solid #ccc" : "1px solid #ccc",
    borderRadius: 5,
    backgroundColor: "transparent",
    width: "100%",
    paddingTop: 1, // Adjust padding as needed (e.g., 2px, 8px)
    paddingBottom: 1,
    fontSize: 12,
  }),
  menu: (provided) => ({
    ...provided,
    border: "none",
    padding: 0,
    fontSize: 12,
  }),
  option: (provided, state) => ({
    ...provided,
    paddingTop: 2, // Adjust padding for individual options
    paddingBottom: 2,
    fontSize: 12,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 99999 }),
};

export const reactSelectStyleWithoutBorder = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "1px solid #ccc" : "none",
    backgroundColor: "transparent",
    width: "100%",
  }),
  menu: (provided) => ({
    ...provided,
    border: "none",
    // backgroundColor: "transparent",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 99999 }),
};

export const reactSelectStyleDefault = {
  menuPortal: (base) => ({ ...base, zIndex: 99999 }),
};