import React, { useEffect, useState } from "react";

import { customStyles } from "../../utils/style";
import { AiOutlineClose } from "react-icons/ai";
import Contact from "../Contact/Contact";
import Modal from "react-modal";

const ContactForm = ({ isOpenContactForm, closeContactForm }) => {
  return (
    <Modal
      isOpen={isOpenContactForm}
      onRequestClose={closeContactForm}
      style={customStyles}
      contentLabel="SignupModal"
    >
      <button className="absolute top-6 right-6" onClick={closeContactForm}>
        <AiOutlineClose className="text-2xl" />
      </button>

      <div className="overflow-auto">
        <div className="pt-2">
          <h1 className="text-2xl font-[600] text-primary text-center">
            Contacto
          </h1>
        </div>
        <div className="mx-6 ">
          <Contact isSearchpage={true} closeContactForm={closeContactForm} />
        </div>
      </div>
    </Modal>
  );
};

export default ContactForm;
