export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};
export const removeUserAndTokenFromLocalStorage = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  return;
};

export const getUserFromLocalStorage = () => {
  const result = {
    user: localStorage.getItem("user"),
    token: localStorage.getItem("token"),
  };
  const user = result.user ? JSON.parse(result.user) : null;
  const token = result.token ? result.token : null;
  // return user;
  return {
    user,
    token,
  };
};

export const addValueToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getValueFromLocalStorage = (key) => {
  const result = localStorage.getItem(key);
  const values = result ? JSON.parse(result) : null;
  return values;
};
