import userRequest from "../utils/requestMethods";

class DoctorService {
  static getListGroupedData = async ({
    search,
    groupedField,
    arrField,
    pageSize,
    pageIndex,
  }) => {
    try {
      const { data } = await userRequest.get("/doctor/list-categorised-data", {
        params: {
          search,
          groupedField,
          pageIndex,
          pageSize,
          arrField,
        },
      });
      return data;
    } catch (err) {
      return [];
    }
  };
}

export default DoctorService;
