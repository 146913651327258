import { AsyncPaginate } from "react-select-async-paginate";
// import { loadOptions } from "./loadOptions.js";

const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
  const bottomBorder = (scrollHeight - clientHeight) / 2;

  return bottomBorder < scrollTop;
};

const ReactSelectPaginate = ({
  value,
  onChange,
  loadOptions,
  style = null,
  className = null,
}) => {
  return (
    <AsyncPaginate
      menuPortalTarget={document.body}
      styles={
        style ? style : { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
      }
      className={className ? className : ""}
      // value={value}
      value={
        value
          ? {
              value: value,
              label: value,
            }
          : null
      }
      loadOptions={loadOptions}
      // onChange={onChange}
      onChange={(e) => onChange(e.value)}
      debounceTimeout={800}
      //   onChange={setValue}
      shouldLoadMore={shouldLoadMore}
      additional={{
        page: 0,
      }}
    />
  );
};

export default ReactSelectPaginate;
