import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BsChevronRight } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as ArchiveFill } from "../../assets/svgs/archive-fill.svg";
import { ReactComponent as Archive } from "../../assets/svgs/archive.svg";
import avatarThumbnail from "../../assets/images/avatar/avatar-thumbnail.webp";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../../utils/baseURL";
import { Currencies } from "../../constants";
import { CurrencyContext } from "../../context/CurrencyProvider";
import ReadMore from "../Shared/ReadMore/ReadMore";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { clearLogs, getCleanedUrl } from "../../utils";
import PhoneNumberMasker from "../Shared/PhoneNumberMasker/PhoneNumberMasker";
import EmailMasker from "../Shared/EmailMasker/EmailMasker";
import { capitalize } from "lodash";

const phoneNumberSvg = (
  <svg
    className="w-6 h-5 text-[#374151]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 19 18"
  >
    <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
  </svg>
);

const emailSvg = (
  <svg className="h-6 w-6 fill-current" viewBox="0 0 512 512">
    <path d="M437.332 80H74.668C51.199 80 32 99.198 32 122.667v266.666C32 412.802 51.199 432 74.668 432h362.664C460.801 432 480 412.802 480 389.333V122.667C480 99.198 460.801 80 437.332 80zM432 170.667L256 288 80 170.667V128l176 117.333L432 128v42.667z" />
  </svg>
);

const locationSvg = (
  <svg className="h-6 w-6 fill-current" viewBox="0 0 512 512">
    <path d="M256 32c-88.004 0-160 70.557-160 156.801C96 306.4 256 480 256 480s160-173.6 160-291.199C416 102.557 344.004 32 256 32zm0 212.801c-31.996 0-57.144-24.645-57.144-56 0-31.357 25.147-56 57.144-56s57.144 24.643 57.144 56c0 31.355-25.148 56-57.144 56z" />
  </svg>
);

const specialitySvg = (
  <svg className="h-6 w-6 fill-current" viewBox="0 0 512 512">
    <path d="M239.208 343.937c-17.78 10.103-38.342 15.876-60.255 15.876-21.909 0-42.467-5.771-60.246-15.87C71.544 358.331 42.643 406 32 448h293.912c-10.639-42-39.537-89.683-86.704-104.063zM178.953 120.035c-58.479 0-105.886 47.394-105.886 105.858 0 58.464 47.407 105.857 105.886 105.857s105.886-47.394 105.886-105.857c0-58.464-47.408-105.858-105.886-105.858zm0 186.488c-33.671 0-62.445-22.513-73.997-50.523H252.95c-11.554 28.011-40.326 50.523-73.997 50.523z" />
    <g>
      <path d="M322.602 384H480c-10.638-42-39.537-81.691-86.703-96.072-17.781 10.104-38.343 15.873-60.256 15.873-14.823 0-29.024-2.654-42.168-7.49-7.445 12.47-16.927 25.592-27.974 34.906C289.245 341.354 309.146 364 322.602 384zM306.545 200h100.493c-11.554 28-40.327 50.293-73.997 50.293-8.875 0-17.404-1.692-25.375-4.51a128.411 128.411 0 0 1-6.52 25.118c10.066 3.174 20.779 4.862 31.895 4.862 58.479 0 105.886-47.41 105.886-105.872 0-58.465-47.407-105.866-105.886-105.866-37.49 0-70.427 19.703-89.243 49.09C275.607 131.383 298.961 163 306.545 200z" />
    </g>
  </svg>
);

const DocotrItem = ({
  image,
  name,
  url,
  id,
  doctor,
  myFavouritePage = false,
  setIsLoginModalOpen,
}) => {
  clearLogs();
  const { token } = getUserFromLocalStorage();
  const handleLoginModal = () => setIsLoginModalOpen(true);
  const navigate = useNavigate();

  const schema = {
    "@context": "https://schema.org",
    "@type": "MedicalOrganization",
    name: name,
    image: image,
    description: name,
    address: {
      "@type": "PostalAddress",
      streetAddress: "Doctor's Street Address",
      addressLocality: doctor?.city,
      addressRegion: doctor?.state,
      postalCode: "Zip Code",
      addressCountry: doctor?.country,
    },
    telephone: doctor?.phoneNumberDetail
      ?.map((item) => item?.phone)
      ?.join(", "),
    email: doctor?.email?.join(", "),
    // sameAs: ["URL to Doctor's Website or Social Media Profiles"],
    medicalSpecialty: doctor?.speciality?.join(", "),
  };

  const navigateToDoctorDetail = () =>
    navigate(
      `/doctores/${getCleanedUrl(doctor?.name)}?city=${getCleanedUrl(
        doctor?.city
      )}&speciality=${doctor?.speciality?.map(getCleanedUrl).join("-")}`
    );

  clearLogs();
  return (
    <>
      <div
        key={doctor}
        className="relative h-full cursor-pointer ease-in hover:shadow-md"
        onClick={navigateToDoctorDetail}
      >
        <Helmet>
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
        <div className="flex flex-col justify-between h-full">
          <div className="rounded-lg overflow-hidden mt-2 max-h-full ">
            <div className="flex items-center justify-center w-full">
              <img
                className="w-32 h-32 mb-3 rounded-full shadow-lg object-cover"
                src={image || avatarThumbnail}
                alt="avatar"
              />
            </div>
            <div className="py-1 px-6">
              <h2 className="text-md font-semibold text-gray-800 text-center">
                {capitalize(name)}
              </h2>
              {doctor?.speciality?.length ? (
                <div className="flex items-center mt-2 text-gray-700">
                  <div className="h-6 w-6">{specialitySvg}</div>
                  <div className="px-1 text-xs">
                    {doctor?.speciality
                      ?.map((speciality) => capitalize(speciality))
                      ?.join(", ")}
                  </div>
                </div>
              ) : null}
              {/* {doctor.country && doctor.city ? ( */}
              <div className="flex items-center mt-2 text-gray-700">
                <div className="h-6 w-6">{locationSvg}</div>
                <div className="px-1 text-xs">
                  {doctor.city ? capitalize(doctor.city) + ", " : null}{" "}
                  {capitalize(doctor.country)}
                </div>
              </div>
              {/* ) : null} */}
              {doctor?.email?.length ? (
                <div className="flex items-center mt-2 text-gray-700">
                  <div className="h-6 w-6">{emailSvg}</div>
                  <div className="px-1 text-xs">
                    {/* {doctor?.email?.join(", ") || "-"} */}
                    {token ? (
                      <div className="px-1 ">{doctor?.email?.join(", ")}</div>
                    ) : (
                      <div className="px-1 flex flex-col">
                        <div className="flex flex-wrap">
                          {doctor?.email?.map((item, index) => (
                            <React.Fragment key={index}>
                              <EmailMasker email={capitalize(item)} />
                            </React.Fragment>
                          ))}
                        </div>
                        <div>
                          <button
                            className="text-secondary"
                            onClick={handleLoginModal}
                          >
                            <small>Register to view full email</small>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {doctor?.phoneNumber?.length ? (
                <div className="flex items-center mt-2 text-gray-700">
                  <div className="h-6 w-6">{phoneNumberSvg}</div>
                  <div className="px-2 text-xs">
                    {token ? (
                      <div className="px-1">
                        {doctor?.phoneNumber?.join(", ")}
                      </div>
                    ) : (
                      <div className="p-1 flex flex-col">
                        <div className="flex flex-wrap">
                          {doctor?.phoneNumber?.map((item, index) => (
                            <React.Fragment key={index}>
                              <PhoneNumberMasker phoneNumber={item} />
                              {index !== doctor.phoneNumber.length - 1 && ", "}
                            </React.Fragment>
                          ))}
                        </div>

                        <div>
                          <button
                            className="text-secondary"
                            onClick={handleLoginModal}
                          >
                            <small>Register to view full number</small>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* <div className="text-center pb-1">
            <Link
              className="text-xs text-indigo-500 italic cursor-pointer hover:underline hover:text-indigo-600 font-medium"
              href={url}
              to={`/doctores/${getCleanedUrl(
                doctor?.name
              )}?city=${getCleanedUrl(
                doctor?.city
              )}&speciality=${doctor?.speciality
                ?.map(getCleanedUrl)
                .join("-")}`}
              // to={`/doctores/${getCleanedUrl(doctor?.name)}?speciality=${doctor?.speciality?.join("-")}&city=${getCleanedUrl(doctor?.city)}`}
              state={id}
            >
              Ver perfil
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DocotrItem;
