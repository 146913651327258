import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  metaTags: {
    title: "Vooy Farma | Buscador de medicamentos en México",
    description:
    "En Vooy Farma hacemos la búsqueda de medicamentos en varios sitios de farmacias de México por ti",
    keywords: "",
    robots: "",
  },
};

const metaSlice = createSlice({
  name: "metaTags",
  initialState,
  reducers: {
    updateMetaTags: (state, action) => {
      state.metaTags = action.payload;
    },
  },
});

export const { updateMetaTags } = metaSlice.actions;

export default metaSlice.reducer;
