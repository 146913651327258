import React from "react";

import Pharmacy1 from "../../assets/images/pharmacy1.webp";
import Pharmacy2 from "../../assets/images/pharmacy2.webp";
import Pharmacy3 from "../../assets/images/pharmacy3.webp";
import Pharmacy4 from "../../assets/images/pharmacy4.webp";
import Pharmacy5 from "../../assets/images/pharmacy5.webp";
import Pharmacy6 from "../../assets/images/pharmacy6.webp";
import Pharmacy7 from "../../assets/images/pharmacy7.webp";

const ListedPharmacies = () => {
  return (
    <div className="py-6">
      <h2 className="text-2xl sm:text-4xl font-[600] text-primary w-9/12">
        Buscamos, comparamos y encontramos tu medicamento ideal más cerca de ti.
      </h2>
      <div
        className="flex items-center gap-2 my-10 overflow-auto md:overflow-hidden"
        id="pharmacies-container"
        data-aos="fade-up"
      >
        <div className="flex gap-4 md:gap-2">
          <div className="w-max md:w-auto">
            <img src={Pharmacy1} alt="super farmacia logo" />
          </div>
          <div className="w-max md:w-auto">
            <img src={Pharmacy2} alt="yza farmacia logo" />
          </div>
          <div className="w-max md:w-auto">
            <img src={Pharmacy3} alt="farmacias benavides logo" />
          </div>
          <div className="w-max md:w-auto">
            <img src={Pharmacy4} alt="biologicos especializados logo" />
          </div>
          <div className="w-max md:w-auto">
            <img src={Pharmacy5} alt="heb logo" />
          </div>
          <div className="w-max md:w-auto">
            <img src={Pharmacy6} alt="la miniatura farmacia logo" />
          </div>
          <div className="w-max md:w-auto">
            <img src={Pharmacy7} alt="san pablo farmacia logo" />
          </div>
        </div>
        <div className="cursor-pointer">
          <span className="text-light transition-all duration-200 ease-in hover:underline">
            +100 más
          </span>
        </div>
      </div>
    </div>
  );
};

export default ListedPharmacies;
