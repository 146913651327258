import React, { useEffect, useState } from "react";
import IGCard from "./IGCard";
import userRequest from "../../utils/requestMethods";
import { Oval } from "react-loader-spinner";
import placeHolderLogo from "../../assets/images/logo.webp";
import { FaInstagram } from "react-icons/fa";

const IGFeedSection = () => {
  const [feedData, setFeedData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchIGFeeds = async () => {
      try {
        const response = await userRequest.get(`/feed/ig-feed`);
        if (response) {
          setFeedData(response.data?.data);
        } else {
          setFeedData([]);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setFeedData([]);
        console.log("error from catch block >>>", error);
      }
    };
    fetchIGFeeds();
  }, []);

  return (
    <div className="py-6" data-aos="fade-up">
      {/* <div data-aos="fade-up">
        <h3 className="text-2xl sm:text-4xl font-[600] text-primary text-center">
        Fuentes de Instagram
        </h3>
      </div> */}
      {!loading && feedData && (
        <>
          <a
            href="https://www.instagram.com/vooyfarma/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center xl:px-1 xl:pt-2 md:px-4 md:pt-2 w-10/12">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full border brightness-50 contrast-125"
                  alt="ig-logo"
                  src={placeHolderLogo}
                />
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                  <FaInstagram color="#fff" />
                </div>
              </div>
              <div className="ml-3 ">
                <span className="text-xl font-semibold antialiased block leading-tight text-start">
                  vooyfarma
                </span>
                <span className="text-gray-600 text-xs block">
                  Mexico City, Mexico
                </span>
              </div>
            </div>
          </a>
          <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-6 mt-6 mb-10">
            {feedData.map((feed) => (
              <IGCard key={feed.id} {...feed} duration={500} />
            ))}
          </div>
        </>
      )}

      {loading && (
        <div
          className="flex justify-center items-center h-72"
          data-aos="zoom-in"
        >
          <Oval
            height={50}
            width={50}
            color="rgba(51, 124, 171, 1)"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="rgba(51, 124, 171, 1)"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </div>
  );
};

export default IGFeedSection;

// const FEED_DATA = [
//   {
//     id: "18023133175595525",
//     username: "vooyfarma",
//     caption:
//       "Prueba nuestra aplicación y obtén todos los beneficios. Ahorra tiempo ⏱️ y dinero💲. Vooyfarma tú buscador de medicamentos 🫰🏻📲\n.\n#farmacia #buscador #app #ai #vooyfarma #gratis #medicine #health",
//     media_type: "IMAGE",
//     media_url:
//       "https://scontent.cdninstagram.com/v/t51.29350-15/361926284_809383603983781_4774713941835969082_n.webp?stp=dst-jpg&_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=mphfv3YnajkAX9stow4&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCHtM_IM6wu8r3RVNoMXL_QsjJMcJ6YZJp0l5z54JkkUQ&oe=64BDBE85",
//     permalink: "https://www.instagram.com/p/Cu7Wvz1u4Sr/",
//     timestamp: "2023-07-20T17:23:33+0000",
//   },
//   {
//     id: "17975459567415106",
//     username: "vooyfarma",
//     caption:
//       "🚩Señales de alerta de un infarto🔎.\n#teamvooy #vooyfarma #app #heartattack",
//     media_type: "IMAGE",
//     media_url:
//       "https://scontent.cdninstagram.com/v/t51.29350-15/361213581_770555021529844_4235010633300909462_n.webp?stp=dst-jpg&_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Cp9V3EWVqqIAX_HJBOx&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfA3YbsOoegmaOH1oGw-mDrkxLTQjRoHChCYDxnc1fpyGQ&oe=64BE0068",
//     permalink: "https://www.instagram.com/p/Cu2Y8exPPCX/",
//     timestamp: "2023-07-18T19:06:34+0000",
//   },
//   {
//     id: "17993742200081969",
//     username: "vooyfarma",
//     caption:
//       "❤️Sabemos que tú salud es lo más importante.🔎Busca tus medicamentos al mejor precio de la manera más fácil y rápida. ✅Somos #vooyfarma , descarga nuestra App📲.\n.\n#ai #farmacia #buscador #medicina #salud #app #ia",
//     media_type: "IMAGE",
//     media_url:
//       "https://scontent.cdninstagram.com/v/t51.29350-15/360999429_809420207220669_8495633076475842101_n.webp?stp=dst-jpg&_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=QA_6Gc6D8CYAX-5KXq9&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAAgjsKgLWQKSvHsapP0vw99ja2kg280uYPU_dWEL_0Hw&oe=64BF0D3C",
//     permalink: "https://www.instagram.com/p/Cu0MfVjpvfo/",
//     timestamp: "2023-07-17T22:39:15+0000",
//   },
//   {
//     id: "18216680992222817",
//     username: "vooyfarma",
//     caption:
//       "Tú salud es lo más importante, nosotros cuidamos de tí❤️‍🩹 #teamvooy #farmacia #cancerdepiel #protectorsolar",
//     media_type: "IMAGE",
//     media_url:
//       "https://scontent.cdninstagram.com/v/t51.29350-15/361168745_6514702521957627_1601357108426680286_n.webp?stp=dst-jpg&_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=cgFXCRUbV9IAX9Nit5g&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCGP6diJWyJOYYvfTdR5ppFb7pnNt_aZW_0ix9miK246g&oe=64BDED29",
//     permalink: "https://www.instagram.com/p/CuzmzmPOetE/",
//     timestamp: "2023-07-17T17:09:58+0000",
//   },
//   {
//     id: "17981170814129449",
//     username: "vooyfarma",
//     caption:
//       "Buscar tú medicamento al mejor precio ahora es muy fácil 🤑💲Descarga nuestra App #vooyfarma y comienza ahorrando.\n#vooyfarma #ahorrar #preciosbajos #farmacia",
//     media_type: "IMAGE",
//     media_url:
//       "https://scontent.cdninstagram.com/v/t51.29350-15/360100440_1087053985603807_1588038057158686800_n.webp?stp=dst-jpg&_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=mym28SwRYHoAX-TFjuf&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBwvb2VoWpZ5gFjTMh_E6RSDkVgU_FLtwXa2lRE6a2N6A&oe=64BEA55A",
//     permalink: "https://www.instagram.com/p/CuxLfJCJWVH/",
//     timestamp: "2023-07-16T18:32:45+0000",
//   },
//   {
//     id: "17991525380306863",
//     username: "vooyfarma",
//     caption:
//       "Hoy en día Miles de personas sufren de ansiedad❤️‍🩹pide ayuda si lo necesitas🩹No estás solx🫡\n#teamvooy #vooyfarma #ansiedad #saludmental",
//     media_type: "IMAGE",
//     media_url:
//       "https://scontent.cdninstagram.com/v/t51.29350-15/360049470_7476948432333876_7132567374933596173_n.webp?stp=dst-jpg&_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Hp9yAq_LmdUAX9zZ0wx&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCiaBKZoMabEjL67cIw7d_2paFSSsb8nOM6N5xi071IvQ&oe=64BE3405",
//     permalink: "https://www.instagram.com/p/Cuu8XEaJGF_/",
//     timestamp: "2023-07-15T21:42:06+0000",
//   },
//   {
//     id: "18044085814473718",
//     username: "vooyfarma",
//     caption:
//       "👋🏻Descarga nuestra App 📲 es #gratis disponible en App store y Google Play 🟢\n#vooyfarma",
//     media_type: "IMAGE",
//     media_url:
//       "https://scontent.cdninstagram.com/v/t51.29350-15/360035377_967749487825637_7714266804569625251_n.webp?stp=dst-jpg&_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=C1VDXabswF0AX8z7rsB&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCKoJ0BJQdkFe5vkJDFW1VH2LoI_4aPxEEM_BDohPZAEQ&oe=64BE3D2F",
//     permalink: "https://www.instagram.com/p/CuuX50KuWEm/",
//     timestamp: "2023-07-15T16:23:32+0000",
//   },
//   {
//     id: "17964959993538016",
//     username: "vooyfarma",
//     caption:
//       "🌡️Cuidemonos de las altas temperaturas 🥵Busca🔎tus electrolitos y protectores solares favoritos con nuestra app📲 y encuentralos al mejor precio #vooyfarma\n\n#medicina #farmacia #buscador #ia #pharmacy #app",
//     media_type: "IMAGE",
//     media_url:
//       "https://scontent.cdninstagram.com/v/t51.29350-15/359801489_280768854446081_7752050569211193540_n.webp?stp=dst-jpg&_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=4OJvdHH58GoAX-wBWdI&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDAmv0XFI3RAzGqqr9u_RPJRTEZovJcZhXhlMDV9K1dXA&oe=64BE9799",
//     permalink: "https://www.instagram.com/p/Cur_nBKpzhr/",
//     timestamp: "2023-07-14T18:12:46+0000",
//   },
// ];
