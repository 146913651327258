import React from "react";
import Select from "react-select";
import { convertToSentenceCase } from "../../../utils";

const ReactSelectComp = ({
  className,
  style,
  border,
  options,
  value,
  onChange,
  placeholder = "Seleccionar",
  isMulti = false,
  defaultValue,
}) => {
  options = options?.map(item =>({
    value: item?.value,
    label: convertToSentenceCase(item?.label),
  }))
  return (
    <Select
      menuPortalTarget={document.body}
      className={className}
      styles={style}
      bordered={border}
      options={options}
      value={value}
      defaultValue={defaultValue}
      onChange={(e) => onChange(e)}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  );
};

export default ReactSelectComp;
