import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Contact from "../components/Contact/Contact";
import { scrollToTop } from "../utils/scrollToTop";

const SupportPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="my-6 px-10 py-12 rounded-2xl relative lg:w-10/12 mx-auto">
      <Helmet>
        <link rel="canonical" href="https://vooyfarma.com/eliminar-mi-cuenta" />
      </Helmet>
      <div className="pb-4">
        <h1 className="text-start text-2xl sm:text-4xl font-[600] text-primary">
          Eliminar mi cuenta
        </h1>
      </div>
      <div>
        <div>
          <h2 className="text-xl font-bold">
            ¿Qué ocurre si elimino mi cuenta de Vooyfarma?
          </h2>
          <p className="py-2">
            <ul className="list-disc ml-5">
              <li>No podrás reactivar tu cuenta.</li>
              <li>
                Tu perfil, tus favoritos y todo lo que hayas agregado se
                eliminará definitivamente.
              </li>
              <li>No podrás ingresar a la app o web:</li>
            </ul>
          </p>
        </div>
        <div>
          <p className="py-1">
            <a
              href="https://play.google.com/store/apps/details?id=com.vooyfarma&pli=1"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-500 hover:underline"
            >
              Play Store:
            </a>
          </p>
          <p className="py-1">
            <a
              href="https://apps.apple.com/us/app/vooy-farma/id6446176829"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-500 hover:underline"
            >
              Apple Store:
            </a>
          </p>
          <p className="py-1">
            <a
              href="https://VooyFarma.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-500 hover:underline"
            >
              Sitio Web:
            </a>
          </p>
        </div>
        <div>
          <h2 className="text-xl font-bold">
            ¿Puedo cancelar la eliminación de mi cuenta?
          </h2>
          <p>No</p>
        </div>
        <div>
          <h2 className="text-xl font-bold">
            Obtén información acerca de cómo eliminar definitivamente tu cuenta
            de Vooyfarma.
          </h2>
          <p>
            Para eliminar definitivamente tu cuenta de Vooyfarma desde la app:
          </p>
          <ul className="list-decimal ml-5">
            <li>Haz clic en Ajustes</li>
            <li>Haz clic en Eliminar mi cuenta.</li>
            <li>
              Haz click en Si si estas segurto que quieres eliminar tu cuenta.
            </li>
          </ul>
          <p>
            Para eliminar definitivamente tu cuenta de Vooyfarma desde la web:
          </p>

          <ul className="list-decimal ml-5">
            <li>Haz clic en Mi Perfil</li>
            <li>Haz clic en Eliminar mi cuenta.</li>
            <li>
              Haz click en Si si estas segurto que quieres eliminar tu cuenta.
            </li>
          </ul>

          <p>O contáctenos para eliminar tu cuenta</p>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default SupportPage;
