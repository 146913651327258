import React, { useContext, useEffect, useRef, useState } from "react";
import {
  addValueToLocalStorage,
  getValueFromLocalStorage,
} from "../../utils/localStorage";

import { CurrencyContext } from "../../context/CurrencyProvider";
import { currenciesWithFlags } from "../../constants";

const CurrencyDropDown = ({ origin = true }) => {
  const locationDetails = getValueFromLocalStorage("locationDetails");
  const existingCurrency = getValueFromLocalStorage("currency");
  // console.log("locationDetails >>>", locationDetails);
  const [isOpen, setIsOpen] = useState(false);
  const { selectedCurrency, setSelectedCurrency } = useContext(CurrencyContext);
  // console.log("selectedCurrency >>>", selectedCurrency);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectedCurrency = (item) => {
    addValueToLocalStorage("currency", item?.currency);
    setSelectedCurrency(item);
    handleToggle();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const getUserCurrency = (currency) => {
    // console.log(typeof currency === "string");
    if (typeof currency === "string") {
      let userCurrency = currenciesWithFlags?.filter(
        (filt) =>
          filt?.currency?.toLocaleLowerCase() === currency?.toLocaleLowerCase()
      );
      if (userCurrency?.length > 0) {
        userCurrency = userCurrency?.[0];
      } else {
        userCurrency = currenciesWithFlags?.[0];
      }
      return userCurrency;
    }
  };

  useEffect(() => {
    if (existingCurrency) {
      setSelectedCurrency(getUserCurrency(existingCurrency));
    } else if (locationDetails?.currency) {
      setSelectedCurrency(getUserCurrency(locationDetails?.currency));
      addValueToLocalStorage(
        "currency",
        getUserCurrency(locationDetails?.currency)?.currency
      );
    } else {
      setSelectedCurrency(currenciesWithFlags?.[0]);
      addValueToLocalStorage("currency", "MXN");
    }
  }, []);

  useEffect(() => {
    setIsOpen(false);

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={dropdownRef} translate="no">
      <button
        id="dropdown-currency-button"
        // className="flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 focus:outline-none"
        className="flex items-center py-2 px-2 text-sm font-medium text-center text-gray-900 "
        type="button"
        onClick={handleToggle}
      >
        <span translate="no" className="pr-1">
          {selectedCurrency?.flag}
        </span>{" "}
        <span translate="no">{`${selectedCurrency?.currency}`}</span>
        {/* <span translate="no">({`${selectedCurrency?.sign}`})</span> */}
        <span className="pl-1">
          <svg
            translate="no"
            className="w-2.5 h-2.5 ms-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="m1 1 4 4 4-4"
            />
          </svg>
        </span>
      </button>
      {isOpen && (
        <div
          id="dropdown-currency"
          // className="absolute right-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow px-2.5"
          className={`z-10 bg-white divide-y divide-gray-100 rounded-lg shadow absolute ${
            origin ? "origin-bottom bottom-full" : ""
          } mt-1`}
          translate="no"
        >
          <ul
            className="py-2 text-sm text-gray-700"
            aria-labelledby="dropdown-currency-button"
            translate="no"
          >
            {currenciesWithFlags?.map((item) => {
              return (
                <li key={item.currency} translate="no">
                  <button
                    type="button"
                    className="w-full px-2 py-2 text-left text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    onClick={() => handleSelectedCurrency(item)}
                    translate="no"
                  >
                    <div
                      className="flex flex-row justify-center items-center w-full"
                      translate="no"
                    >
                      <div translate="no" className="pr-1">
                        {item?.flag}
                      </div>{" "}
                      <div translate="no">{item?.currency}</div>
                      {/* <div translate="no">({`${item?.sign}`})</div> */}
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CurrencyDropDown;
