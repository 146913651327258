import axios from "axios";
import { addValueToLocalStorage } from "../utils/localStorage";

class LocationService {
  static getLocation = async ({ latitude, longitude }) => {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10&addressdetails=1`;
      const response = await axios.get(url);
      const countryName = response?.data?.address?.country;
    //   addValueToLocalStorage("country", countryName);
      return {
        response,
        countryName,
      };
    } catch (err) {
      return null;
    }
  };
  static ipLookUp = async () => {
    try {
      const ipURL = `https://api.ipify.org/?format=json`;
      const ipResponse = await axios.get(ipURL);
      const ip = ipResponse?.data?.ip;

      if (ip) {
        const url = `https://ipapi.co/${ip}/json/`;
        const response = await axios.get(url);
        console.log("User's Location Data is ", response);
        addValueToLocalStorage("locationDetails", response?.data);
        //   const url = `http://ip-api.com/json`;
        // const response = await axios.get(url);
        // addValueToLocalStorage("locationDetails", response?.data);
        return {
          result: response?.data,
        };
      }
    } catch (err) {
      return null;
    }
  };
}

export default LocationService;
