// import momentTZ from "moment-timezone";
import _ from "lodash";

const swapArrayElements = (arr, indexA, indexB) => {
  const temp = arr[indexA];
  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
  return arr;
};
const deg2rad = (deg) => deg * (Math.PI / 180);

const calculateDistance = (lat2, lon2, lat1, lon1, withKM) => {
  if (!lat1 || !lon1 || !lat2 || !lon2) {
    return NaN;
  }

  const R = 6371;

  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = Number(`${Math.round(`${R * c}e${2}`)}e-${2}`);
  return withKM ? `(${d?.toFixed(2)} km)` : d;
};

const distance = (lat1, lon1, lat2, lon2, name) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const distance = lon1 - lon2;
  const radDistance = (Math.PI * distance) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radDistance);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (name == "K") {
    dist *= 1.609344;
  }
  if (name === "N") {
    dist *= 0.8684;
  }
  return dist;
};

const getFinalTrial = (places, coords) => {
  const locationTotal = places && places.length;
  if (places && locationTotal >= 1) {
    const trialLatitude = places?.map((el) => el?.location?.latitude);
    const trialLongitude = places?.map((el) => el?.location?.longitude);
    const distanceArray = trialLatitude.map((lat, idx) => {
      const log = trialLongitude[idx];
      return distance(coords?.latitude, coords?.longitude, lat, log);
    });
    // Above returns an Array, then find the shortest distance, and get the index of this distance
    // display this index value from Trial obJect
    const closest = Math.min(...distanceArray);
    // console.log("closest >>>", closest);
    const closestLocationIndex = distanceArray?.indexOf(closest);
    // console.log("closestLocationIndex >>>", closestLocationIndex);
    return places[closestLocationIndex];
  }
  return {};
};

const paragraph = (text) => {
  const words = text?.split(" ");
  const truncatedWords = words?.slice(0, 20);
  const truncatedText = truncatedWords?.join(" ");

  return truncatedText + "...";
};

const handleReJoinText = (text) => {
  let reJoinText = text;
  reJoinText = reJoinText?.split("-")?.join(" ");
  reJoinText = reJoinText?.split("__")?.join("-");
  reJoinText = reJoinText?.split("_")?.join("/");
  reJoinText = reJoinText?.split("___")?.join("?");
  return reJoinText;
};

const handleSplitTxt = (text) => {
  let splitText = text;
  splitText = splitText?.split(" ").join("-");
  splitText = splitText?.split("/").join("-");
  splitText = splitText?.split("?").join("___");
  return splitText;
};

const removePercentEncoding = (url) => {
  // Decode all percent-encoded characters in the URL string
  url = decodeURIComponent(url);

  // Remove any extra spaces before and after the URL string
  url = url.trim();

  return url;
};

const getCleanedUrl = (url) => {
  // const url = `/${splitPharmacyName}/${splitCategoryNameName}/${splitName}`;
  // const url = "https://www.example.com/áí/productos/televisor";
  const cleanedUrl = url
    ?.normalize("NFD") // normalize the string to decomposed form
    ?.replace(/[\u0300-\u036f]/g, "") // remove diacritical marks
    ?.replace(/[^\w\s]/gi, "") // remove non-word and non-space characters
    ?.replace(/\s+/g, "-") // replace spaces with hyphens
    ?.toLowerCase(); // convert to lowercase
  // console.log("cleanedUrl >>>", cleanedUrl); // "https://www.example.com/ai/productos/televisor"
  return cleanedUrl;
};

const getOriginalUrl = (url) => {
  // const originalUrl = url
  //   .replace(/-/g, " ") // replace hyphens with spaces
  //   .normalize("NFD") // normalize to decomposed form
  //   .replace(/[\u0300-\u036f]/g, (match) => // add back diacritical marks
  //     String.fromCharCode(match.charCodeAt(0) + 0x0300 - 0x20)
  //   )
  const originalUrl = decodeURI(url)
    .replace(/-/g, " ") // replace hyphens with spaces
    .replace(/\b\w/g, (match) => match.toUpperCase()) // capitalize first letter of each word
    .normalize("NFD") // normalize to decomposed form
    .replace(
      /[\u0300-\u036f]/g,
      (
        match // add back diacritical marks
      ) => String.fromCharCode(match.charCodeAt(0) + 0x0300 - 0x20)
    )
    .replace(/\s/g, "-"); // replace spaces with hyphens

  return originalUrl;
};
const validateEmail = (email) => {
  return /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,10}$/.test(email);
};

const convertToSentenceCase = (text) => {
  // if (text?.includes(" ")) {
  //   // For Multiple words
  //   const lowerCaseText = text?.toLowerCase();
  //   const words = lowerCaseText?.split(" ");
  //   const sentenceCaseWords = words?.map((word) => {
  //     const firstLetter = word?.charAt(0).toUpperCase();
  //     const restOfWord = word?.slice(1);
  //     return `${firstLetter}${restOfWord}`;
  //   });
  //   const sentenceCaseText = sentenceCaseWords?.join(" ");
  //   return sentenceCaseText;
  // } else {
  // For Single word
  const firstLetter = text?.charAt(0).toUpperCase();
  const restOfWord = text?.slice(1).toLowerCase();
  return `${firstLetter}${restOfWord}`;
  // }
};

// const getLocationDetailsFromTimeZone = async () => {
//   try {
//     var userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     var tzArr = userTimeZone.split("/");
//     var details = momentTZ.tz.guess(true);

//     return details;
//   } catch (err) {
//     return null;
//   }
// };

const formatDataForDropDownFilter = (
  data,
  label,
  value,
  count,
  isExistAllLabel
) => {
  let availableItems = data?.filter((item) => {
    if (item?.[label]) {
      return item;
    }
  });
  let sortList = [];
  sortList = availableItems?.map((item) => {
    return {
      label:
        item?.[label] +
        `${count ? "   (" + manualStringFormatting(item?.[count]) + ")" : ""}`,
      value: item?.[value],
    };
  });
  if (!isExistAllLabel) {
    sortList.unshift({
      label: "All",
      value: "",
    });
  }
  return sortList;
};
const formatDataWithoutCountForDropDownFilter = (
  data,
  label,
  value,
  isExistAllLabel
) => {
  let availableItems = data?.filter((item) => {
    if (item?.[label]?.length) {
      return item;
    }
  });
  let sortList = [];
  availableItems?.forEach((item) => {
    item?._id?.forEach((item) => {
      sortList.push({
        value: item,
        label: item,
      });
    });
  });
  if (!isExistAllLabel) {
    sortList.unshift({
      label: "All",
      value: "",
    });
  }
  return sortList;
};

const manualStringFormatting = (number) => {
  return number ? `${number?.toLocaleString()}` : number;
};

const getUniqueArray = (data) => {
  if (Array.isArray(data)) {
    const uniqueValues = [...new Set(data)];
    // console.log("uniqueValues >>", uniqueValues);

    const convertToLowerCase = uniqueValues?.map((value) => _.lowerCase(value));
    // console.log("convertToLowerCase >>>", convertToLowerCase);

    const removeAccent = convertToLowerCase?.map((value) => _.deburr(value));
    // console.log("removeAccent >>", removeAccent);

    const removeFalseValues = _.compact(removeAccent);

    const unique = _.chain(removeFalseValues)
      .map((item) => convertToSentenceCase(item))
      .uniq()
      .value();

    return unique;
  } else {
    return data;
  }
};

const clearLogs = () => {
  console.clear();
};

const interpolate_general = (text, array) => {
  var stringToReturn = "",
    length = text.length,
    index = 0,
    array_length = array.length;

  for (var i = 0; i < length; i++) {
    var currentChar = text[i];
    var nextChar = null;
    if (i + 1 < length) nextChar = text[i + 1];

    if (currentChar === "`" && nextChar === "%") {
      stringToReturn += "%";
      i += 1;
    } else {
      if (currentChar === "%") {
        if (index < array_length) {
          stringToReturn += array[index++];
        } else {
          stringToReturn += "";
        }
      } else {
        stringToReturn += currentChar;
      }
    }
  }
  return stringToReturn;
};

const get_key_length = (text, index) => {
  var length = text.length;

  var key = "";

  var i = index;
  while (i < length && text[i] !== "}") {
    key += text[i];
    i++;
  }

  if (text[i] === "}") key += "}";
  else throw Error("SYNTAX ERROR");

  return [key.slice(2, key.length - 1), key.length];
};

const interpolate_key_value = (text, json) => {
  let stringToReturn = "",
    length = text.length,
    index = 0;

  // if (!_.isObject(json)) return text;
  if (!json?.length) return text;

  for (let i = 0; i < length; i++) {
    let currentChar = text[i];
    let nextChar = null;
    if (i + 1 < length) nextChar = text[i + 1];

    if (currentChar === "`" && nextChar === "%") {
      stringToReturn += "%";
      i += 1;
    } else if (currentChar === "%" && nextChar === "{") {
      let result = get_key_length(text, i);
      let key = result[0];
      let key_length = result[1];

      stringToReturn += json[key] === undefined ? "" : json[key];
      i += key_length - 1;
    } else {
      stringToReturn += currentChar;
    }
  }

  return stringToReturn;
};

const interpolate = (text, input) => {
  if (input === undefined) return text;

  if (Object.prototype.toString.call(input) === "[object Array]") {
    return interpolate_general(text, input);
  } else {
    return interpolate_key_value(text, input);
  }
};

const convertStringToNumber = (input) => {
  // Check if input is already a number, if so, return it
  if (typeof input === "number" && !isNaN(input)) {
    return input;
  }

  // Check if input is a string, if not, return 0
  if (typeof input !== "string") {
    return 0;
  }

  // Remove commas from the string
  var str = input.replace(/,/g, "");

  // Convert the string to a floating-point number
  var num = parseFloat(str);

  // Return the number
  return isNaN(num) ? 0 : num;
};

const _convertToSentenceCase = (text) => {
  // Check karein ki text ek single word hai ya multiple words
  if (text?.includes(" ")) {
    // For Multiple words
    const lowerCaseText = text?.toLowerCase();
    const words = lowerCaseText?.split(" ");
    const sentenceCaseWords = words?.map((word) => {
      const firstLetter = word?.charAt(0).toUpperCase();
      const restOfWord = word?.slice(1);
      return `${firstLetter}${restOfWord}`;
    });
    const sentenceCaseText = sentenceCaseWords?.join(" ");
    return sentenceCaseText;
  } else {
    // For Single word
    const firstLetter = text?.charAt(0).toUpperCase();
    const restOfWord = text?.slice(1).toLowerCase();
    return `${firstLetter}${restOfWord}`;
  }
};

const convertArrayOfStringIntoSentaenceCase = (arr) => {
  if (arr?.length) {
    return arr?.map((item) => _convertToSentenceCase(item));
  }
  return arr;
};

const _convertAndCheckArrayOfStringIntoSentaenceCase = (arr) => {
  if (arr?.length) {
    arr = arr?.filter((item) => item);
    arr = arr?.map((item) => _convertToSentenceCase(item));
    return arr;
  }
  return arr;
};

const getObjectId = (object) => object?._id || object?.id;

const getStringToSimpleNumber = (str) => {
  if (typeof str === "string") {
    let updatedValue;
    if (str?.includes(",")) {
      updatedValue = parseFloat(str.replace(/,/g, ""));
    } else {
      updatedValue = parseFloat(str);
    }
    return updatedValue;
  } else {
    return str;
  }
};

function calculateTotal(data) {
  const total = data?.reduce((sum, product) => {
    // product?.amount * product.quantity;
    const productTotal =
      (product?.amount ? getStringToSimpleNumber(product?.amount) : 0) *
      product.quantity;
    return sum + productTotal;
  }, 0);

  return formatCurrency(total);
}

function calculateTotalV1(data) {
  const total = data?.reduce((sum, product) => {
    // product?.amount * product.quantity;
    const productTotal =
      (product?.extraInfo?.amount
        ? getStringToSimpleNumber(product?.extraInfo?.amount)
        : 0) * product.quantity;
    return sum + productTotal;
  }, 0);

  return formatCurrency(total);
}

function formatCurrency(num) {
  try {
    if (!num) return "";
    if (num < 1) return num?.toFixed(4);
    num = num?.toString()?.replace(/\$|\,/g, "");
    if (isNaN(num)) num = "0";
    let sign = num == (num = Math.abs(num));
    num = Math.floor(num * 100 + 0.50000000001);
    let cents = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10) cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
      num =
        num.substring(0, num.length - (4 * i + 3)) +
        "," +
        num.substring(num.length - (4 * i + 3));
    return (sign ? "" : "-") + num + "." + cents;
  } catch (err) {
    return num;
  }
}

const handleCapitalizedString = (text) => {
  if (text?.length) {
    const capitalizedString = text?.charAt(0).toUpperCase() + text?.slice(1);
    return capitalizedString;
  } else {
    return text;
  }
};

export {
  swapArrayElements,
  calculateDistance,
  getFinalTrial,
  paragraph,
  handleReJoinText,
  handleSplitTxt,
  removePercentEncoding,
  getCleanedUrl,
  getOriginalUrl,
  validateEmail,
  convertToSentenceCase,
  // getLocationDetailsFromTimeZone,
  formatDataForDropDownFilter,
  formatDataWithoutCountForDropDownFilter,
  manualStringFormatting,
  getUniqueArray,
  clearLogs,
  get_key_length,
  interpolate_key_value,
  interpolate,
  interpolate_general,
  convertStringToNumber,
  convertArrayOfStringIntoSentaenceCase,
  _convertAndCheckArrayOfStringIntoSentaenceCase,
  _convertToSentenceCase,
  getObjectId,
  getStringToSimpleNumber,
  calculateTotal,
  calculateTotalV1,
  formatCurrency,
  handleCapitalizedString,
};
