import React, { useState } from "react";
import { interpolate } from "../../../utils";

const ReadMore = ({ text, maxLength, isHtml = false }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  if (!text?.length) return;

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const truncatedText = isTruncated ? text?.slice(0, maxLength) : text;

  return (
    <div>
      {isHtml ? (
        <div
          className="render-html-string [&>*]:py-2"
          dangerouslySetInnerHTML={{ __html: interpolate(truncatedText, "") }}
        />
      ) : (
        <p className="text-sm">{truncatedText}</p>
      )}
      {text?.length > maxLength && (
        <button
          onClick={toggleTruncate}
          className="text-blue-800 font-semibold hover:underline hover:decoration-sky-500 text-xs"
        >
          {isTruncated ? "Read More" : "Read Less"}
        </button>
      )}
    </div>
  );
};

export default ReadMore;
