import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import metaTagsReducer from "../features/meta/metaSlice";
import shopLocationReducer from "../features/shopLocation/shopLocationSlice";
import cartReducer from "../features/cart/cart";
import cartReleatedProductsReducer from "../features/cart/cartRelatedProducts";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    metaTags: metaTagsReducer,
    shopLocation: shopLocationReducer,
    cart: cartReducer,
    cartReleatedProducts: cartReleatedProductsReducer,
  },
});
