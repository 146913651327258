import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AOS from "aos";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./features/auth/authSlice";
import { Oval } from "react-loader-spinner";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

import LocationService from "./services/location.service";
import { getValueFromLocalStorage } from "./utils/localStorage";
import { clearLogs } from "./utils";
import { setInitialState } from "./features/cart/cart";


// components
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import SignupModal from "./components/SignupModal/SignupModal";
import LoginModal from "./components/LoginModal/LoginModal";
import OrderSuccess from "./components/OrderSuccess/OrderSuccess";
import MyOrderDetails from "./components/MyOrder/MyOrderDetails";

// pages
import HomePage from "./pages/HomePage";
import Page404 from "./pages/Page404";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SupportPage from "./pages/SupportPage";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import DoctorPage from "./pages/DoctorPage";
import SharedMedicinePage from "./pages/SharedMedicinePage";
import FdaEmaSharedMedicinePage from "./pages/FdaEmaSharedMedicinePage";
import ProductComparison from "./pages/ProductComparison";
// import PostalCodeForm from "./components/PostalCodeForm/PostalCodeForm";
// import { getValueFromLocalStorage } from "./utils/localStorage";

// const HomePage = React.lazy(() => import("./pages/HomePage"));
const MedicineDetailPage = React.lazy(() => import("./pages/MedicineDetailPage"));
const MyFavouritePage = React.lazy(() => import("./pages/MyFavouritePage"));
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));
const SearchPage = React.lazy(() => import("./pages/SearchPage"));
const BlogPage = React.lazy(() => import("./pages/BlogPage"));
const BlogSection = React.lazy(() => import("./components/BlogSection/BlogSection") );
const DoctorDetailPage = React.lazy(() => import("./pages/DoctorDetailPage"));
const CartPage = React.lazy(() => import("./pages/CartPage"));
const CheckoutPage = React.lazy(() => import("./pages/CheckoutPage"));
const PaymentPage = React.lazy(() => import("./pages/PaymentPage"));
const FdaEmaDrugPage = React.lazy(() => import("./pages/FdaEmaDrugPage"));

const renderLoader = () => (
  <div className="flex justify-center items-center h-72" data-aos="fade-up">
    <Oval
      height={50}
      width={50}
      color="rgba(51, 124, 171, 1)"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="rgba(51, 124, 171, 1)"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  </div>
);

export function RoutesComponent({ setIsLoginModalOpen, isLoggedIn, user }) {
  const renderMultiRoutes = ({ element: Element, paths, ...rest }) =>
    paths.map((path) => (
      <Route key={path} path={path} {...rest} element={Element} />
    ));

  return (
    <React.Suspense fallback={renderLoader()}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/:pharmacyname/:categoryname/:name"
          element={<MedicineDetailPage />}
        />
        <Route path="/buscar" element={<SearchPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route
          path="/checkout"
          element={<CheckoutPage setIsLoginModalOpen={setIsLoginModalOpen} />}
        />
        <Route path="/payment" element={<PaymentPage />} />
        {/* </>
          ))} */}
        <Route
          path="/doctores"
          element={<DoctorPage setIsLoginModalOpen={setIsLoginModalOpen} />}
        />
        <Route
          path="/doctores/:id"
          element={
            <DoctorDetailPage setIsLoginModalOpen={setIsLoginModalOpen} />
          }
        />
        <Route
          path="/fda-ema-drug"
          element={<FdaEmaDrugPage setIsLoginModalOpen={setIsLoginModalOpen} />}
        />
        <Route
          path="/lista/:slug"
          element={
            <SharedMedicinePage setIsLoginModalOpen={setIsLoginModalOpen} />
          }
        />
        <Route
          path="/fda-ema-lista/:slug"
          element={
            <FdaEmaSharedMedicinePage
              setIsLoginModalOpen={setIsLoginModalOpen}
            />
          }
        />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:title" element={<BlogSection />} />
        <Route path="/politica-de-privacidad" element={<PrivacyPolicyPage />} />
        <Route path="/politica-de-cookies" element={<CookiePolicyPage />} />
        <Route path="/comparador" element={<ProductComparison />} />
        {renderMultiRoutes({
          paths: [
            "/perfil",
            "/mis-pedidos",
            "/sobre-vooy-farma",
            // "/politica-de-privacidad",
            "/terminos-y-condiciones",
            "/preguntas-frecuentes",
            "/contacto",
            "/ingresar",
          ],
          element: <ProfilePage />,
        })}
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        {isLoggedIn ? (
          <>
            <Route path="/mis-favoritos" element={<MyFavouritePage setIsLoginModalOpen={setIsLoginModalOpen} />} />
            <Route path="/eliminar-mi-cuenta" element={<SupportPage />} />
            <Route path="/mis-pedidos/:orderId" element={<MyOrderDetails />} />
          </>
        ) : null}
        <Route path="/success" element={<OrderSuccess />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </React.Suspense>
  );
}

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const cart = useSelector((state) => state.cart);
  const [isFirstTime, setIsFirstTime] = useState(true);
  // location details
  const locationDetails = getValueFromLocalStorage("locationDetails");

  useEffect(() => {
    setIsFirstTime(false);
    if (cart?.data?.length) localStorage.setItem("cart", JSON.stringify(cart));
    else if (!cart?.data?.length && !isFirstTime)
      localStorage.setItem("cart", JSON.stringify({ data: [] }));
  }, [cart, isFirstTime]);

  useEffect(() => {
    const userCart = localStorage.getItem("cart", JSON.stringify(cart));

    if (userCart) dispatch(setInitialState(JSON.parse(userCart)));
    else localStorage.setItem("cart", JSON.stringify([]));
  }, []);

  const openLoginModalHandler = () => {
    if (isLoggedIn) {
      navigate("/perfil");
    } else {
      setIsSignupModalOpen(false);
      setIsLoginModalOpen(true);
    }
  };

  const openSignupModalHandler = () => {
    if (isLoggedIn) {
      navigate("/perfil");
    } else {
      setIsLoginModalOpen(false);
      setIsSignupModalOpen(true);
    }
  };

  const closeLoginModalHandler = () => {
    setIsLoginModalOpen(false);
  };

  const closeSignupModalHandler = () => {
    setIsSignupModalOpen(false);
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    if (!locationDetails?.country_name) {
      LocationService.ipLookUp();
    }
  }, []);

  useEffect(() => {
    //get user from local storage
    const userData = JSON.parse(localStorage.getItem("user"));
    //get token from local storage
    const token = localStorage.getItem("token");
    //if user and token is present in local storage
    if (userData && token) {
      //dispatch login action
      dispatch(login({ user: userData, token }));
    }
    clearLogs();
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === "/privacy-policy") {
      navigate("/politica-de-privacidad");
    } else if (!user && location.pathname === "my-order") {
      navigate("/404");
    }
    // if (location.pathname === "/reset-password") {
    //   setIsResetPass(true);
    // }
  }, [location]);

  clearLogs();

  return (
    <div className="bg-tertiary ">
      <ToastContainer />
      <div className="sm:container p-3">
        <Navbar
          onLoginModal={openLoginModalHandler}
          onSignupModal={openSignupModalHandler}
        />
        <RoutesComponent
          setIsLoginModalOpen={setIsLoginModalOpen}
          isLoggedIn={isLoggedIn}
          user={user}
        />
        <Footer onSignupModal={openSignupModalHandler} />
      </div>
      <SignupModal
        isOpen={isSignupModalOpen}
        closeSignupModal={closeSignupModalHandler}
        onOpenLoginModal={openLoginModalHandler}
      />
      <LoginModal
        isOpen={isLoginModalOpen}
        closeLoginModal={closeLoginModalHandler}
        onOpenSignupModal={openSignupModalHandler}
      />
    </div>
  );
}

export default App;
