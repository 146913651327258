// import { updateMedicine } from "../slices/medicineDetail";
// import { updateFavouriteMedicines } from "../slices/medicines";
// import { updatePharmacyShopsLocation } from "../slices/pharmacyShops";
import DataHelper from "./DataHelper";

class ShopsHelper {
  getShopsWithLocation = () => {
    // const { pharmacyShops } = DataHelper.getStore().getState();
    const { pharmacyShops } = DataHelper.getStore();
    const shopsWithLocation = [];
    if (pharmacyShops?.data?.length) {
      pharmacyShops?.data?.forEach((shop) => {
        if (
          shop?.locations &&
          Array.isArray(shop?.locations) &&
          shop?.locations?.length
        ) {
          shopsWithLocation.push(shop?.name?.toUpperCase());
        }
      });
    }
    return shopsWithLocation;
  };

  // // shape of shopsObj = {"pharmacyName": [{location:{latitude,longitude}}]}
  // updateShopsLocation = (pharmacies, shopsObj) => {
  //   if (Array.isArray(pharmacies) && pharmacies?.length) {
  //     if (shopsObj instanceof Object) {
  //       DataHelper.getStore()?.dispatch(
  //         updatePharmacyShopsLocation({
  //           pharmacyStoresObj: shopsObj,
  //         })
  //       );
  //     }
  //   }
  // };

  // updateFavouriteStateOfMedicines = (medicineId, isFavourite) => {
  //   const { medicineDetail } = DataHelper.getStore().getState();
  //   if (medicineDetail?.data?.pharmacy?.id) {
  //     DataHelper.getStore()?.dispatch(
  //       updateMedicine({
  //         key: "isFavourite",
  //         value: isFavourite,
  //       })
  //     );
  //   }
  //   DataHelper.getStore()?.dispatch(
  //     updateFavouriteMedicines({
  //       pharmacyId: medicineId,
  //       isFavourite,
  //     })
  //   );
  // };
}

export default new ShopsHelper();
