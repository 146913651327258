import React from "react";

const ACCORDIAN_DATA = [
  {
    id: 1,
    title: "Uso de Cookies en Vooyfarma",
    content: (
      <div>
        En Vooyfarma utilizamos cookies para mejorar tu experiencia de
        navegación, personalizar contenido, ofrecer funciones de redes sociales
        y analizar nuestro tráfico. No usamos cookies para recopilar información
        personal identificable sin tu permiso explícito.
      </div>
    ),
  },
  {
    id: 2,
    title: "Tipos de Cookies Utilizadas",
    content: (
      <div className="px-4">
        <ul className="list-decimal">
          <li className="py-1">
            <b>Cookies Necesarias:</b> Estas cookies son esenciales para el
            funcionamiento del sitio y no pueden ser desactivadas en nuestros
            sistemas. Generalmente, se configuran en respuesta a acciones
            realizadas por ti, como establecer tus preferencias de privacidad,
            iniciar sesión o completar formularios.
          </li>
          <li className="py-1">
            <b>Cookies de Rendimiento y Análisis:</b> Estas cookies nos permiten
            contar visitas y fuentes de tráfico para medir y mejorar el
            rendimiento de nuestro sitio. Nos ayudan a saber qué páginas son las
            más y las menos populares y cómo se desplazan los visitantes por el
            sitio.
          </li>
          <li className="py-1">
            <b>Cookies de Funcionalidad:</b> Estas cookies permiten que el sitio
            ofrezca una funcionalidad mejorada y personalización. Pueden ser
            establecidas por nosotros o por proveedores externos cuyos servicios
            hemos añadido a nuestras páginas.
          </li>
          <li className="py-1">
            <b>Cookies de Publicidad y Marketing:</b> Estas cookies pueden ser
            establecidas a través de nuestro sitio por nuestros socios
            publicitarios. Pueden ser utilizadas por esas empresas para
            construir un perfil de tus intereses y mostrarte anuncios relevantes
            en otros sitios.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 3,
    title: "Cómo Gestionar y Rechazar el Uso de Cookies",
    content: (
      <div>
        Tienes el derecho de elegir si aceptar o rechazar las cookies. Puedes
        ajustar tus preferencias de cookies en cualquier momento accediendo a la
        configuración de tu navegador. Ten en cuenta que si rechazas las
        cookies, algunas partes del sitio pueden no funcionar correctamente.
      </div>
    ),
  },
  {
    id: 4,
    title: "Cambios en la Política de Cookies",
    content: (
      <div>
        <p>
          Vooyfarma se reserva el derecho de modificar esta política de cookies
          en cualquier momento. Los cambios entrarán en vigor inmediatamente
          después de su publicación en el sitio web. Te animamos a revisar
          periódicamente esta política para estar informado sobre cómo
          protegemos tu información
        </p>
        <p>
          Si tienes preguntas o comentarios sobre nuestra política de cookies,
          no dudes en{" "}
          <a
            href="https://vooyfarma.com/contacto"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-500 hover:underline"
          >
            contactarnos
          </a>
          .
        </p>
      </div>
    ),
  },
];

const CookiePolicySection = () => {
  return (
    <div className="py-3 flex flex-col gap-3">
      <div className="pt-4 px-4 rounded-lg">
        <div className="flex items-center justify-between cursor-pointer">
          <h2 className="text-xl font-[600]">¿Qué son las cookies?</h2>
        </div>
        <div className="mt-2 border-t border-gray-200 pt-2">
          <p className="">
            Las cookies son pequeños archivos de texto que los sitios web que
            visitas envían y almacenan en tu dispositivo. Estos archivos
            permiten que el sitio web recuerde información sobre tu visita, lo
            que puede facilitar tu próxima visita y hacer que el sitio sea más
            útil para ti.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {ACCORDIAN_DATA.map((item) => (
          <div className="px-4 rounded-lg" key={item?.id}>
            <div className="flex items-center justify-between cursor-pointer">
              <h2 className="text-xl font-[600]">{item?.title}</h2>
            </div>
            <div className="mt-2 border-t border-gray-200 pt-2">
              <p>{item?.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CookiePolicySection;
