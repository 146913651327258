import React, { useState } from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaTiktok,
  FaLinkedin,
} from "react-icons/fa";

import Logo from "../../assets/images/logo.png";
import AvailableAppStore from "../../assets/images/available-app-store.webp";
import AvailablePlayStore from "../../assets/images/available-play-store.webp";
import { Link } from "react-router-dom";
import TextField from "../Shared/TextField/TextField";
import { AiOutlineMail } from "react-icons/ai";
import Button from "../Shared/Button/Button";
import userRequest from "../../utils/requestMethods";
import { toast } from "react-toastify";
import { validateEmail } from "../../utils";
import { MdLanguage } from "react-icons/md";
import CurrencyDropDown from "../CurruncyDropDown/CurruncyDropDown";
import GoogleTranslate from "../GoogleTranslate/GoogleTranslate";

const Footer = ({ onSignupModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const curruntYear = new Date().getFullYear()

  const signupHandler = () => {
    onSignupModal();
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleNewsletter = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // const email = e.target.email.value;
    if (!email) {
      setIsLoading(false);
      return toast.warning(
        "Por favor ingrese la dirección de correo electrónico"
      );
    }
    if (!validateEmail(email)) {
      setIsLoading(false);
      return toast.warning(
        "Por favor ingrese una dirección de correo electrónico válida"
      );
    }
    // console.log("email >>>", email);
    const body = {
      email: email,
    };
    try {
      const response = await userRequest.post(`/user/newsletter`, body);
      // console.log("response >>>", response);
      if (response) {
        setEmail("");
        setIsLoading(false);
        toast.success("Boletín suscrito");
      }
    } catch (error) {
      toast.error(error.response.data.error);
      setIsLoading(false);
    }
  };
  return (
    <div className="pt-14 pb-4 border-t border-t-gray-300">
      <div className="flex flex-col sm:flex-row justify-between gap-8">
        <div className="flex-[2] md:pr-14">
          <div>
            <img
              src={Logo}
              alt="Vooyfarma Logo"
              className="w-24"
              loading="lazy"
            />
          </div>
          <p className="my-3 text-light">
            Vooyfarma cuenta con una base de datos actualizada en tiempo real
            con los últimos medicamentos disponibles en el mercado.
          </p>
          <div className="flex gap-3 items-center">
            <a
              href="https://facebook.com/vooyfarma"
              target={"_blank"}
              className="bg-secondary p-2 rounded-full text-white transition-all duration-300 ease-in hover:scale-105"
              aria-label="Read more about Vooyfarma on Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://twitter.com/vooyfarma"
              target={"_blank"}
              className="bg-secondary p-2 rounded-full text-white transition-all duration-300 ease-in hover:scale-105"
              aria-label="Read more about Vooyfarma on Twitter"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com/vooyfarma/"
              target={"_blank"}
              className="bg-secondary p-2 rounded-full text-white transition-all duration-300 ease-in hover:scale-105"
              aria-label="Read more about Vooyfarma on Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.youtube.com/@vooyfarma"
              target={"_blank"}
              className="bg-secondary p-2 rounded-full text-white transition-all duration-300 ease-in hover:scale-105"
              aria-label="Read more about Vooyfarma on Youtube"
            >
              <FaYoutube />
            </a>
            <a
              href="https://www.tiktok.com/@vooyfarma"
              target={"_blank"}
              className="bg-secondary p-2 rounded-full text-white transition-all duration-300 ease-in hover:scale-105"
              aria-label="Read more about Vooyfarma on Ticktok"
            >
              <FaTiktok />
            </a>
            <a
              href="https://www.linkedin.com/company/vooy-farma"
              target={"_blank"}
              className="bg-secondary p-2 rounded-full text-white transition-all duration-300 ease-in hover:scale-105"
              aria-label="Read more about Vooyfarma on LinkedIn"
            >
              <FaLinkedin />
            </a>
          </div>
          <div className="flex gap-3 items-center mt-2">
            <a
              href="https://play.google.com/store/apps/details?id=com.vooyfarma&pli=1"
              target={"_blank"}
            >
              <img
                src={AvailablePlayStore}
                alt="Available on Play Store"
                className="my-2"
                width={140}
                height={40}
              />
            </a>
            <a
              href="https://apps.apple.com/app/vooy-farma/id6446176829"
              target={"_blank"}
            >
              <img
                src={AvailableAppStore}
                alt="Available on App Store"
                className="my-2"
                width={130}
                height={40}
              />
            </a>
          </div>
        </div>
        <div className="flex-1 mt-4">
          <div className="font-[700] text-xl">Información</div>
          <ul className="mt-6 text-light flex flex-col gap-3">
            <li>
              <p
                // href={() => false}
                className="cursor-pointer py-0"
                onClick={signupHandler}
              >
                Regístrate
              </p>
            </li>
            <li>
              <Link to={"/mis-favoritos"}>Mis favoritos</Link>
            </li>
            <li>
              <Link to={"/buscar"}>Búsqueda avanzada</Link>
            </li>
            <li>
              <Link to={"/sobre-vooy-farma"}> Sobre Vooyfarma </Link>
            </li>
          </ul>
        </div>
        <div className="flex-1 mt-4">
          <div className="font-[700] text-xl">Ayuda</div>
          <ul className="mt-6 text-light flex flex-col gap-3">
            <li>
              <Link to={"/contacto"}>Soporte</Link>
            </li>
            <li>
              <Link to={"/preguntas-frecuentes"}>Preguntas frecuentes</Link>
            </li>
            <li>
              <Link to={"/terminos-y-condiciones"}>
                {" "}
                Términos y condiciones{" "}
              </Link>
            </li>
            <li>
              <Link to={"/politica-de-privacidad"}>
                {" "}
                Política de privacidad{" "}
              </Link>
            </li>
            <li>
              <Link to={"/politica-de-cookies"}> Política de Cookies </Link>
            </li>
          </ul>
        </div>
        <div className="flex-[1.5] mt-4">
          <div className="font-[700] text-xl">Newsletter</div>
          <form className="w-full mt-6" onSubmit={handleNewsletter}>
            <TextField
              label="Correo electrónico"
              name="email"
              icon={<AiOutlineMail className="text-xl" />}
              placeholder="Ingresa tu correo electrónico"
              value={email}
              onChange={(e) => handleEmail(e)}
            />
            <div className="flex items-center justify-start my-6">
              <Button type="submit" className="lg:w-5/12" disabled={isLoading}>
                <span
                  // style={{fontSize: "1.6vmin"}}
                  className="text-base md:text-sm lg:text-sm xl:text-base"
                >
                  Suscríbete
                </span>
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="my-6 flex flex-row justify-start items-center gap-6">
        <p className="text-light">
          Copyright © {curruntYear} Vooyfarma. Todos los derechos
          reservados
        </p>
        <div>
          <div className="flex justify-start items-center flex-wrap">
            <div>
              <MdLanguage />
            </div>
            <div className="google-translate">
              <GoogleTranslate />
            </div>
            <div>
              <CurrencyDropDown />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
