import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import SharedMedicineItem from "../components/SharedMedicineItem/SharedMedicineItem";
import { Oval } from "react-loader-spinner";
import { Helmet } from "react-helmet";

import Button from "../components/Shared/Button/Button";
import userRequest from "../utils/requestMethods";
import { useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../utils/scrollToTop";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { updateMetaTags } from "../features/meta/metaSlice";
import { CurrencyContext } from "../context/CurrencyProvider";
import placeHolderImage from "../assets/images/pills.placeholder.webp";
import DoctorSearch from "../components/Doctor/DoctorSearch";
import SelectDropDown from "../components/Shared/SelectDropDown/SelectDropDown";
import ReactSelectComp from "../components/Shared/ReactSelectComp/ReactSelectComp";
import { toast } from "react-toastify";
import _ from "lodash";
import { _convertToSentenceCase, clearLogs } from "../utils";
import ContactForm from "../components/ContactForm";
import CurrencyDropDown from "../components/CurruncyDropDown/CurruncyDropDown";
import TextField from "../components/Shared/TextField/TextField";
import { AiOutlineLock } from "react-icons/ai";
import CustomTable from "../components/Shared/CustomTable/CustomTable";
import moment from "moment";
// import SharedMedicineItemV1 from "../components/SharedMedicineItem/SharedMedicineItemV1";

const initialSortFilterValues = [
  {
    label: "Nombre A-Z",
    // value: "nameAsc",
    field: "ProductName",
    value: JSON.stringify({
      field: "medicine.name",
      value: false,
    }),
  },
  {
    label: "Nombre Z-A",
    // value: "nameDesc",
    value: JSON.stringify({
      field: "medicine.name",
      value: true,
    }),
  },
  {
    label: "Precio (Menor a Mayor)",
    field: "price",
    value: JSON.stringify({
      field: "medicine.price",
      value: false,
    }),
  },
  {
    label: "Precio (Mayor a Menor)",
    field: "price",
    value: JSON.stringify({
      field: "medicine.price",
      value: true,
    }),
  },
];

const initialFilter = {
  searchFilter: "",
  country: "All",
  pharmacy: "All",
  sortBy: initialSortFilterValues[0],
};

const filterReducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "searchFilter":
    case "sortBy":
    case "pharmacy":
    case "country": {
      let newState = { ...state };
      newState[action.payload.key] = action.payload.value;
      return newState;
    }
    case "filterByDate": {
      let newState = { ...state };
      newState[action.payload.key] = action.payload.value;
      return newState;
    }
    case "sortBy": {
      let newState = { ...state };
    }
    // eslint-disable-next-line no-fallthrough
    case "reset": {
      return initialFilter;
    }
    case "default":
      return state;
  }
};

const FdaEmaSharedMedicinePage = ({ setIsLoginModalOpen }) => {
  const { token, user } = useSelector((state) => state?.auth);
  const metaTagsData = useSelector((state) => state.metaTags.metaTags);
  const { selectedCurrency } = useContext(CurrencyContext);
  const [isOpenContactForm, setIsOpenContactForm] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const slug = location.pathname?.split("/")?.at(-1);

  const [searchValue, setSearchValue] = useState("");

  // // country
  const [countries, setCountries] = useState([]);

  const [appliedFilters, dispatchAppliedFilters] = useReducer(
    filterReducer,
    initialFilter
  );

  // params
  const [searchParams, setSearchParams] = useSearchParams();

  const addFilter = (key, value, stringify = false) => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set(key, stringify ? JSON.stringify(value) : value);
      return prevSearchParams;
    });
  };
  const isJsonParsable = (val) => {
    try {
      return JSON.parse(val);
    } catch (err) {
      return false;
    }
  };
  const jsonParse = (value) => {
    try {
      return JSON.parse(value);
    } catch (err) {
      return value;
    }
  };
  const applyInitialFilters = () => {
    for (let key in appliedFilters) {
      const val = searchParams.get(key);
      if (val) updateFilter(key, jsonParse(val), isJsonParsable(val));
    }
  };

  useEffect(() => {
    applyInitialFilters();
  }, []);

  const prepareSearchPayload = useCallback(
    (pagination = true, summary = false) => {
      let payload = {
        searchFilter: "",
      };
      if (pagination) {
        payload = {
          ...payload,
          // pageSize,
          // pageIndex,
        };
      }
      if (selectedCurrency) {
        payload.currencyFilter = selectedCurrency?.currency || "MXN";
      }
      for (let key in appliedFilters) {
        if (appliedFilters[key] && appliedFilters[key] !== "All") {
          if (key === "sortBy") {
            payload[key] = jsonParse(jsonParse(appliedFilters[key])?.value);
          } else {
            payload[key] = appliedFilters[key];
          }
        }
      }
      // if (summary) {
      //   payload.summary = true;
      // }
      return payload;
    },
    [appliedFilters, selectedCurrency]
  );

  const fetchSearchResults = useCallback(async () => {
    setIsLoading(true);
    try {
      const payload = prepareSearchPayload(true);
      const response = await userRequest.get(
        `/fda-ema-shared-medicine/${slug}`,
        {
          params: payload,
        }
      );
      if (response.data) setSearchResults(response.data);
      setIsLoading(false);
      // if (response.data?.length > pageSize - 1) {
      //   setHasMore(true);
      // } else {
      //   setHasMore(false);
      // }
      // if (payload.pageIndex === 0) {
      //   setData(response.data);
      // } else {
      //   setData((prevItems) => [...prevItems, ...response.data]);
      // }
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error from pharmacies >>>", error);
    }
  }, [prepareSearchPayload]);

  useEffect(() => {
    fetchSearchResults();
    clearLogs();
  }, [selectedCurrency, appliedFilters]);

  const fetchCountries = async () => {
    try {
      const response = await userRequest.get("/country-store");
      console.log("response with fetchCountries >>>", response);
      let formatDate = response?.data?.filter((item) => {
        if (item?.value) {
          return item;
        }
      });
      setCountries([
        {
          value: "",
          label: "Todas",
        },
        ...formatDate,
      ]);
    } catch (error) {
      console.log("error from catch >>>>", error);
    }
  };
  useEffect(() => {
    scrollToTop();
    fetchCountries();
  }, []);

  const handleLoginModal = () => {
    setIsLoginModalOpen(true);
  };
  const handleNavigate = () => {
    navigate(`/buscar`);
  };

  useEffect(() => {
    let payload = {
      title: "Vooy Farma | Buscador de medicamentos en México",
      description:
        "En Vooy Farma hacemos la búsqueda de medicamentos en varios sitios de farmacias de México por ti",
    };
    dispatch(updateMetaTags(payload));
  }, [dispatch]);

  const searchHandler = () => {
    dispatchAppliedFilters({
      payload: { key: "searchFilter", value: searchValue },
      type: "searchFilter",
    });
    setSearchResults({});
  };

  const updateFilter = (key, value, stringify = false) => {
    setSearchResults({});
    dispatchAppliedFilters({ payload: { key, value }, type: key });
    addFilter(key, value, stringify);
  };

  const handleReset = async () => {
    setSearchParams({});
    setSearchValue("");
    dispatchAppliedFilters({ type: "reset" });
  };

  const showContactForm = () => {
    setIsOpenContactForm(true);
  };
  const closeContactForm = () => {
    setIsOpenContactForm(false);
  };

  const handleReqeustAccess = async () => {
    if (!token) return handleLoginModal();
    setIsLoading(true);
    try {
      const payload = {
        slug: slug,
        email: user?.email,
      };
      const response = await userRequest.post(
        `/fda-ema-shared-medicine/request-access`,
        payload
      );
      toast.success(
        "Su solicitud ha sido enviada al administrador para su aprobación."
      );
      console.log("response >>", response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
      console.log("error from access >>>", error);
    }
  };
  const handleGetProtectedList = async (e) => {
    e.preventDefault();
    const listPassword = e.target?.password?.value;
    if (!token) return handleLoginModal();
    if (!listPassword) return toast.error("Please enter list password");

    setIsLoading(true);
    try {
      const payload = {
        slug: slug,
        listPassword: listPassword,
      };
      const response = await userRequest.post(
        `/fda-ema-shared-medicine/get-protected-list`,
        payload
      );
      // toast.success(
      //   "Su solicitud ha sido enviada al administrador para su aprobación."
      // );
      console.log("response >>", response);
      fetchSearchResults();

      // setSearchResults(response.data)
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
      console.log("error from access >>>", error);
    }
  };

  const columns = (
    <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
      <tr>
        <th scope="col" className="px-6 py-3">
          Fecha de aprobación
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">Nombre del medicamento</div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">Sustancia Activa</div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">Compañía</div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center">País</div>
        </th>
      </tr>
    </thead>
  );

  if (
    !isLoading &&
    searchResults?.isPasswordRequired &&
    searchResults?.hasOwnProperty("isPasswordRequired")
  ) {
    return (
      <div className="flex justify-center items-center h-[50vh]">
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col items-center justify-center my-3 w-full">
            <div>
              <p className="my-3">
                Password Required: To view this list, please enter password
              </p>
            </div>
            <form className="w-full" onSubmit={handleGetProtectedList}>
              <TextField
                label="Contraseña"
                name="password"
                icon={<AiOutlineLock className="text-xl" />}
                placeholder="Enter List Password"
                type="password"
              />
              <div className="flex items-center justify-center my-6">
                <Button className="w-full" type="submit" disabled={isLoading}>
                  Enter Password
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (
    !isLoading &&
    ((!searchResults?.isPublic && searchResults?.hasOwnProperty("isPublic")) ||
      searchResults?.isPendingRequest)
  ) {
    return (
      <div className="flex justify-center items-center h-[50vh]">
        <div className="flex flex-col justify-center items-center">
          <div className="flex items-center justify-center my-3 w-full">
            {searchResults?.isPendingRequest ? (
              <p>Solicitud pendiente</p>
            ) : (
              <Button className="w-full" onClick={handleReqeustAccess}>
                Solicitar acceso
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    // <div className="my-3 px-10 py-4 rounded-2xl relative lg:w-10/12 mx-auto">
    <div className="sm:container px-3 py-6">
      <Helmet>
        <link
          rel="canonical"
          href={`https://vooyfarma.com/fda-ema-lista/${slug}`}
        />

        <title>{metaTagsData.title}</title>
        <meta name="description" content={metaTagsData.description} />
      </Helmet>
      <div className="flex flex-col lg:flex-col">
        {true ? (
          <>
            <div className="py-2 w-full">
              {!isLoading && searchResults?.medicine?.length > 0 ? (
                <div className="font-[600] pb-4">
                  <h2 className="font-[600] text-xl">
                    {_convertToSentenceCase(searchResults?.name)}
                  </h2>
                </div>
              ) : null}

              <DoctorSearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                onChangeValue={searchHandler}
                placeholder="Buscador de medicamentos"
              />
              {!isLoading && searchResults?.medicine?.length > 0 && (
                <div className="py-2">
                  <div className="xs:pr-4">
                    <p className="p-0 font-[300]" onClick={handleReset}>
                      {"Si necesitan medicamentos difíciles de encontrar, "}
                      <button
                        className="text-secondary underline py-2 font-[600]"
                        onClick={showContactForm}
                      >
                        contáctenos
                      </button>
                    </p>
                  </div>
                </div>
              )}
              <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 py-2">
                <div className=" xs:pr-4 xs:border-r-2 xs:border-gray-200">
                  <div className="font-[600]">Ordernar por:</div>
                  <ReactSelectComp
                    // style={reactSelectStyleDefault}
                    placeholder="Seleccionar"
                    options={initialSortFilterValues}
                    value={appliedFilters?.sortBy ? appliedFilters?.sortBy : ""}
                    onChange={(e) => updateFilter("sortBy", e, true)}
                  />
                </div>
                {/* <SelectDropDown
                  options={countries}
                  label="País:"
                  value={appliedFilters?.country}
                  onChange={(value) => updateFilter("country", value)}
                /> */}
                <div className="xs:pr-4 flex justifyflex items-center justify-start">
                  <button
                    className="text-secondary underline pt-5 font-[600]"
                    onClick={handleReset}
                  >
                    Resetear filtros
                  </button>
                </div>
              </div>
            </div>
            <div className="pb-3">
              {!isLoading && searchResults?.medicine?.length > 0 && (
                <div className="my-4">
                  <div className="flex justify-end items-center">
                    <div>
                      Total:
                      <span className="font-bold text-md">{` ${searchResults?.medicine?.length}`}</span>
                    </div>
                  </div>
                  <div className="mt-2 mb-6 w-full">
                    {/* <div className="mt-2 mb-6 grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4"> */}
                    {/* {searchResults?.medicine?.map((medicine, index) => (
                      <SharedMedicineItem
                        key={medicine.id}
                        name={medicine.name}
                        amount={false}
                        image={
                          medicine?.images?.length
                            ? medicine?.images?.[0]
                            : medicine?.Image?.[0]
                            ? medicine?.Image?.[0]
                            : placeHolderImage
                        }
                        pharmacyName={medicine?.company || "-"}
                        price={false}
                        medicine={searchResults?.medicine?.[index]}
                      />
                    ))} */}
                    <CustomTable
                      columns={columns}
                      rows={searchResults?.medicine?.map((item) => (
                        <tr className="bg-white border-b " key={item}>
                          <th scope="row" className="px-6 py-4 font-medium ">
                            {item?.approvalDate
                              ? moment(item?.approvalDate)?.format("YYYY-MM-DD")
                              : ""}
                          </th>
                          <td className="px-6 py-4">
                            {item?.name || item?.drugName || item?.ProductName}
                          </td>
                          <td className="px-6 py-4">
                            {(item?.activeIngredients &&
                              item?.activeIngredients?.join(", ")) ||
                              ""}
                          </td>
                          <td className="px-6 py-4">{item?.company}</td>
                          <td className="px-6 py-4">
                            {item?.country || item?.Country}
                          </td>
                        </tr>
                      ))}
                    />
                  </div>
                </div>
              )}
              {isLoading && (
                <div
                  className="flex justify-center items-center h-72"
                  data-aos="fade-up"
                >
                  <Oval
                    height={50}
                    width={50}
                    color="rgba(51, 124, 171, 1)"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="rgba(51, 124, 171, 1)"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-[50vh]">
            <div className="flex flex-col justify-center items-center">
              <p className="text-primary font-[600] text-sm">
                Inicie sesión para ver la lista de medicamentos
              </p>
              <div className="flex items-center justify-center my-3 w-full">
                <Button className="w-full" onClick={handleLoginModal}>
                  Iniciar sesión o registrarse
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ContactForm
        isOpenContactForm={isOpenContactForm}
        closeContactForm={closeContactForm}
      />
    </div>
  );
};

export default FdaEmaSharedMedicinePage;
