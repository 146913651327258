import moment from "moment";
import React, { useContext } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { calculateTotal, formatCurrency } from "../../utils";
import { CurrencyContext } from "../../context/CurrencyProvider";
import { Currencies } from "../../constants";

const OrderSuccess = () => {
  const location = useLocation();
  const userData = location.state;
  // console.log("userData >>>", userData);
  const { selectedCurrency } = useContext(CurrencyContext);


  if (!userData) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <section className="py-8 antialiased md:py-16">
      <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
        <div>
          <section className="antialiased">
            <div className="mx-auto max-w-2xl bg-white rounded-lg p-8">
              <div className="mb-6">
                <svg
                  viewBox="0 0 24 24"
                  className="text-green-600 w-20 h-20 mx-auto"
                >
                  <path
                    fill="currentColor"
                    d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                  ></path>
                </svg>
              </div>
              <h2 className="text-xl font-semibold text-gray-900  sm:text-2xl mb-2">
                {/* Thanks for your order! */}
                ¡Gracias por tu orden!
              </h2>
              <p className="text-gray-500  mb-6 md:mb-8">
                Su pedido será procesado dentro de las 24 horas durante los días
                laborables. Le notificaremos por correo electrónico una vez que
                su pedido haya sido enviado.
              </p>
              <div className="space-y-4 sm:space-y-2 rounded-lg border border-gray-100 bg-gray-50 p-6  mb-3 md:mb-4">
                <dl className="sm:flex items-center justify-between gap-4">
                  <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                    Fecha
                  </dt>
                  <dd className="font-medium text-gray-900  sm:text-end">
                    {moment().format("DD-MM-YYYY")}
                  </dd>
                </dl>
                {/* <dl className="sm:flex items-center justify-between gap-4">
                  <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                    Método de pago
                  </dt>
                  <dd className="font-medium text-gray-900  sm:text-end">
                    {"Cuotas mensuales"}
                  </dd>
                </dl> */}
                <dl className="sm:flex items-center justify-between gap-4">
                  <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                    Nombre
                  </dt>
                  <dd className="font-medium text-gray-900  sm:text-end">
                    {userData?.name}
                  </dd>
                </dl>
                <dl className="sm:flex items-center justify-between gap-4">
                  <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                    Teléfono
                  </dt>
                  <dd className="font-medium text-gray-900  sm:text-end">
                    {userData?.phoneNumber}
                  </dd>
                </dl>
                <dl className="sm:flex items-center justify-between gap-4">
                  <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                    Pais
                  </dt>
                  <dd className="font-medium text-gray-900  sm:text-end">
                    {userData?.country}
                  </dd>
                </dl>
                <dl className="sm:flex items-center justify-between gap-4">
                  <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                    Ciudad
                  </dt>
                  <dd className="font-medium text-gray-900  sm:text-end">
                    {userData?.city}
                  </dd>
                </dl>
                <dl className="sm:flex items-center justify-between gap-4">
                  <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                    RFC
                  </dt>
                  <dd className="font-medium text-gray-900  sm:text-end">
                    {userData?.vatNumber}
                  </dd>
                </dl>
                <dl className="sm:flex items-center justify-between gap-4">
                  <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                    Nombre de empresa
                  </dt>
                  <dd className="font-medium text-gray-900  sm:text-end">
                    {userData?.companyName}
                  </dd>
                </dl>
              </div>
              {userData?.cart?.length ? (
                <div className="space-y-4 sm:space-y-2 rounded-lg border border-gray-100 bg-gray-50 p-6 mb-3 md:mb-4">
                  <div>
                    <h2 className="text-md font-bold">Productos</h2>
                  </div>
                  <div>
                    {userData?.cart?.map((item) => (
                      <div
                        key={item?._id || item?.id}
                        className="border rounded-md p-2 mb-1"
                      >
                        <dl className="sm:flex items-center justify-between gap-4">
                          <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                            Nombre
                          </dt>
                          <dd className="font-medium text-gray-900  sm:text-end">
                            {item?.name}
                          </dd>
                        </dl>
                        <dl className="sm:flex items-center justify-between gap-4">
                          <dt className="font-normal mb-1 sm:mb-0 text-gray-500 ">
                            Precio
                          </dt>
                          <dd className="font-medium text-gray-900  sm:text-end">
                          {`${Currencies[selectedCurrency?.currency]?.sign}`}
                            {typeof item?.amount === "number"
                              ? formatCurrency(item?.amount)
                              : item?.amount}
                          </dd>
                        </dl>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              {userData?.cart?.length ? (
                <div className="space-y-4 sm:space-y-2 rounded-lg border border-gray-100 bg-gray-50 py-2 px-6 mb-6 md:mb-8">
                  <dl className="flex items-center justify-between gap-4 py-2">
                    <dt className="text-base font-bold text-gray-900 ">
                      Total
                    </dt>
                    <dd className="text-base font-bold text-gray-900 ">
                    {`${Currencies[selectedCurrency?.currency]?.sign}`}                      
                      {userData?.cart
                        ? formatCurrency(String(calculateTotal(userData?.cart)))
                        : 0}
                    </dd>
                  </dl>
                </div>
              ) : null}
              <div className="flex items-center space-x-4">
                <Link
                  to={"/buscar"}
                  className="text-white bg-secondary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
                >
                  Inicio
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default OrderSuccess;
